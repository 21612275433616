import { Edit, OpenInNew, Stop } from '@material-ui/icons';
import React, { useState } from 'react';
import {FaSpinner, FaTimes} from 'react-icons/fa';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CircularStatic from '../../../imageUpload';
import defaultImage from '../../assets/support-image.png'
import { url } from '../../../Redux/Api';
import { Spinner } from '../../../Loading';

const NewSupport = () => {
    const[problem, setProblem] = useState('')
    const[solution, setSolution] = useState('')
    const[image, setImage] = useState('')
    const[imageUrl, setImageUrl] = useState('')
    const[imagePreview, setImagePreview] = useState('')
    const[imagePreviewEdit, setImagePreviewEdit] = useState('')
    const[loading, setLoading] = useState(false)
    const[showOld, setShowOld] = useState(false)
    const[editing, setEditing] = useState(false)

    // const[editSupportImage, setEditSupportImage] = useState('')
    const[editSupportProblem, setEditSupportProblem] = useState('')
    const[editSupportSolution, setEditSupportSolution] = useState('')

    const[editedImage, setEditedImage] = useState('')
    const[supportUpdateSuccess, setSupportUpdateSuccess] = useState('')
    const[supportUpdateError, setSupportUpdateError] = useState('')
    const[supportUpdateLoading, setSupportUpdateLoading] = useState(false)
    
    const[editingData, setEditingData] = useState({})
        
    const app = useSelector(state=>state.apps.items)
    const user = useSelector(state=>state.auth.user)

const uploadHandler = async(e)=>{
    e.preventDefault()
    const fileData = e.target.files[0]
    const base64 = await converter(fileData)
    setImagePreview(base64)
    setImage(fileData)
}

const uploadHandlerEdited = async(e)=>{
    e.preventDefault()
    const fileData = e.target.files[0]
    const base64 = await converter(fileData)
    setImagePreviewEdit(base64)
    setEditedImage(fileData)
}

const supportHandler = async(e)=>{
e.preventDefault()
setLoading(true)
    if(image===''){

        await fetch(`${url}/new-support`,{
            method:'post',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },
            body:JSON.stringify({
                problem,
                solution,
                appId:app._id
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                setLoading(false)
            }else if(resp.err){
                setLoading(false)
            }
        }).catch(err=>{
            if(err){
                setLoading(false)
            }
        })
    }else{
        const imageData = new FormData()
        imageData.append('file',image)
        imageData.append('upload_preset','xfindx')
        imageData.append('cloud_name','xfindx')
        await fetch('https://api.cloudinary.com/v1_1/cashorbits/image/upload',{
            method:'post',
            body:imageData
        }).then(res=>res.json())
        .then(result=>{
            if(result){
                setImageUrl(result.url)
                console.log('To cloud',result.url)
                fetch(`${url}/new-support`,{
                    method:'post',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    },
                    body:JSON.stringify({
                        problem,
                        solution,
                        image:result.url,
                        appId:app._id
                    })
                }).then(res=>res.json())
                .then(resp=>{
                    if(resp.resp){
                        setLoading(false)
                    }else if(resp.err){
                        setLoading(false)
                    }
                }).catch(err=>{
                    if(err){
                        setLoading(false)
                    }
                })
            }
        }).catch(err=>{
            if(err){
                setLoading(false)
            }
        })
    }
}
const converter = (e)=>{
    return new Promise((resolve,reject)=>{
        const reader = new FileReader()
        reader.readAsDataURL(e)
        reader.onload=()=>{
            resolve(reader.result)
        }
        reader.onerror = (error)=>{
            reject(error)
        }
    })
}
const handleEditSubmit = async(e)=>{
    e.preventDefault()
    setSupportUpdateLoading(true)
    setEditing(true)
    setEditingData(e)
    setShowOld(false)
   if(editedImage===''){
     function fetchData(){
         fetch(`${url}/update-support/${editingData.supportId}`,{
            method:'put',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },
            body:JSON.stringify({
            problem:editSupportProblem, solution:editSupportSolution,image:editingData.image,supportId:editingData.supportId
            })  
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                setSupportUpdateSuccess(resp.resp)
                setSupportUpdateLoading(false)
            }else if(resp.err){
                setSupportUpdateError(resp.err)
                setSupportUpdateLoading(false)
            }
        }).catch(err=>{
            if(err){
                setSupportUpdateError(err.message)
                setSupportUpdateLoading(false)
            }
        })
            }
            fetchData()
   }else{
    const imageData = new FormData()
    imageData.append('file',editedImage)
    imageData.append('upload_preset','xfindx')
    imageData.append('cloud_name','xfindx')
    await fetch('https://api.cloudinary.com/v1_1/cashorbits/image/upload',{
        method:'post',
        body:imageData
    }).then(res=>res.json())
    .then(result=>{
        if(result){
            setImageUrl(result.url)
            async function fetchData(){
                await fetch(`${url}/update-support/${editingData.supportId}`,{
                    method:'put',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    },
                    body:JSON.stringify({
                    problem:editSupportProblem, solution:editSupportSolution,image:result.url
                    })  
                }).then(res=>res.json())
                .then(resp=>{
                    if(resp.resp){
                        setSupportUpdateSuccess(resp.resp)
                        setSupportUpdateLoading(false)
                    }else if(resp.err){
                        setSupportUpdateError(resp.err)
                        setSupportUpdateLoading(false)
                    }
                }).catch(err=>{
                    if(err){
                        setSupportUpdateError(err.message)
                        setSupportUpdateLoading(false)
                    }
                })
                    }
                    fetchData()
        }
    }).catch(err=>{
        if(err){
            setSupportUpdateLoading(false)
        }
    })
   }
}
const handleEditOpen = (e)=>{
    setEditing(true)
    setEditingData(e)
}
    return (
        <Main>
           <div className='mian-container'>
           <form onSubmit={supportHandler} className='new-support-form'>
            <p className='support-default'>Blogging as {user.userName}</p>
                <textarea type='text' onChange={(e)=>setProblem(e.target.value)} placeholder='Name of the problem' className='new-support-title'/>
                <textarea type='text' onChange={(e)=>setSolution(e.target.value)} placeholder='Describe your suggestion here...' className='new-support-body' />
                <span className='new-support-placeholder'>Upload Image</span>
                <input type='file' onChange={uploadHandler} className='new-support-file' />
                {
                    loading===true?<CircularStatic />:<button type='submit' className='new-support-btn'>Create</button>
                }
            </form>
            {
                imagePreview!==''?
            <div className='new-support-preview-container'>
                <p className='new-support-preview-title'>Preview</p>
                <img src={imagePreview} width='300px' height='300px' alt='' className='new-support-preview-image' />
            </div>:
            <div className='new-support-preview-container'>
                {showOld===false&&<OpenInNew onClick={()=>setShowOld(true)} />}
            <p className='new-support-preview-title'>Preview shows here</p>
            <img src={defaultImage} width='300px' height='300px' alt='' className='new-support-preview-image' />
            </div>}
           </div>
           {editing===true&&<div className='old-supports-editing-container'>
                {editing===true&&<FaTimes onClick={()=>setEditing(false)} />}
               <form className='new-support-form' onSubmit={handleEditSubmit}>
               <input type='text' onChange={(e)=>setEditSupportProblem(e.target.value)}  className='new-support-title'/>
               <input type='text' onChange={(e)=>setEditSupportSolution(e.target.value)}  className='new-support-body'/>
               <input type='file' onChange={uploadHandlerEdited} className='new-support-file' />
               {
                supportUpdateLoading===true?<Spinner />:<button type='submit'  className='new-support-btn'>Update</button>
               }
               
               </form>
               {
                imagePreviewEdit!==''?
            <div className='new-support-preview-container'>
                <p className='new-support-preview-title'>Preview editing</p>
                <img src={imagePreviewEdit} width='300px' height='300px' alt='' className='new-support-preview-image' />
            </div>:
            <div className='new-support-preview-container'>
                {showOld===false&&<OpenInNew onClick={()=>setShowOld(true)} />}
            <p className='new-support-preview-title'>Preview shows here</p>
            <img src={editingData.image!==null&&editingData.image!==''?editingData.image:defaultImage} width='300px' height='300px' alt='' className='new-support-preview-image' />
            </div>}
            </div>}

            <div className='old-supports-container'>
                {showOld===true&&<FaTimes onClick={()=>setShowOld(false)} />}
                {
                   showOld===true&&app.supports.map((item,index)=>{
                        return(
                            <div className='old-supports'>
                            <h4 className='old-supports-title'>{item.problem}</h4>
                            <p className='old-supports-body'>{item.solution}</p>
                            <Edit onClick={()=>handleEditOpen(item)} />
                            </div>
                        )
                    })
                }
            </div>
        </Main>
    );
};

const Main = styled.div`
width: 80%;
padding: 20px 30px;
margin: 40px auto;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
background: var(--dark-bg);
@media screen and (max-width:850px){
    width:90%;
}
.mian-container{
    display: flex;
justify-content: space-between;
align-items: baseline;
width: 100%;
@media screen and (max-width:850px){
    flex-direction: column-reverse;
}
}
.new-support-form{
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
width: 100%;
flex-basis: 65%;
gap:20px;
@media screen and (max-width:850px){
    flex-basis:100%;
    width:100%;
}
}
.new-support-title{
min-height: 50px;
height: fit-content;
border-radius: 5px;
padding: 5px;
width: 100%;
text-align: center;
}
.new-support-body{
height: fit-content;
min-height: 300px;
border-radius: 5px;
padding: 5px;
width: 100%;
text-align: justify;
} 
.new-support-file{
text-align: center;
font-weight: bold;
color: var(--bg);
} 
.new-support-placeholder{
    background: none;
} 
.new-support-btn{
padding: 5px 100px;
color:var(--style-blue-cyan);
background-color: var(--style-blue-dark);
border-radius: 4px;
cursor:pointer;
outline: none;
border:none;
transform: scale(1);
&:hover{
transform: scale(1.1);
transition:var(--transition);
}
} 

.new-support-preview-container{
    flex-basis: 30%;
    width: 100%;
    @media screen and (max-width:850px){
    flex-basis:100%;
}
}
.new-support-preview-title{
    text-align: center;
    padding: 20px;
}
.new-support-preview-image{
    width: 100%;
    border-radius: 5px;
}
.old-supports-container{
    width: 100%;
}
.old-supports{
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
}
.old-supports-title{
text-align: center;
padding: 10px;
}
.old-supports-body{
text-align: justify;
padding: 10px;
}
.support-default{
color: var(--gray-color);
text-align: center;
font-weight: bold;
padding: auto 10px;
}
.old-supports-editing-container{
    display: flex;
justify-content: space-between;
align-items: baseline;
width: 100%;
}
`
export default NewSupport;