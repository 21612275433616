import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setAuthHeader, url } from "../Api";

const initialState = {
    items:[],
    status:null
}
export const getMyGrooves = createAsyncThunk(
    'myGrooves/getMyGrooves',
    async()=>{
        try {
          const response = await axios.get(`${url}/my-grooves`,setAuthHeader());
          return response.data.resp
        } catch (error) {
            return
        }
    }
)
const MyGroovesSlice = createSlice({
    name:'myGrooves',
    initialState,
    reducers:{},
    extraReducers:{
        [getMyGrooves.pending]:(state,action)=>{state.status = 'pending'},
        [getMyGrooves.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getMyGrooves.rejected]:(state,action)=>{state.status='rejected'}
}
})

export default MyGroovesSlice.reducer;