import { Add, CameraEnhance, Settings } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { FaShare, FaUser } from 'react-icons/fa';
import styled from 'styled-components';
import defaultImage from '../../../assets/user_image_placeholder.png'
import PersonalSettings from './PersonalSettings';
import { useSelector } from 'react-redux';
import UserDetailsPage from './UserDetailsPage';
import CircularStatic from '../../../../imageUpload';
import { useNavigate } from 'react-router-dom';
import jobSeek from '../../../assets/user_image_placeholder.png'
import GrooveForm from './GrooveForm';
import { url } from '../../../../Redux/Api';

const Profile = () => {
  const[userDetailStyle, setUserDetailStyle] = useState('user-detail-in-active')
  const[settingStyle, setSettingStyle] = useState('setting-style-in-active')
  const[createGrooveStyle, setCreateGrooveStyle] = useState('create-groove-style-in-active')

  const[imagePreview,setImagePreview] = useState('')

  const[image, setImage] = useState('')

    const user = useSelector(state=>state.auth.user)
 const[currentLink, setCurrentLink] = useState('detail')
 const[isGrooveForm, setIsGrooveForm] = useState(false)

  const[profileImageLoading, setProfileImageLoading] = useState(false)
  const[goodImage, setGoodImage] = useState(false)
  const[profileImageSuccess, setProfileImageSuccess] = useState('')
  const[profileImageError, setProfileImageError] = useState('')

const[render,setRender] = useState(<UserDetailsPage />)
const detailsHandler = ()=>{
  setRender(<UserDetailsPage />)
  setCurrentLink('detail')
}

const settingsHandler = ()=>{
  setRender(<PersonalSettings />)
  setCurrentLink('setting')
}

const navigate = useNavigate()
const uploadHandler = async(e)=>{
  e.preventDefault()
  const fileData = e.target.files[0]
  const base64 = await Converter(fileData)
  setImagePreview(base64)
  setImage(fileData)

  if(fileData){
   if(fileData.size>2000000){
    setProfileImageError(`Sorry, ${fileData.size}kb is high. Max file file size is 2MB`)
   }else{
    const extensions = ['image/png','image/jpg','image/jpeg','image/ico','image/jfif','image/gif']
    const extension = ['png','jpg','jpeg','ico','jfif','gif']
    
    const checkExtension = extensions.indexOf(fileData.type)
   if(checkExtension<0){
    setProfileImageError(`Unsupported file. Try any of these ${extension}.`)
   }else{
    setGoodImage(true)
   }
   }
}
}

const Converter = (e)=>{
  return new Promise((resolve,reject)=>{
    const reader = new FileReader()
    reader.readAsDataURL(e)
    reader.onload = ()=>{
      resolve(reader.result)
    }
    reader.onerror = (error)=>{
      reject(error)
    }
  })
}

const supportHandler = async(e)=>{
  e.preventDefault()
  setProfileImageLoading(true)

  const imageData = new FormData()
  imageData.append('file',image)
  imageData.append('upload_preset','editor')
  imageData.append('cloud_name','editor')
  await fetch('https://api.cloudinary.com/v1_1/cashorbits/image/upload',{
      method:'post',
      body:imageData
  }).then(res=>res.json())
  .then(result=>{
      if(result){
          fetch(`${url}/update-profile-picture`,{
              method:'post',
              headers:{
                  'Content-Type':'application/json',
                  'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
              },
              body:JSON.stringify({
                  image:result.url
              })
          }).then(res=>res.json())
          .then(resp=>{
              if(resp.resp){
                setProfileImageSuccess(resp.resp)
                setProfileImageLoading(false)
              }else if(resp.err){
                setProfileImageLoading(false)
                  setProfileImageError(resp.err)
              }
          }).catch(err=>{
              if(err){
                setProfileImageError(err.message)
                setProfileImageLoading(false)
              }
          })
      }
  }).catch(err=>{
      if(err){
        setProfileImageError(err.message)
        setProfileImageLoading(false)
      }
  })
}
const createGrooveHandler = ()=>{
  setRender(<GrooveForm />)
  setIsGrooveForm(true)
  setCurrentLink('createGroove')
}
  return (
    <Main img={jobSeek}>
      {
        !isGrooveForm&&<Add className='add-groove-icon'  onClick={createGrooveHandler} />
      }
      <div className='user-profile-left-widget'>
      <img src={user?.image!=='image'?user?.image:imagePreview!==''?imagePreview:defaultImage} alt='user_image' />
      <div className='user-detail-container'>
        {user?.isCashier&&<>{user?.bank?<h1 style={{color:'var(--style-lemon)',fontSize:'10px'}}>Verified Cashier</h1>:
        <h1 style={{color:'var(--style-error)',fontSize:'10px'}}>Add a bank account</h1>}</>}{profileImageSuccess?<p className='profile-image-success'>{profileImageSuccess}</p>:profileImageError?<p className='profile-image-error'>{profileImageError}</p>
        :<h3 className='profile-title'>{user?.firstName}&nbsp;{user?.lastName}</h3>}
        <form onSubmit={supportHandler} className='image-form'>
                <label className='file-wrapper' for='fileInput'>
                  <CameraEnhance className='image-change-icon-profile'/>
                  <input type='file' id='fileInput' className='file-input' accept='image/png, image/jpg, image/gif, image/jpeg' onChange={uploadHandler} />
                </label>
                {
                    profileImageLoading===true?<CircularStatic />:
                    goodImage===true?<button type='submit' className='save-profile-picture-btn'>Update</button>:null
                }
            </form>
        <hr />
        <div className='links'>
          <p className={userDetailStyle}>User Detail</p>
        <li className={currentLink==='detail'?'active':'not-active'}><FaUser onMouseOver={()=>setUserDetailStyle('user-detail-active')} onMouseOut={()=>setUserDetailStyle('user-detail-in-active')} onClick={detailsHandler} /></li>
        <p className={settingStyle}>Settings</p>
        <li  className={currentLink==='setting'?'active':'not-active'}><Settings onMouseOver={()=>setSettingStyle('setting-style-active')} onMouseOut={()=>setSettingStyle('setting-style-in-active')}  onClick={settingsHandler} /></li>
        <p className={createGrooveStyle}>Share with friends</p>
        <li  className={currentLink==='createGroove'?'active':'not-active'}><FaShare onMouseOver={()=>setCreateGrooveStyle('create-groove-style-active')} onMouseOut={()=>setCreateGrooveStyle('create-groove-style-in-active')}  onClick={()=>navigate('/link-share')} /></li>
      </div>
        </div>
      </div>
      <div className='user-profile-right-widget'>
      {render}
      </div>
    </Main>
  );
};

const Main = styled.div`
*{
  margin: 0;
  padding: 0;
}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 200px;
  gap: 20px;
  margin: auto;
  margin-bottom: 0px;
  width: 100%;
  min-height: 90vh;
  height: auto;
  background-image: url(${props=>props.img});
  background-repeat: repeat;
  background-size: 70px;
  background:var(--style-white);
  position: relative;
  @media screen and (max-width:1200px) {
    padding: 20px 20px;
    margin: 60px auto;
    height: auto;
  }
  @media screen and (max-width:800px) {
    flex-direction: column;
    padding: 20px 20px;
    margin: 60px auto;
    height: auto;
  }
.add-groove-icon{
position: fixed;
right: 30px;
top: 200px;
width: 100px;
height: 100px;
border-radius: 50%;
font-size: 5px;
color: var(--style-blue-dark);
background-color: var(--style-bright);
box-shadow: 2px 5px 2px 4px rgb(220, 220, 220);
cursor: pointer;
transform: scale(1);
z-index: 100;
@media screen and (max-width:800px) {
width: 50px;
height: 50px; 
}
  }
  .add-groove-icon:hover{
    transform: scale(1.1);
    transition: all ease 1s;
  }
  .user-profile-left-widget{
    flex-basis: 40%;
    width: 100%;
    padding: 20px;
    background-color: var(--style-bright);
    border-radius: 4px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    @media screen and (max-width:800px) {
   flex-basis: 100%;
   padding: 20px;
  }
  }
  .user-detail-container{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width:800px) {
    gap: 10px;
  }
  @media screen and (max-width:500px) {
    justify-content: center;
    padding-right: 20px;
    gap: 40px;
  }
  }
  .save-profile-picture-btn{
    padding: 10xp 4px !important;
    margin: 20px 20px;
  }
  .profile-image-success{
    color: var(--style-success);
  }
  .profile-image-error{
    color: var(--style-error);
  }
  .profile-image-loading{
    color: var(--style-blue);
  }
  .profile-title{
    font-size: 18px;
    text-align:center;
    padding: 5px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: var(--style-grey);
    text-shadow: 2px 2px 2px #beaaaa;
  }
  .image-form{
    /* position: relative; */
  }
  .file-input{
    display: none;
  }
  .file-wrapper{
    border: thin solid var(--style-blue-dark);
    border-radius: 4px;
    color:var(--style-blue-dark);
    background: transparent;
    position: absolute;
    left: 55px;
    top: 50px;
    cursor: pointer;
    @media screen and (max-width:700px) {
      left: 20px;
      top: 150px;
    }
  }
  .image-change-icon-profile{
    color: var(--style-blue-dark);
  }
  .user-profile-left-widget img{
    width:300px;
    @media screen and (max-width:800px) {
   height: 200px;
   width:200px;
   border-radius: 50%;
   padding: 20px;
  }
  }
  .user-profile-left-widget p{
    font-size: 16px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding: 20px;
  }
  .user-profile-left-widget .links{
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    margin-top: 70px;
    width: 100%;
    @media screen and (max-width:800px) {
      margin-top: 5px;
    }
    @media screen and (max-width:500px) {
      /* flex-direction: column-reverse; */
    }
  }
  .user-messages-class{
    z-index: 200;
  }
  #messages-lists{
    position: relative !important;
  }
  .unreads{
    color: red;
    font-size: 50px !important;
    position: absolute;
    bottom: 25px;
    right: 28px;
    z-index: 1;
  }
  .reads{
    display: none;
  }
.user-detail-in-active,
.groove-style-in-active,
.setting-style-in-active,
.groove-wallet-style-in-active,
.create-groove-style-in-active,
.user-messaging-style-in-active,
.blog-style-in-active{
display: none;
}

.user-detail-active,
.groove-style-active,
.setting-style-active,
.groove-wallet-style-active,
.create-groove-style-active,
.user-messaging-style-active,
.blog-style-active{
display: block;
padding:0 30px;
text-decoration: 2px overline var(--style-blue-dark);
position: absolute;
bottom: 30px;
@media screen and (max-width:800px) {
  padding: 5px 10px;
  bottom: 50px;
}
}
.active{
   text-decoration: underline;
   color: var(--style-blue-dark);
   cursor:pointer;
   margin-right: 10px;
  }
  .not-active{
    color: var(--style-blue-dark);
    cursor:pointer;
    margin-right: 10px;
  }
  .not-active:hover{
    color: #c6e2cf;
    /* transition: all 0.3s ease-in; */
    }
  .user-profile-right-widget{
    flex-basis: 60%;
    max-width: 700px;
    padding: 20px;
    background-color:var(--style-bright);
    border-radius: 4px;
    @media screen and (max-width:800px) {
   flex-basis: 100%;
   width: 100%;
   max-width: 100%;
  }
  }
  .profile-page-form{
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px;
    background-color:var(--style-bright);
    padding: 10px;
  }
  .edit-icon,.cancel-icon{
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 5px;
    font-size: 30px;
    color: var(--style-dark);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px
  }
  .input-non-edit{
    height: 50px;
    padding: 5px;
    outline: none;
    width: 100%;
    border-radius: 5px;
    border: none;
    background-color: var(--style-bright);
  }
  .input-edit:focus{
    background-color: var(--style-blue-dark);
    outline: 1px solid var(--style-blue);
  }

  .input-edit{
    height: 50px;
    padding: 5px;
    outline: none;
    width: 100%;
    border-radius: 5px;
    border: none;
    background-color: var(--style-blue-dark);
    color: #000;
  }
  .input-edit:focus{
    background-color: #c6e2cf;
    outline: 1px solid var(--style-blue);
  }

  .no-save-changes{
    display: none;
  }
  .save-changes{
    display: block;
    background-color: var(--style-blue);
    color: var(--style-bright);
  }
  .groove-container{
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px;
    background: var(--style-blue-dark);
    padding: 50px;
    max-width: 100%;
  }
  @media screen and (max-width:500px) {
    .groove-container{
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px;
    padding: 5px;
    width: 100%;
  }
  }
  .search-groove-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--style-bright);
    border-radius: 10px;
  }
  .search-groove{
    flex-basis: 90%;
    max-width: 100%;
    height: 40px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    color: gray;
    outline: none;
    border: none;
    padding: 5px 10px;
  }
  @media screen and (max-width:500px) {
    .search-groove{
      width: 100%;
    }
  }
  .groove{
    box-shadow: 1px 1px 1px 1px rgb(220,220,220);
    background: var(--style-blue-dark);
    margin: 20px;
    padding: 10px;
    border-radius: 10px;
    min-width: 180px;
    overflow-x: hidden;
    min-height: 100px;
    max-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in;
    position: relative;
  }
  .groove-wrapper{
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    
  }
  .groove-wrapper::-webkit-scrollbar{
    width: 10px;
    border-radius: 6px;
  }
  .groove-wrapper::-webkit-scrollbar-track{
    box-shadow: inset 0 0 10px rgb(0, 0, 0, 0.25);
  }
  .groove-wrapper::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background:var(--style-blue);
  }
  .groove-wrapper::-webkit-scrollbar-track-piece{
    background-color: var(--style-blue-dark);
  }
  .groove .link-to-groove{
    cursor:pointer;
    margin: auto;
    opacity: 0.6;
    width:200px;
    height:80px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .groove-name{
    text-shadow: 1px solid var(--style-blue-dark);
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 70px;
  }
  .groove-detail{
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    width:100%;
    box-shadow: 1px 1px 1px rgb(220,220,220);
    position: relative;
  }
  
  .groove-detail-container{
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    align-items: center;
    flex-basis: 40%;
    position: absolute;
    right: -50%;
    left:-50%;
    width: 300px;
    padding: 20px;
    opacity: 0.5;
    height: 150px;
    background-color: var(--style-blue);
    margin: 60px auto;
  }
  @media screen and (max-width:500px){
    .groove-detail{
      margin-top:30px;
    }
    .groove-detail-container{
      width: 100%;
      margin: 0px auto;
    }
  }
  .groove-detail-container-overlay{
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    align-items: center;
    flex-basis: 40%;
    position: absolute;
    right: -50%;
    left:-50%;
    width: 300px;
    padding: 20px;
    background: transparent;
    margin: 40px auto;
  }

  .groove-detail-title {
text-align: center;
padding: 20px;
}
@media screen and (max-width:500px) {
  .groove-detail-title {
text-align: center;
font-size: 12px;
padding: 5px;
} 
}
  .groove-detail-name{
text-align: left;
padding: 20px;
  }
  .groove-detail-bio{
    text-align: left;
    padding: 20px;
  }
  .groove-detail-address{
    text-align: left;
    padding: 20px;
  }
  .successful{
    text-align: center;
    padding: 4px 10px;
    background-color: var(--style-success);
    border: 1px solid var(--style-success);
    cursor: pointer;
    color: #c6e2cf;
    border-radius: 5px;
  }
  .loading{
    text-align: center;
    padding: 4px 10px;
    background-color: var(--style-bright);
    border: 1px solid var(--style-success);
    cursor: pointer;
    color: gray;
    border-radius: 5px;
  }
  img{
    max-width: 100%;
  }
  .my-groove-name{
    font-size: 20px;
    color: gray;
    margin-top: 10px;
  }
  .my-position{
    font-size: 16px;
    color: gray;
  }
  .mygroove-detail{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
    padding:20px 5px;
    width:100%;
  }
  .mygroove-name{
    color: var(--style-blue);
    font-size: 16px;
    text-transform: uppercase;
    padding: 5px;
  }
  .mygroove-address, .mygroove-bio{
    color: gray;
    font-size: 14px;
    padding: 5px;
  }
   .mygroove-phone{
    color: gray;
    padding: 5px;
   }
  .services-title{
    font-size: 14px;
    color: var(--style-blue);
    padding-top: 20px;
    text-transform: uppercase;
  }
  
  .my-groove-main-container{
max-width: 700px;
overflow-x: hidden;
  }

.mygroove-list{
  width: 300px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 1px rgb(220,220,220);
}
.mygroove-earned{
  color: var(--style-blue-dark);
  font-size: 16px;
  padding: 10px;
}
.mygroove-title{
  padding: 10px;
  color: var(--style-grey);
  font-size: 24px;
}
`
export default Profile;