export const currencies = [
    {symbol:'₦',name:'NGN'},
    {symbol:'¢',name:'GH'},
    {symbol:'$',name:'USD'},
    {symbol:'Դ',name:'AMD'},
    {symbol:'Kz',name:'AOA'},
    {symbol:'$',name:'ARS'},
    {symbol:'$',name:'AUD'},
    {symbol:'ƒ',name:'AWG'},
    {symbol:'MAH',name:'AZN'},
    {symbol:'KM',name:'BAM'},
    {symbol:'$',name:'BBD'},
    {symbol:'৳',name:'BDT'},
    {symbol:'лв',name:'BGN'},
    {symbol:'ب.د',name:'BHD'},
    {symbol:'₣',name:'BIF'},
    {symbol:'$',name:'BMD'},
    {symbol:'$',name:'BND'},
    {symbol:'Bs.',name:'BOB'},
    {symbol:'Bs.',name:'BOB'},
    {symbol:'R$',name:'BRL'},
    {symbol:'$',name:'BSD'},
    {symbol:'P',name:'BWP'},
    {symbol:'Br',name:'BYN'},
    {symbol:'$',name:'BZD'},
    {symbol:'$',name:'CAD'},
    {symbol:'₣',name:'CDF'},
    {symbol:'₣',name:'CHF'},
    {symbol:'$',name:'CLP'},
    {symbol:'¥',name:'CNY'},
    {symbol:'$',name:'COP'},
    {symbol:'₡',name:'CRC'},
    {symbol:'$',name:'CUP'},
    {symbol:'$',name:'CVE'},
    {symbol:'Kč',name:'CZK'},
    {symbol:'₣',name:'DJF'},
    {symbol:'kr',name:'DKK'},
    {symbol:'$',name:'DOP'},
    {symbol:'د.ج',name:'DZD'},
    {symbol:'£',name:'EGP'},
    {symbol:'Nfk',name:'ERN'},
    {symbol:'€',name:'EUR'}   
]