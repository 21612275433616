import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Home from './components/pages/home';
import Login from './components/pages/User/Login/Login';
import Confirmation from './components/pages/User/Mailing/Confirmation';
import Footer from './components/Parts/Footer/Footer'
import Nav from './components/Parts/Navbar/Nav'
import Dashboard from './components/pages/Dashboard/Dashboard';
import Support from './components/pages/Support/Support';
import NewPassword from './components/pages/User/NewPassword';
import PasswordReset from './components/pages/User/PasswordReset';
import Learn from './components/pages/Learn';
import { useSelector } from 'react-redux';
import Page from './404'
import Profile from './components/pages/User/Profile/Profile';
import About from './components/pages/Support/About';
import Faq from './components/pages/Support/Faq';
import Grooves from './components/pages/Grooves';
// import StackPay from './components/PaymentGateways/StackPay';
import ShareComponent from './components/pages/User/Profile/ShareComponent';
import Chat from './components/pages/chat';
import ManualPayment from './components/PaymentGateways/ManualPayment';
import ManualWithdrawal from './components/PaymentGateways/ManualWithdrawal';
import Loading from './Loading';
import axios from 'axios';


function App() {
  const user = useSelector(state=>state.auth.user)
  const app = useSelector(state=>state.apps.items)
// if(app){
  return (
  <div>
  <Router>
    <Nav />
    <Routes>
      <Route path='/' exact element ={<Home/>}/>
      <Route path = '/:referrer/register' element={user?<Navigate replace to = {`/${user?._id}/dashboard`}/>:<Login />}/>
      <Route path = {`/:token/:email/:referrer/authentication`} element={user?<Navigate replace to = {`/${user?._id}/profile`}/>:<Confirmation />}/>
      <Route path = '/login' element={user?<Navigate replace to = {`/${user?._id}/dashboard`} />:<Login />}/>
      <Route path = '/register' element={user?<Navigate replace to = {`/${user?._id}/profile`}/>:<Login />}/>
      <Route path='/forgotten-password' element ={<PasswordReset />}/>    
  <Route path='/:token/:email/create-new-password' element ={<NewPassword />}/>
      <Route path={`/${user?._id}/profile`} element ={user?._id?<Profile/>:<Login/>}/>
      <Route path={`/${user?._id}/dashboard`} element ={user?._id?<Dashboard/>:<Login/>}/>
      <Route path={`/${user?._id}/chat`} element ={user?._id?<Chat />:<Login/>}/>
      {/* <Route path={`/${user?._id}/:payee/:amount/:grooveId/:phone/:currency/:type/:nextWithdrawal/:requiredMembers/:description/:name/payment`} element ={user?._id?<StackPay/>:<Login/>}/> */}
      <Route path={`/${user?._id}/:payee/:amount/:grooveId/:phone/:currency/:type/:nextWithdrawal/:requiredMembers/:description/:name/payment`} element ={user?._id?<ManualPayment/>:<Login/>}/>
      <Route path={`/${user?._id}/:amount/:paymentId/:type/withdrawal`} element ={user?<ManualWithdrawal/>:<Login/>}/>
      <Route path='/grooves' element={<Grooves />} />
      <Route path='/link-share' element={<ShareComponent />} />
      <Route path='/support' element={<Support />} />
      <Route path = '/learn' element={<Learn />} />
      {/* <Route path='/about' element={<About />} /> */}
      <Route path='/faq' element={<Faq />} />
      <Route path='*' element ={<Home />}/>
    </Routes>
    <Footer/>
  </Router>
 </div>
  );
}
// return <Loading/>
// }
export default App;