import React, {useState} from 'react';
import copy from "copy-to-clipboard";
import { FaCopy, FaTimes } from "react-icons/fa";
import { Modal, makeStyles } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clientUrl } from '../../../../Redux/Api';
import { useNavigate } from 'react-router-dom';

const styles = makeStyles(prop=>({
    modal:{
        width:'60%',
        height:'150px !important',
        margin:'110px auto',
        color:'#333',
        [prop.breakpoints.down('md')]:{
            width:'85%',
            margin:'100px auto'
        }
    },
    modalContent:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'baseline',
        borderRadius:'5px',
        height:'150px !important',
        padding:'1.5rem',
        position:'relative',
    },
    refItems:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        flexDirection:'column',
        gap:'15px',
        flexBasis:'90%',
        padding:'15px',
        height:'150px !important',
        background:'var(--style-white)'
    },
    refOpts:{
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        flexWrap:'wrap',
        gap:'30px',
        width:'100%'
    },
    refLinks:{
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        width:'100%'
    },
    refLink:{
    fontSize:'15px',
    fontWeight:'bold',
    textAlign:'center',
    color:[prop.palette.primary.main]
    },
    refOpt:{
        fontSize:'14.5px',
        fontWeight:'lighter',
        cursor:'pointer',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        '&:hover':{
            transition:'ease-in 3ms',
            fontSize:'15px'
        }
    },
    icon:{
        fontSize:'24px',
        cursor:'pointer'
    },
    cancelWrapper:{
        flexBasis:'10%',
        position:'absolute',
        right:'20px',
        top:'20px',
    },
    cancel:{
        fontSize:'30px',    
        borderRadius:'50%',
        padding:'6px',
        cursor:'pointer',
        'hover':{
            color:'#fff'
        }
    }
    }))

const ShareComponent = () => {
    const navigate = useNavigate()
    const style = styles()
    const user = useSelector(state=>state.auth.user)
    const[open, setOpen] = useState(true)
    async function handleCopy(){
        copy(`${clientUrl}/${user?.userName}/register`)
     await toast.success('Referral link copied!.', {position: toast.POSITION.TOP_RIGHT})
      setOpen(false)
      return navigate(-1)
    }
    const handleCancel = ()=>{
        setOpen(false);
        navigate(-1);
    }
    return (
        <div style={{height:'60vh'}}>
            <ToastContainer />
            <Modal open={open} className={style.modal}>
<div classNclassNameame={style.modalContent}>
    <div className={style.refItems}>
<p className={style.refLink}>My Referral link</p>
<div className={style.refOpts}>
            <li className={style.refOpt}>{`${clientUrl}/${user?.userName}/register`}</li>
            <div className={style.refLinks}>
            <FaCopy className={style.icon} onClick={handleCopy} />
        <a href={`https://wa.me/?text=cash orbits - spend it save it ${clientUrl}/${user?.userName}/register`} rel='noreferrer' target="_blank">
        <Share className={style.icon} /></a> 
            </div>
        </div>
    </div>
    <div className={style.cancelWrapper}>
    <FaTimes onClick={handleCancel} className={style.cancel} />
    </div>
</div>
             </Modal>
        </div>
    );
};

export default ShareComponent;