import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { url } from "../Api"

const initialState = {bankName:'',bankAccountName:'',bankAccountNumber:'',status:''}

export const createBankAccount = createAsyncThunk(
    'bank/createBankAccount',
    async(bank,{rejectWithValue})=>{
        try {
           async function fetchData(){
            await fetch(`${url}/create-bank-account`,{
                method:'put',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                },
                body:JSON.stringify({
                    bankName:bank.bankName,
                    bankAccountName:bank.bankAccountName,
                    bankAccountNumber:bank.bankAccountNumber
                })
            }).then(res=>res.json())
            .then(response=>{
                if(response.resp){
                    return response.resp
                }
            })
           }
           return fetchData()
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
const bankSlice = createSlice({
    name:'bank',
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
            builder.addCase(createBankAccount.pending,(state,action)=>{
                return {...state,status:'pending'}
            })
            builder.addCase(createBankAccount.fulfilled,(state,action)=>{
                if(action.payload){
                    return {...state, status:'fulfilled'}
                }else{
                    return state
                }
            })
            builder.addCase(createBankAccount.rejected,(state,action)=>{
                return {...state,status:'rejected'}
            })
        }
})

export default bankSlice.reducer;