export const LearnContent = [
    {
        title:'Orbits',
        body:'Each orbit has its unique energy powered by the various levels of the orbit. The strength of an orbits relies on the levels of the orbit. So in order to make your orbit strong, make each level of the orbit strong.'
    },
    {
        title:'Level',
        body:'Each investment made forms a new and unique level in the current orbit. To make a level strong, investment with considerably high amount.'
    },
    {
        title:'How to earn big on cash orbits',
        body:'Make each investment higher than the previous one. Dont\'t increase the level strength only when you wan to move to higher orbits, within the orbit, strengthen your levels by always making the current amount higher than the previous. When you want to change orbit, pay higher than what the platform gives you to pay. This makes your levels strong and consequently strengthens your orbits.'
    },{
        title:'How to earn allowances',
        body:'Cash orbits pays its marketers allowances which can be withdrawn along side the salary. In order to earn some allowance, keep working even though you have accomplished your monthly task, extra investors brought earns the marketer some allowance.'
    }]

export const FaqContent = [{
    title:'Can I earn on cash orbits?',
    body:'Yes. You can earn on cash orbits. To earn on cash orbits, simply sign up using a working email and phone number. Afetr signup, credit your account and wait for an hour. You can wihdraw from your account after 24 hours of crditing your account.'
},
{
    title:'How can personal invesment help my day to day runnings?',
    body:'We understand that nomatter how we tell ourselves we are not going to spend our money untill we have accomplished what we had kept the money for, we see ourselves spending the money. Day to day spending is what man cannot do without. You certainly need to pay those bills, buy the food and extend some help to those you help or assist. Doing this shouldn\'t make you go bankrupt! You should spend yet save! This is what makes you financially free and acceptible. Your savings shouldn\'t prevent you from spending and your spending shouldn\'t prevent you from saving. Cash orbits makes you spend it and save it at the same time.'
},
{
    title:'I am a student, no job yet. How do I partake?',
    body:'Both students and salary earners can make huge some of money on cash orbits. You can start with as litle as you hav on you and then grow it. Everyday you can withdraw a sum, re-invest for the next day\'s withdrawals. You can also apply to work as one of our marketers and get paid monthly.'
},{
    title:'How much can I earn as a staff of cash orbits?',
    body:'You can earn from $200 and above just for working as one of our staffs from your home.'
},
{
    title:'What is the daily target?',
    body:'If you\'ll be working as our marketer, all you need do is post cash orbits on your social media platforms, make sure you get 30 active users in 30 days and on the 30th day, you can withdraw the sum of $200.'
},{
    title:'Do I have to refer someone before getting paid?',
    body:'No. Cash orbits doesn\'t make you refer in order to get paid. Just credit your account and come back after 24 hours to withdraw.'
},{
    title:'Do I get some referral benefits for referring?',
    body:'No. Cash Orbits is not a ponzi scheme. We cannot afford to pay our customers for referring someone as our income comes from solely from trading. We trade with the fund invested here and as we understand trading, success and failure are common factors.'
},
{
    title:'Other than investing on the platform, which other way can one get paid on cash orbits?',
    body:'If you want to get paid on cash orbits without investing any money, then you\'ll have to be working as one of our staffs. We employ those who want to work with us in the marketing department and pay them monthly. To apply kindly forward your application to employment@cashorbits.com. After the application, kindly registr on the platform. You\'ll be reached within 24 hours after your application was sent.'
},
{
    title:'What is groove?',
    body:'Cash orbits give room to group contribution. Members of a groove can contribute and raise a given target and they can withdraw it once the duration is reached.'
},
{
    title:'Is groove the same as the regular contribution people do?',
    body:'No. There is a slight difference between groove and the regular contribution people do. Here is te difference, while people reach out to those interested in contributing, contribute and give the sum to one member at a time without any interest, groove does the same thing but you dont\'t have to reach out to anyone, also while the former gives you just th capital you have invested, the later(groove) pays you with some interest.'
},
{
    title:'How can groove help me as business person?',
    body:'The main aim of the groove is to provide capital for start-ups, businesses and other financial needs which weigh higher than what one can offord. If you want to aquire land, home, buy a car, rent an apartment or start a new business without borrowing money, nor touching your savings, groove is what you need.'
},
{
    title:'What\'s health?',
    body:'Health is an algorithm cash orbits uses to calculate how well an investor adheres to instructions given on the platform. When a user signs up, they have a healthy account meassured as 100%. When the default, the health reduces from 100%.'
},
{
    title:'What\'s the implication of having an unhealthy account?',
    body:'An invstor whose acoount\'s health is less than 100% cannot create nor manage a groove. They have withdrawal problems.'
},
{
    title:'How do I maintain a 100% health?',
    body:'Pay on or before when due. Since there may be delay in bank transactions, transaction failure etc, don\'t wait until the expiration before you think of making payments.'
}]

export const AboutContent = [
    {
    title:'About Cash Orbits',
    body:'Cash orbits is a platform where money is kept in orbits and continuously orbited using space and time as fuel to keep the orbit in its place.'
},
{
    title:'What\'s Cash Orbits',
    body:'Cash orbits lets you spend your money everyday yet able to save money everyday.'
},
{
    title:'Earning Method',
    body:'Cash Orbits lets you spend your money everyday yet able to save money everyday. It uses time and movement to work, so when either time, it allows people who have big budgets but do not have enough fund to form a groove(group) where each member pays a given sum of money and is able to withdraw with profit.'
},  {
    title:'Groove',
    body:'Cash Orbits lets you spend your money everyday yet able to save some money everyday. It uses time and movement to work, and allows people who have big budgets but do not have enough fund to form a groove(group) where each member contributes a given sum of money and is able to withdraw with profit.'
},  {
    title:'Personal Accounts',
    body:'A personal account is created for all users during signup and they can start investing immediately and withdraw after 24 hours. Investment goes in orbits and grows from one orbit to the another.'
},  {
    title:'',
    body:''
},{
    title:'',
    body:''
},  {
    title:'',
    body:''
},
]