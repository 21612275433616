import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setAuthHeader, url } from "../Api";

const initialState = {
    items:!localStorage.getItem('payments')&&[],
    status:null
}
export const getPayments = createAsyncThunk(
    'payments/getPayments',
    async()=>{
        try {
          const response = await axios.get(`${url}/payments`,setAuthHeader());
          return response.data.resp
        } catch (error) {
            return
        }
    }
)
const PaymentsSlice = createSlice({
    name:'payments',
    initialState,
    reducers:{},
    extraReducers:{
        [getPayments.pending]:(state,action)=>{state.status = 'pending'},
        [getPayments.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getPayments.rejected]:(state,action)=>{state.status='rejected'}
}
})

export default PaymentsSlice.reducer;