import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setAuthHeader, url } from "../Api";

const initialState = {
    items:[],
    status:null
}
export const getReferrers = createAsyncThunk(
    'referrers/getReferrers',
    async()=>{
        try {
          const response = await axios.get(`${url}/referrers`,setAuthHeader())  
          return response.data
        } catch (error) {
            return
        }
    }
)
const referrersSlice = createSlice({
    name:'referrers',
    initialState,
    reducers:{},
    extraReducers:{
        [getReferrers.pending]:(state,action)=>{state.status = 'pending'},
        [getReferrers.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getReferrers.rejected]:(state,action)=>{state.status='rejected'}
}
})

export default referrersSlice.reducer;