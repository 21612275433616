import React, { useEffect, useState } from 'react';
import { Modal, makeStyles } from '@material-ui/core';
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { url } from '../../Redux/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { Spinner } from '../../Loading';

const styles = makeStyles(prop=>({
    modal:{
        width:550,
        height:400,
        margin:'100px auto',
        borderRadius:'5px',
        padding:'20px',
        [prop.breakpoints.down('md')]:{
            width:'90vw',
            margin:'30px auto'
        }
    },
    modalContainer:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:'100%',
        background:'var(--style-bright)',
        gap:'15px',
        height:'100%',
        flexDirection:'column'
    },
    type:{
    paddingTop:'20px',
    color:'var(--style-dark-bg)',
    fontSize:'20px',
    fontWeight:500,
    textAlign:'center',
    textTransform:'capitalize'
    },
    priceInfo:{
        color:'var(--style-dark-bg)',
        fontSize:'14px',
        fontWeight:300,
        textAlign:'center',
        textTransform:'capitalize'
        },
    form:{
        display:'flex',
        justifyContent:'space-between',
        width:'100%',
        padding:'20px',
        alignItems:'center',
        gap:'15px',
        flexDirection:'column',
        position:'relative'
    },
    formItem:{
        display:'flex',
        justifyContent:'flex-start',
        width:'100%',
        padding:'5px',
        alignItems:'center',
        gap:'15px',
    },
    span:{
        color:'var(--style-gray)',
        fontSize:'14px',
        textAlign:'left'
    },
    required:{
        color:'red',
        fontSize:'14px'
    },
    input:{
    width:'80%',
    padding:'10px',
    height:'35px',
    borderRadius:'4px',
    color:'var(--style-grey)',
    border:'none',
    outline:'none'
    },
    button:{
        color:'var(--style-bright)',
        padding:'10px 25px',
        borderRadius: '4px',
        border: 'none',
        backgroundColor: 'var(--style-blue-dark)',
        cursor: 'pointer',
        fontWeight: 600,
        'hover':{
        color: 'var(--style-blue-cyan)',
        transition: 'var(--transition)',
        fontWeight: 500
        }
    
    },
    closer:{
        color:'var(--style-dark-bg)',
        cursor:'pointer',
        fontSize:'18px',
        position:'absolute',
        right:'30px',
        top:'30px'
    }
    }))

const Grooves = () => {
    const style = styles()
    const[email, setEmail] = useState('')
    const[subscriptionSuccess, setSubscriptionSuccess] = useState('')
    const[subscriptionError, setSubscriptionError] = useState('')
    const[subscriptionLoading, setSubscriptionLoading] = useState(false)
    const [loadingPayment, setLoadingPayment] = useState(false);
    const [open, setOpen] = useState(false);
    const [groove, setGroove] = useState({});
    const [mini, setMini] = useState(Number)
    const [maxi, setMaxi] = useState(Number)
    const [amount, setAmount] = useState(Number);
    const grooves = useSelector(state=>state.grooves.items)
    const app = useSelector(state=>state.apps.items)
    const user = useSelector(state=>state.auth.user)

    useEffect(()=>{
    setMaxi(groove?.target-(app?.grooveRate*groove?.target));
    setMini(((groove?.target-groove?.target*app?.grooveRate)/groove?.requiredMembers))
},[groove,app])
const navigate = useNavigate()

const handleSubmit = e=>{
    e.preventDefault()
    setSubscriptionLoading(true)
    async function fetchData(){
await fetch(`${url}/newsletter-subsrciption`,{
    method:'post',
    headers:{
        'Content-Type':'application/json',
        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
    },
    body:JSON.stringify({
        email,appId:app?._id
    })
}).then(res=>res.json())
.then(resp=>{
    if(resp.response){
        setSubscriptionSuccess(resp.response)
        setSubscriptionLoading(false)
    }else if (resp.err){
        setSubscriptionError(resp.err)
        setSubscriptionLoading(false)
    }
}).catch(err=>{
    if(err){
        setSubscriptionError(err.message)
        setSubscriptionLoading(false)
    }
})
    }
    fetchData()
}
    const handleJoin = (e)=>{
        e.preventDefault()
        if(user){
            async function fetchData(){
                await fetch(`${url}/join-groove/${groove?._id}`,{
                    method:'put',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    },body:JSON.stringify({amount})
                }).then(res=>res.json())
                .then(resp=>{
                    if(resp.resp){
                        toast.success('Processing...', {position: toast.POSITION.TOP_RIGHT})
                        return navigate(`/${user?._id}/${user.email}/${amount}/${groove?._id}/${user.phone}/${app?.currency}/join-groove/0/0/description/name/payment`)
                    }else if(resp.err){
                        toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
                        setLoadingPayment(false)
                    }
                })
            }
            return fetchData()
        }else{
            navigate('/login')
        }
    }
    const setHandleOpen = (e)=>{
        setOpen(true)
        setGroove(e)
    }
    return (
        <Main>
            <div className='groove-container'>
            <ToastContainer />
                <div className='grooves-middle'>
                <h5 className='grooves-middle-section-title'>Grooves</h5>
                    <Modal open={open} className={style.modal}>
                    <div className={style.modalContainer}>
                        <h3 className={style.type}>Join {groove.name}</h3>
                        <p className={style.priceInfo}>Minimum {app.currency}{maxi} or {app.currency}{mini}</p>
                        <FaTimes className={style.closer} onClick={()=>setOpen(false)} />
                        <form className={style.form} onSubmit={handleJoin}>
                        <div className={style.formItem}>
                        <span className={style.span}>
                            Amount</span>
                            <input type='number' step='any' min='0' placeholder='Enter amount' 
                            className={style.input} onChange={e=>setAmount(e.target.value)} />
                            <span className={style.required}>*</span>
                            </div>
                            {
                                loadingPayment?<FaSpinner />:<button type='submit' className={style.button}>Continue</button>
                            }
                        </form>
                    </div>
                    </Modal>
                <div  className='grooves-middle-content'>
                    {grooves?.length>0?
                        grooves?.map((item,index)=>{
                            return(<div key={index} className='grooves-middle-content-items'>
                                {
                                    item.image===''&&<img src={item.image} alt={item.name} className='groove-image' />
                                }
                                <h6 className='grooves-title'>{item.name}</h6>
                                <p className='grooves-body'>{item.description}</p>
                               <div className='groove-wrapper'>
                               <h6 className='grooves-members'>{`${item.members.length}/${item.requiredMembers}`}</h6>
                                <h6 className='grooves-target'>{app?.currency}{item.target}</h6>
                                {!user&&<button onClick={()=>setHandleOpen(item)} className='join-groove-btn'>Join Us</button>}
                                {
                                 user&&item.members.filter(filt=>filt.member.toString()===user.email.toString()).length<1&&item.members.length<item.requiredMembers?<button onClick={()=>setHandleOpen(item)} className='join-groove-btn'>Join Us</button>:(user&&<Link to={`/${user?._id}/dashboard`}>Visit</Link>)
                                }
                               </div>
                                </div>)
                        })
                    :<Link to={`/${user?._id}/profile`} className='join-groove-btn'>Be the firs to create a groove</Link>}
                </div>
                </div>
                <div className='grooves-right'>
                    <form onSubmit={handleSubmit} className='newsletter-form'>
                        {
                            subscriptionSuccess!==''?<p className='subscription-success'>{subscriptionSuccess}</p>:
                            subscriptionError!==''?<p className='subscription-error'>{subscriptionError}</p>:<p className='newsletter-form-title'>Subscribe to newsletter</p>
                        }
                        <input type='email' placeholder='Enter email' onChange={e=>setEmail(e.target.value)} className='newsletter-input' />
                        {
                            subscriptionLoading?
                             <Spinner />//<FaSpinner className='grooves-image-loading' />
                            :<button type='submit' className='newsletter-btn'>Subscribe</button>
                            }
                    </form>
                </div>
            </div>
        </Main>
    );
};

const Main = styled.div`
width:100%;
padding: 20px;
display: flex;
min-height: 80vh;
height: auto;
    justify-content: center;
    flex-direction: column;
    background: var(--style-white);
    align-items: center;
.groove-container{
    width: 100%;
    padding: 10px 20px;
    background-color: var(--style-bright);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap:10px;
    @media screen and (max-width: 850px){
        flex-direction: column;
        padding: 5px 5px;
    }
}
.grooves-middle{
    flex-basis: 75%;
    padding:30px 20px;
    background:var(--style-white);
    @media screen and (max-width: 850px) {
    flex-basis: 100%;
    width:100%;
}
}
.grooves-middle-content{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px;
    border-radius: 10px;
    margin-top: 20px;
    margin:auto;
    width:80%;
    flex-wrap:wrap;
    gap:20px;
    @media screen and (max-width: 850px){
        padding: 5px;
        width:100%;
    }
}
.grooves-middle-section-title{
    text-align: center;
    padding: 10px;
    color: var(--style-blue-dark);
    font-size: 20px;
    font-weight: bold;
}
.grooves-middle-content-items{
    width: 40%;
    height: 200px;
    padding: 10px;
    box-shadow: 1px 2px 1px rgb(210,210,210);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px;
    background-color: var(--style-white);
    @media screen and (max-width: 850px){
        padding: 5px 5px;
        width:100%;
        height:350px;
    }
}
.grooves-title{
 font-size:20px;
 font-weight: bold;
 padding: 10px 0px;
 text-align: center;
 color: var(--style-grey);
}

.groove-wrapper{
    display:flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}
.grooves-body{
    text-align: justify;
    font-size: 14px;
    color:var(--style-grey);
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    max-width: 300px;
    padding: 5px;
}
.grooves-body:hover{
    max-width: fit-content;
}
.grooves-members{
    text-align: center;
    font-size: 12px;
    color:var(--style-grey);
}
.grooves-target{
    text-align: justify;
    font-size: 14px;
    color:var(--style-blue-dark);
}
.join-groove-btn{
    padding: 5px 10px;
    border-radius: 4px;
    background:var(--style-blue-dark);
    color:var(--style-blue-cyan);
    border: none;
    cursor:pointer;
    transform: scale(1);
    &:hover{
        transform: scale(1.1);
        transition:var(--transition);
    }
}
.groove-image{
    width:90%;
    margin: auto;
    cursor: pointer;
    transform: scale(1);
    &:hover{
        transform: scale(1.1);
        transition:var(--transition);
    }
}
.grooves-right{
    margin-top: 20px;
    flex-basis: 25%;
display: flex;
justify-content: space-between;
align-items: center;
@media screen and (max-width: 850px) {
    flex-basis: 100%;
    width:100%;
}
}
.newsletter-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height:150px;
    padding:20px;
    gap:15px;
    background-color: var(--style-white);
}
.subscription-success{
color: var(--style-success);
margin-bottom: 15px;
text-align:center;
}
.subscription-error{
    color: var(--style-error);
    margin-bottom: 15px;
    text-align:center;
}
.newsletter-form-title{
text-align: center;
color: var(--style-blue-dark);
font-size: 16px;
} 
.newsletter-input{
height: 35px;
width: 90%;
border-radius: 5px;
padding: 5px;
border: 1px solid lightgray;
} 
.newsletter-btn{
    background-color: var(--style-blue-dark);
    color: var(--style-blue-cyan);
    margin-bottom: 20px;
    padding:5px 10px;
    border-radius:3px;
    cursor:pointer;
    outline: none;
    border: none;
}
.grooves-image-loading{
    color: var(--style-blue-dark);
}
`
export default Grooves;