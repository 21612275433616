import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "./Api";

export const groovesApi = createApi({
  reducerPath: "groovesApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${url}`}),
  endpoints: (builder) => ({
    getAllgrooves: builder.query({
      query: () => `grooves`,
    }),
  }),
});

export const { useGetAllgroovesQuery } = groovesApi;
