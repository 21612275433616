import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const UserDetailsPage = () => {
  const user = useSelector(state=>state.auth.user)
  const myGrooves = useSelector(state=>state.myGrooves.items)
    return (
        <Main>
    <p className='personal-details-title'>User info</p>
    <ul className='detail-items'>
      <li className='detail-list'><p>User Name</p><p className='detail-item'>{user?.userName}</p></li>
      <li className='detail-list'><p>First Name</p><p className='detail-item'>{user?.firstName}</p></li>
      <li className='detail-list'><p>Last Name</p><p className='detail-item'>{user?.lastName}</p></li>
      <li className='detail-list'><p>Email</p><p className='detail-item'>{user?.email}</p></li>
      <li className='detail-list'><p>My grooves</p><p className='detail-item'>{myGrooves?.length}</p></li>
      <li className='detail-list'><p>Nationality</p><p className='detail-item'>{user?.country.label}</p></li>
    </ul>
</Main>
    );
};

const Main = styled.div`
.personal-details-title{
  text-align: center;
  color:gray;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.detail-items{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.detail-list{
  display:flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px;
  padding: 10px;
  gap: 100px;
  border-radius: 5px;
  color:var(--style-blue-dark);
  background-color: var(--style-bright);
}
.detail-item{
  color:var(--style-blue-dark);
}
@media screen and (max-width:500px) {
    .detail-list{
        gap:10px;
    }
}
@media screen and (max-width:300px) {
    .detail-list{
        flex-wrap: wrap;
        gap: 10px;
    }
}
`
export default UserDetailsPage;