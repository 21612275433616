import React, { useEffect, useState } from 'react';
import {makeStyles, Modal} from '@material-ui/core'
import {FaSpinner, FaTimes} from 'react-icons/fa'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import { Close } from '@material-ui/icons';
import { url } from '../../../Redux/Api';

const styles = makeStyles((theme)=>({
    modal:{
        width: '60% !important',
        height: '50% !important',
        borderRadius: '10px !important',
        margin:'auto',
        background: 'hsl(210,55%,8%) !important',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:'20px',
        flexDirection:'column',
        position:'relative'
    },
    reportForm:{
        width:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
    },
    formTitle:{
        textAlign:'center',
        padding:'20px',
        color:'gray'
    },
    success:{
        color:'green',
        textAlign:'center',
        padding:'20px'
    },
    error:{
        color:'red',
        textAlign:'center',
        padding:'20px'
    },
    replyBody:{
        width:'50%',
        minHeight:'50%',
        height:'auto',
        padding:'10px',
        '&:focus':{
            outline:'none',
        }
    },
    collapse:{
        cursor:'pointer',
        color:'gray',
        position:'absolute',
        right:'20px',
        top:'20px'
    },
    btn:{
        padding:'5px 40px',
        lineHeight:'20px'
    }

}))

const Reports = (props) => {
  const{sentMessages,receivedMessages} = props
    const[replyForm, setReplyForm] = useState(false)
    const[data, setData] = useState()
    const[content, setContent] = useState()
    const[loading, setLoading] = useState(false)
    const[error, setError] = useState('')
    const[success, setSuccess] = useState('')
    const app = useSelector(state=>state.apps.items)
const openReply = e=>{
    setReplyForm(true)
    setData(e)
}
useEffect(()=>{
    async function fetchData(){
        await fetch(`${url}/read-user-report`,{
        method:'put',
        headers:{
            'Content-Type':'application/json',
            'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
        }
        }).catch(err=>{
            if(err){
                setError(err.message)
            }
        })
            }
            fetchData()
})
const sendReply = e=>{
    e.preventDefault()
    async function fetchData(){
await fetch(`${url}/reply-user-report`,{
method:'put',
headers:{
    'Content-Type':'application/json',
    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
},body:JSON.stringify({
    content,
    reportId:data.reportId,receiver:data.sender,app:app._id
})
}).then(res=>res.json())
.then(resp=>{
    if(resp.resp){
    setReplyForm(false)
    setSuccess(resp.resp)
    setLoading(false)
    setReplyForm(false)
    }else if(resp.err){
        setError(resp.err)
        setLoading(false)
        setReplyForm(false)
    }
}).catch(err=>{
    if(err){
        setError(err.message)
        setLoading(false)
        setReplyForm(false)
    }
})
    }
    fetchData()
}
const style = styles()
    return (
        <Main>
            <Modal open={replyForm} className={style.modal} >
            <form onSubmit={sendReply} className={style.reportForm}>
                <Close className={style.collapse} onClick={()=>setReplyForm(false)} />
                {success!==''?<p className={style.success}>{success}</p>:error!==''?<p className={style.error}>{error}</p>:<p  className={style.formTitle}>Replying {data?.subject}</p>}
                <textarea type='text' placeholder='Content' className={style.replyBody} onChange={e=>setContent(e.target.value)} />
                {loading?<FaSpinner />:<button type='submit' className={style.btn}>Send</button>}
            </form>
            </Modal>
           {
            sentMessages?.length>0?
            <div className='sent-message-container'>
            {
                sentMessages?.length>0&&sentMessages?.map((item,index)=>{
return (<div key={index} className={item?.read===true?'read-messages-container':'unread-messages-container'}>
<h5 className={item?.read===true?'read-messages':'unread-messages-sent'}>{item?.subject}</h5>
<p>{item?.content}</p>
</div>)
                })
            }
        </div>:<div className='no-reports'><h3  className='no-reports-caption'>No reports for now</h3></div>
           }
        {
            receivedMessages.length>0&&
            <div className='received-message-container'>
            {
                receivedMessages.map((item,index)=>{
return (<div key={index} className={item?.read===true?'read-messages-container':'unread-messages-container'}>
<h5 className={item?.read===true?'read-messages':'unread-messages-received'}>{item?.subject}</h5>
<p>{item?.content}</p>
{
    item?.replied===false?<button onClick={()=>openReply(item)}>Reply</button>:null
}
</div>)
                })
            }
        </div>
        }
        </Main>
    );
};

const Main = styled.div`
position:relative;
width: 95%;
height: 70vh;
margin-top: 20px;
padding-top: 10px;
padding-bottom: 50px;
display: flex;
align-items: center;
background: hsl(210,25%,8%);
overflow-y: auto;
@media screen and (max-width: 950px){
    overflow-x: hidden;
    width: 100%;
    padding: 5px 5px;
    align-items: flex-end;
}
.modal{
    width: 400px !important;
    height: 300px !important;
    border-radius: 10px !important;
    background: hsl(210,25%,8%) !important;
}
.collapse{
    cursor: pointer;
    padding: 20px;
}
.received-message-container{
position: absolute;
right: 20px;
background: #000;
padding:20px;
padding-bottom: 30px;
padding-left: 30px;
border-bottom-left-radius: 20px;
border-top-left-radius: 20px;
border-bottom-right-radius: 50px;
width:50%;
}
.sent-message-container{
position: absolute;
left: 20px;
background: hsl(210,10%,15%);
padding:20px;
padding-bottom: 30px;
padding-left: 30px;
border-bottom-left-radius: 50px;
border-top-right-radius: 20px;
border-bottom-right-radius: 20px;
width:50%;
}
.unread-messages-received{
    color: green;
}
.unread-messages-sent{
    color: cyan;
}
.no-reports{
    position:absolute;
    top:20px;
}
.no-reports-caption{
    text-align:center;
    font-size:20px;
    padding:16px;
    margin-top:20px;
    font-weight: lighter;
}
`
export default Reports;