import { configureStore } from '@reduxjs/toolkit';
import userReducer, { loadUser } from './Slices/AuthSlice';
import usersReducer, { getUsers } from './Slices/usersSlice';
import bankReducer from './Slices/BankSlice';
import appReducer, { getApps } from './Slices/appSlice';
import { usersApi } from './UsersApi';
import { groovesApi } from './GroovesApi'
import { myGroovesApi } from './MyGroovesApi'
import { paymentsApi } from './PaymentsApi'
import groovesReducer, { getGrooves } from './Slices/GroovesSlice'
import MyGroovesReducer, { getMyGrooves } from './Slices/myGroovesSlice'
import PaymentsReducer, { getPayments } from './Slices/PaymentsSlice';
import ReferrersReducer, { getReferrers } from './Slices/ReferrersSlice';

export const store = configureStore({
  reducer: {
    auth:userReducer,
    users:usersReducer,
grooves:groovesReducer,
myGrooves:MyGroovesReducer,
payments:PaymentsReducer,
referrers:ReferrersReducer,
bank:bankReducer,
    apps:appReducer,
    [usersApi.reducerPath]:usersApi.reducer,
    [groovesApi.reducerPath]:groovesApi.reducer,
    [myGroovesApi.reducerPath]:myGroovesApi.reducer,
    [paymentsApi.reducerPath]:paymentsApi.reducer
  },
  middleware:(getDefaultMiddleware)=>(
    getDefaultMiddleware().concat(usersApi.middleware)
  ),
  devTools: process.env.NODE_ENV !== 'production',
})

store.dispatch(getApps())
store.dispatch(loadUser())
store.dispatch(getUsers())
store.dispatch(getGrooves())
store.dispatch(getMyGrooves())
store.dispatch(getPayments())