import React,{useState} from 'react';
import styled from 'styled-components';
import Moment from 'moment'
import './UserComponent.css'
import { makeStyles, Modal } from '@material-ui/core';
import { FaSpinner, FaTimes} from 'react-icons/fa'
import { Delete, GridOn, List } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { url } from '../../../Redux/Api';
import { Spinner } from '../../../Loading';

const styles = makeStyles(theme=>({
    modalDetail:{
        width:'100%',
        height:'fit-content',
        marginBottom:'40px',
        margin:'10px auto'
    },
    deleteModal:{
        width:500,
        height:'fit-content',
        margin:'100px auto',
        borderRadius:5,
        padding:'1rem',
        background:'hsl(210,25%,8%)',
        color:'gray',
        boxShadow:'1px 2px 1px var(--bg-primary-color)',
        [theme.breakpoints.down('md')]:{
            width:'90vw'
        }
    },
    deleteFirstCentent:{
        margin: '1rem auto',
        display: 'flex',
        flexDirection:'column',
        alignItems:'center',
        gap: '2rem',
        p:{
            color: 'red'
        }
    }
    ,
    deleteModalCentent:{
        padding: '1rem',
    },
    deleteModalBtnGroup:{
        display: 'flex',
        justifyContent:'space-around',
        flexWrap:'wrap',
        alignItems:'center',
        marginBottom:'1rem',
    },
    noBtn:{
        background: 'var(--style-bright)',
        padding:'8px 20px',
        color:'var(--style-blue)',
        border:'thin solid var(--style-blue)',
        outline:'none',
        cursor:'pointer',
        borderRadius:'4px'
    },
    yesBtn:{
        background: 'var(--style-bright)',
        padding:'8px 20px',
        color:'var(--style-error)',
        border:'thin solid var(--style-error)',
        outline:'none',
        cursor:'pointer',
        borderRadius:'4px'
    }
}))
const UsersComponent = () => {
    const[detail,setDetail] = useState(null)
    const[openDeleteModal, setOpenDeleteModal] = useState(false)
    const[currentUser, setCurrentUser] = useState(null)
    const [success, setSuccess] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [serverError, setServerError] = useState('')
    const [isloading, setIsloading] = useState(false)
    const[display, setDisplay] = useState(true)
    const users = useSelector(state=>state.users.items)
    const app = useSelector(state=>state.apps.items)
    const [approvalsLoading, setApprovalsLoading] = useState(false);
    
    const approveCashierApplication = (e)=>{
        setIsloading(true)
        fetch(`${url}/create-cashier`,{
            method:'post',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },
            body:JSON.stringify({
                email:e
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                toast.success(resp.resp, {position: toast.POSITION.TOP_RIGHT})
                setApprovalsLoading(false)
            }else if(resp.err){
                toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
                setApprovalsLoading(false)
            }else{
                toast.error('Something went wrong. Try again.', {position: toast.POSITION.TOP_RIGHT})
                setApprovalsLoading(false)
            }
        }).catch(err=>toast.error(err.message, {position: toast.POSITION.TOP_RIGHT}))
    }

const style = styles()
    const deleteAccount = (e)=>{
        setOpenDeleteModal(true)
        setCurrentUser(e)
    }
    const[penalty, setPenalty] = useState(0)
    const[pardon, setPardon] = useState(0)
    const pardonHandler = (e)=>{
        e.preventDefault()
        fetch(`${url}/pardon-user/${detail?._id}`,{
            method:'put',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },body:JSON.stringify({pardon})
         }).then(res=>res.json())
         .then(resp=>{
            if(resp.err){
                toast.error(resp.err,{position:toast.POSITION.TOP_RIGHT})
                return setOpen(false)
            }else if(resp.resp){
                toast.success(resp.resp,{position:toast.POSITION.TOP_RIGHT});
                return setOpen(false)
            }
         }).catch(err=>{toast.error(err.message,{position:toast.POSITION.TOP_RIGHT});return setOpen(false)})
    }
    const penalizeHandler = (e)=>{
        e.preventDefault()
        fetch(`${url}/penalize-user/${detail?._id}`,{
            method:'put',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },body:JSON.stringify({penalty})
         }).then(res=>res.json())
         .then(resp=>{
            if(resp.err){
                toast.error(resp.err,{position:toast.POSITION.TOP_RIGHT})
                return setOpen(false)
            }else if(resp.resp){
                toast.success(resp.resp,{position:toast.POSITION.TOP_RIGHT});
                return setOpen(false)
            }
        }).catch(err=>{toast.error(err.message,{position:toast.POSITION.TOP_RIGHT});return setOpen(false)})
    }
    const confirmDeleteAccount = ()=>{
        setOpenDeleteModal(true)
        setIsloading(true)
        fetch(`${url}/delete-account`,{
            method:'put',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },
            body:JSON.stringify({
                id:currentUser._id
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                setSuccess(resp.resp)
            }else if(resp.err){
                setErrorMsg(resp.err)
            }else{
                setServerError('Sorry, there was an error. Please try again.')
            }
        })
        setSuccess('')
        setServerError('')
        setErrorMsg('')
        setIsloading(false)
        setOpenDeleteModal(false)
    }
    const formatDate = Moment().format('DD-MM-YYYY')
    const[open,setOpen] = useState(false)
    const[openCheck,setOpenCheck] = useState(false)
    
    const handleModal = (e)=>{
        setOpen(true)
        setDetail(e)
    }
    
    return (
        <Main> 
            <ToastContainer />
       {
            success !==''?<h4>{success}</h4>:errorMsg !==''?<h4>{errorMsg}</h4>:
            serverError !==''?<h4>{serverError}</h4>:<h4>{users.length}&nbsp;{users.length>1? 'users':' user'} as of {formatDate}</h4>
        }
            <SubContainer>
          <DetailsContainer>
          {
            users&&<div className='display-container'>
            <GridOn onClick={()=>setDisplay(true)} className={display?'grid-view-active':'grid-view-in-active'} />
             <List onClick={()=>setDisplay(false)} className={!display?'list-view-active':'list-view-in-active'} />
      </div>
          }
          <Modal open={openDeleteModal} className={style.modalDetail}>
            <UserDetail>
            <div className={style.deleteModalCentent}>
                  <div className={style.deleteFirstCentent}>
                  <p>{currentUser?.userName}'s acoount would be deleted parmanently and can't be undone.</p>
                  <h6>Click 'Yes' to parmanently delete this account or 'No' to cancel the process.</h6>
                  <h5>Do you want to continue?</h5>
                  </div>
                  <div className={style.deleteModalBtnGroup}>
                  {isloading? <Spinner />:
                 <> <button className={style.noBtn} onClick={()=>setOpenDeleteModal(false)}>No</button>
                 <button className={style.yesBtn} onClick={confirmDeleteAccount}>Yes</button></>
              }
              </div>
                  </div>
            </UserDetail>
          </Modal>
         <div className={display?'detail-grid-view':'detail-list-view'}>
         {
              users?.length >=1? users?.map(item=>{
                  return (
                      <div key={item._id} className={display?'grid-user-item':'list-user-item'}>
                      {/* <Delete onClick={()=>deleteAccount(item)} className='delete-user' /> */}
                      {
                        app.applicants.filter(filt=>filt===item.email).length>0&&
                        <>
                        {
                            approvalsLoading?<FaSpinner />:
                            <button onClick={()=>approveCashierApplication(item?.email)} className='approval-btn'>Approve cashier</button>
                        }
                        </>
                      }
                      <span>
                      {item.isCashier&&<h1 style={{color:'var(--style-lemon)',fontSize:'30px'}}>.</h1>}
                      Username: {item.userName}</span>
                      <span>First Name: {item.firstName}</span>
                      <span>Last Name: {item.lastName}</span>
                      <span onClick={()=>handleModal(item)} style={{cursor:'pointer',fontSize:'25px'}}>...</span>
                      <Modal open={open} className={style.modalDetail}>
                        <UserDetail>{openCheck?
                        <div className='form-wrapper'>
                            <div className='info-wrapper'><FaTimes onClick={()=>setOpenCheck(false)}/> &nbsp;{detail?.health}%</div>
                        <form className='health-form' onSubmit={penalizeHandler}>
                          <input className='helth-input' type='number' step='any' min={0} placeholder='Penalty value' onChange={(e)=>setPenalty(e.target.value)} />
                          {penalty>0&&<button className='health-btn' type='submit'>Penalize by %&nbsp;{penalty}?</button>}
                        </form>
                        {
                          detail?.health<100&&<form className='health-form' onSubmit={pardonHandler}>
                          <input className='helth-input' type='number' step='any' min={0} placeholder='Pardon value' onChange={(e)=>setPardon(e.target.value)} />
                          {pardon>0&&<button className='health-btn' type='submit'>Pardon by % &nbsp;{pardon}?</button>}
                        </form>}
                        </div>:
                        <>
          <li><p>Username</p>{detail?.userName}</li>
          <li><p>First Name</p>{detail?.firstName}</li>
          <li><p>Last Name</p>{detail?.lastName}</li>
          <li><p>Phone</p>{detail?.phone}</li>
          <li><p>Email</p>{detail?.email}</li>
          <li><p>Country</p>{detail?.country}</li>
          <li><p>Status</p>{detail?.status}</li>
          <li><p>Passwords</p>{detail?.usedPasswords.length}</li>
          <li><p>Last Visit</p>{moment(detail?.lastVisit).format('DD-MM-YYYY')}</li>
          <li><p>Joined</p>{moment(detail?.date).format('DD-MM-YYYY')}</li>
          <li><p>Last Update</p>{moment(detail?.updatedAt).format('DD-MM-YYYY')}</li>
          <span><FaTimes onClick={()=>setOpen(false)} className='close' /></span>
          <button  className='health-btn' onClick={()=>setOpenCheck(true)}>Penalty check</button></>}
      </UserDetail>
  </Modal>
                      </div>
                  )
              }):''
          }
         </div>
         </DetailsContainer>
            </SubContainer>
        </Main>
    );
};
const Main = styled.div`
width:90%;
/* padding:1rem; */
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
/* margin: 50px; */
background-color:var(--style-dark-bg);
.approval-btn{
    color:var(--style-bright);
padding:10px 25px;
    border-radius: 4px;
    border: none;
    background-color: var(--style-blue-dark);
    cursor: pointer;
    font-weight: 600;
    &:hover{
    color: var(--style-blue-cyan);
    transition: var(--transition);
    font-weight: 500;
    }
}
@media screen and (max-width:1000px){
    margin: 0;
    gap: 10px;
    padding: 5px 5px;
    margin-bottom: 30px;
}
h4{
    text-align: center;
    font-size: 16px;
    color: gray;
}
.grid-view-active{
    background: gray;
    cursor:pointer;
    color: black;
}
.grid-view-in-active{
    background: black;
    cursor:pointer;
    color: gray;
}
.list-view-active{
    background: black;
    cursor:pointer;
    color: gray;
}
.list-view-in-active{
    background: gray;
    cursor:pointer;
    color: black;
}
.display-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    padding: 10px 5px;
    box-shadow: 1px 1px 1px rgb(225, 225, 225);
    border-radius: 5px;
    margin: 30px 0;
}
`
const SubContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
width: 100%;
@media screen and (max-width: 1000px){
width:70vw;
}
`
const DetailsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .delete-user{
        background: transparent;
        cursor:pointer;
        &:hover{
            font-size: 26px;
            transition: all 3ms ease;
        }
    }
    .detail-grid-view{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        max-width: 100%;
        width:auto;
        gap: 40px;
    }
    .detail-list-view{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 100%;
        width:auto;
    }
    .grid-user-item{
    display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
border: 1px solid #333;
box-shadow:#333;
border-radius: 1rem;
gap: 1rem;
padding: 10px 20px;
min-height: 240px;
height: 100px;
width: 300px;
span{
    font-size: 16px;
    padding: 0;
    margin: 0;
}}
.list-user-item{
    display: flex;
justify-content: space-between;
align-items: center;
border: 1px solid #333;
box-shadow:#333;
border-radius: 1rem;
gap: 1rem;
padding: 1rem;
margin:1rem 0;
min-height: 80px;
width: 100%;
flex-wrap: wrap;
span{
    font-size: 16px;
    padding: 0;
    margin: 0;
}
@media screen and (max-width:1000px){
    width: 90%;
    border-radius: 5px;
    justify-content: space-evenly;
}
}
`
const UserDetail = styled.div`
width:70%;
border:none;
outline: none;
padding: 1rem;
border-radius: 5px;
box-shadow: 1px 2px 2px 1px var(--style-grey);
margin: auto;
background: hsl(210,25%,8%);
color: gray;
position: relative;
@media screen and (max-width:1300px){
    width:95%;
padding: 20px;
margin: auto;
}
li{
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.close{
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
}
.form-wrapper{
    width:50%;
    display:flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding:20px;
    margin:auto;
    background:var(--style-bright);
    @media screen and (max-width:1300px){
        width:100%;
    }
}
.info-wrapper{
    flex-basis: 50%;
    width:100%;
    margin:auto;
    padding:10px;
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.health-form{
    flex-basis: 50%;
    width:100%;
    margin:auto;
    padding:10px;
    display:flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap:20px;
} 
.helth-input{
    height:35px;
    padding:4px;
    width:100%;
    border:none;
    color:var(--style-grey);
}
.health-btn{
color:var(--style-bright);
padding:10px 25px;
    border-radius: 4px;
    border: none;
    background-color: var(--style-blue-dark);
    cursor: pointer;
    font-weight: 600;
    &:hover{
    color: var(--style-blue-cyan);
    transition: var(--transition);
    font-weight: 500;
    }
}
`
export default UsersComponent;