import React, {useState} from 'react';
import styled from 'styled-components';
import { url } from '../../../../Redux/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaSpinner } from 'react-icons/fa';

const BankComponent = () => {
    const[loading, setLoading] = useState(false)
    const[bankName, setBankName] = useState('')
const[bankAccountName, setBankAccountName] = useState('')
const[bankAccountNumber, setBankAccountNumber] = useState()

    const handleEditBank = (e)=>{
        setLoading(true)
         function fetchData(){
             fetch(`${url}/create-bank-account`,{
                method:'put',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                },
                body:JSON.stringify({
                    bankName, bankAccountName, bankAccountNumber
                })
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    setBankName('')
                    setBankAccountName('')
                    setBankAccountNumber()
                    setLoading(false)
                    return toast.success(resp.resp, {position: toast.POSITION.TOP_RIGHT})
                }else if(resp.err){
                    setLoading(false)
                    return toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
                }
            }).catch(err=>{toast.error(err.message, {position: toast.POSITION.TOP_RIGHT});setLoading(false)})
        }
        return fetchData()
    }
    return (
    <Main>
        <ToastContainer />
    <form className='profile-page-form' onSubmit={handleEditBank}>
      <input type='text' placeholder='Edit Bank name' className='input-edit' onChange={(e)=>setBankName(e.target.value)}  />
      <input type='text' placeholder='Edit Acount name' className='input-edit' onChange={(e)=>setBankAccountName(e.target.value)}/>
      <input type='number' placeholder='Edit Acount number' className='input-edit' onChange={(e)=>setBankAccountNumber(e.target.value)}/>
      {
        loading===false?<button type='submit' className = 'save-changes'>Save Changes</button>:<FaSpinner />
      }
    </form>
    </Main>
    );
};
const Main = styled.div`
.input-edit{
    background-color: var(--style-white);
    height: 35px;
}
.input-edit:active{
    background-color: var(--style-bright);
}
.save-changes{
    padding: 10px 20px;
    color:var(--style-bright);
    background-color: var(--style-blue-dark);
    border-radius: 4px;
    border:none;
    cursor: pointer;
}
`
export default BankComponent;