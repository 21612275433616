//import CryptoJS from "crypto-js";
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { url } from "../Api";

// Encrypt

// const encryptData = (name,data)=>{
//     const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data),process.env.REACT_APP_CryptoJS_Secrete_Key).toString()
//     localStorage.setItem(name,JSON.stringify(encrypted))
// }

// Decrypt

// const decryptData = (name)=>{
//     const data = JSON.parse(localStorage.getItem(name))
//     const decrypt = CryptoJS.AES.decrypt(data,process.env.REACT_APP_CryptoJS_Secrete_Key)
//     return decrypt.toString(CryptoJS.enc.Utf8)
// }
const initialState = {
    items:[],
    status:null
}
export const getGrooves = createAsyncThunk(
    'grooves/getGrooves',
    async()=>{
        try {
          const response = await axios.get(`${url}/grooves`)  
        //   encryptData('grooves',response.data.resp)
          return response.data.resp
        } catch (error) {
            return
        }
    }
)
const groovesSlice = createSlice({
    name:'grooves',
    initialState,
    reducers:{},
    extraReducers:{
        [getGrooves.pending]:(state,action)=>{state.status = 'pending'},
        [getGrooves.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getGrooves.rejected]:(state,action)=>{state.status='rejected'}
}
})

export default groovesSlice.reducer;