import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { url } from "../Api";

const initialState = {
    items:[],
    status:[]
}
export const getApps = createAsyncThunk(
    'apps/getApps',
    async()=>{
        try {
          const response = await axios.get(`${url}/app-setting`)  
          return response.data.resp
        } catch (error) {
            return 
        }
    }
)
const appsSlice = createSlice({
    name:'apps',
    initialState,
    reducers:{},
    extraReducers:{
        [getApps.pending]:(state,action)=>{state.status = 'pending'},
        [getApps.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getApps.rejected]:(state,action)=>{state.status='rejected'}
}
})

export default appsSlice.reducer;