import Select from 'react-select'
import { useState, useRef, useEffect, useMemo } from "react";
import {
  Typography,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import countryList from 'react-select-country-list'
import { FaSpinner } from "react-icons/fa";
import { url } from "../../../../Redux/Api";
import styled from "styled-components";


function Confirmation() {
  const [userName, setUserName] = useState("");
  const [userNamed, setUserNamed] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [phone, setPhone] = useState();
  const [usernameTakenText, setUsernameTakenText] = useState("");
  const [usernameAvailableText, setUsernameAvailableText] = useState("");
    const [existingUserNameState, setExistingUserNameState] = useState(false);
  const [userNameData, setUserNameData] = useState('');

  const[agree, setAgree] = useState(false)
    const[disagree, setDisagree] = useState(false)

  const navigate = useNavigate()
   
  const [country, setCountry] = useState({});
  const{email,referrer,token} = useParams()

  const [password, setPassword] = useState("");

  const options = useMemo(() => countryList().getData(), [])
    const [confirmedPassword, setConfirmedPassword] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const eyeRef = useRef(null);
  const [eyeOpen, setEyeOpen] = useState(false);
  const [eyes, setEyes] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [success, setSuccess] = useState('');
  const[isLoading, setIsLoading] = useState(false)
  const[errorMsg, setErrorMsg] = useState('')
    const handleAgree = ()=>{
    setDisagree(false)
    setAgree(true)
}
const handleDisagree = ()=>{
    setDisagree(true)
    setAgree(false)
}
  const eyeToggle = (e) => {
    e.preventDefault();
    if (eyeOpen === true) {
      setPasswordType("password");
      setEyeOpen(false);
    } else {
      setPasswordType("text");
      setEyeOpen(true);
    }
  };
  const codeRef = useRef(null);
useEffect(()=>{
  if(password !==confirmedPassword){
    setPasswordMismatch(true)
  }
},[confirmedPassword,password])
//Cotinue from here
// const usernameSearchHandler = async(e) =>{
//   await fetch('/users',{
//     method:'get'
//   }).then(res=>res.json())
//   .then(data=>{
//     if(data){
//       const condition = data?.map(item=>item.userName).includes(userName)
//       setUserNameData(condition)
//       console.log('Check it now',condition,'usernameTakenText')
//     }
//   }).catch(err=>console.log(err))
// }
const usernameSearchHandler = async(e) =>{
  e.preventDefault()
  setUsernameTakenText('')
  await fetch(`${url}/check-username`,{
    method:'post',
    headers:{
      'Content-Type':'application/json'
    },
    body:JSON.stringify({userName,firstName,lastName})
  }).then(res=>res.json())
  .then(resp=>{
    if(resp.resp){
      setUsernameAvailableText(resp.resp)
    }else if(resp.err){
      setUsernameTakenText(resp.err)
      
    }
  }).catch(err=>console.log(err))
}

useEffect(()=>{
  async function fetchData(){
    await fetch(`${url}/users`,{
      method:'get'
    }).then(res=>res.json())
    .then(data=>{
      if(data){
        const condition = data?.map(item=>item.userName).includes(userName)
        setUserNameData(condition)
        setExistingUserNameState(condition)

      }
    }).catch(err=>console.log(err))
  }
  return fetchData()

},[userName])
// useEffect(()=>{
//   if(userNameData && userName.length>0){
//     setUsernameTakenText(userNameData.msg)
//   }
// },[userNameData,userName])
const changeHandler = value => {
  setCountry(value)
}
  const handleRegistration = (e) => {
    e.preventDefault();
    setIsLoading(true)
       async function fetchData(){
        await fetch(`${url}/register`,{
          method:'post',
          headers:{
            'Content-Type':'application/json'
          },
          body:JSON.stringify({
           userName,
           email,
           firstName,
           lastName,
           middleName,
           country:country===''?'Nigeria':country,
           referrer,
           phone,
           password,
           confirmedPassword
          })
        }).then(res=>res.json())
        .then(resp=>{
          if(resp.resp){
            setSuccess(resp.resp)
           toast.success(resp.resp, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false)
           return navigate(`/login`)
          }else if(resp.err){
           setIsLoading(false)
           toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT});
           return setErrorMsg(resp.err)
          }
        }).catch(err=>{
          if(err){
            setIsLoading(false)
            toast.error(err.message, {position: toast.POSITION.TOP_RIGHT});
           return setErrorMsg(err.message)
          }
        })
       }
       setIsLoading(false)
       return fetchData()
  };
  
  return (
    <Main>
      <ToastContainer />
      {/* <div className='left-circle'></div> */}
      {
            success!==''?<p className='success'>
            {success}
          </p>: errorMsg !==''?<p className='error-msg'>
            {errorMsg}
          </p>:<Typography variant="h6" className={
            userName.length>0&&usernameTakenText!==''?
            'unavailable-title'
           :userName.length>0&&usernameTakenText===''?'available-title':'title'
          }>
              {
                userName.length>0&&usernameTakenText!==''&&usernameAvailableText===''?
                usernameTakenText
                 :userName.length>0&&usernameTakenText===''&&usernameAvailableText!==''?usernameAvailableText:'Register'
              }
            </Typography>
          }
        
        <form
          autoComplete="off"
          className='form'
          onSubmit={handleRegistration}
        >
          <p style ={{color:'var(--style-blue-cyan)', textAlign:'center',padding:'20x 10px'}}>Use the same name as used on your bank account</p>
          <div className='item'>
          <div className='inputs-wrapper'>
          <input className={
                userName.length>0&&usernameTakenText!==''?
                  'unavailable-name'
                 :userName.length>0&&usernameTakenText===''?'available-name':'login-input'
              
            }
              placeholder={
                userName.length>0&&usernameTakenText!==''&&usernameAvailableText===''?
                usernameTakenText
                 :userName.length>0&&usernameTakenText===''&&usernameAvailableText!==''?usernameAvailableText:'Enter unique user name'
                }
              type='text'
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              onMouseLeave={usernameSearchHandler}
              required
            />
            
            <input className='login-input'
              placeholder="First Name"
              required
              type='text'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
           <div className='inputs-wrapper'>
           <input className='login-input'
              placeholder="Last Name"
              required
              type='text'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <input className='login-input'
              placeholder="Middle Name"
              required={false}
              type='text'
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
            
           </div>
           
           <div className='inputs-wrapper'>
           <input className='login-input'
              placeholder="Email"
              type='email'
              value={email}
              disabled
            />
            <input className='login-input'
              placeholder="Phone"
              required
              // value={phone}
              type="tel" name={phone}
              // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              onChange={(e) => setPhone(e.target.value)}
            />
            </div>
           {/* <div style={{display:'flex',alignItems:'center',gap:'30px'}}> */}
            <span style={{color:'white'}}>Country</span>
            <Select options={options} value={country} className='' onChange={changeHandler}/>
            {/* </div> */}
            <div className='inputs-wrapper'>
            
            {
              referrer!=='self'&&<input disabled className='phone-input'
              value={`${referrer!=='self'&&'Referee: '+referrer}`}
            />
            }
            </div>
           <div className='inputs-wrapper'>
           <input className='login-input'
              onChange={(e) => setPassword(e.target.value)}
              onMouseDown={()=>setEyes(true)}
              type={passwordType}
              placeholder="Password"
              required
              
            />
           
           {
            eyes&&<>
            {!eyeOpen ? (
               <Visibility className='eye' onClick={eyeToggle} />
             ) : (
               <VisibilityOff className='eye' onClick={eyeToggle} />
             )}
            </>
           }
              <input className='login-input'
              onChange={(e) => setConfirmedPassword(e.target.value)}
              type={passwordType}
              required
              placeholder={password !==confirmedPassword? "Passwords Don't Match":`${confirmedPassword===''?'Confirm Password':''}`}
              
            />
           </div>
            </div>
            <a  href ='https://terms.cashorbits.com' style={{color:"var(--style-blue-cyan)"}} target='_blank' rel='noreferrer' >Terms and conditions</a>
            <div className='btn-group'>
               {
                    agree===false&&<button onClick={handleAgree} className='agree-btn'>Agree</button>
                }
                {
                    disagree===false&&<button onClick={handleDisagree} className='disagree-btn'>Disagree</button>
                }
              
                {
                    isLoading===true?<FaSpinner />:
                    <>{
                        agree===true?<button type='submit' className='submit-btn'>Create</button>:null
                    }
                    </>
                }
                 </div>
        </form>
        {/* <div className='right-circle'></div> */}
    </Main>
  );
}
const Main = styled.div`
    width: 80%;
    height: auto;
    margin: 20px auto;
    padding:3rem;
    margin-bottom:10rem;
    position:relative;
    background-color: var(--style-white);
    @media screen and (max-width:650px){
      padding:1rem;
      width:100%;
    }
    .left-circle{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30%;
    width: 30%;
    background-color: var(--style-blue-dark);
    border-top-right-radius: 10%;
    border-top-left-radius: 5%;
    @media screen and (max-width:650px){
      /* height:100px;
      width:100px;
      border-top-right-radius: 100px;
      border-top-left-radius: 5px; */
    }
    }
    .right-circle{
    position: absolute;
    top: 0;
    right: 0;
    height: 30%;
    width: 30%;
    background-color: var(--style-blue-dark);
    border-bottom-left-radius: 200px;
    border-top-right-radius: 10px;
    @media screen and (max-width:650px){
      height:100px;
      width:100px;
      border-bottom-left-radius: 100px;
      border-top-right-radius: 5px;
    }
  }
  .form {
    margin: auto;
    padding: 10px;
    width: 70%;
    display:flex;
    justify-content:space-between;
    flex-direction:column;
    align-items:center;
    gap:20px;
    background: var(--style-blue-dark);
    border:thin solid var(--style-blue-dark);
    @media screen and (max-width:650px){
      width:100%;
    }
  }
  .item{
    display:flex;
    justify-content:space-between;
    flex-direction:column;
    flex-wrap: wrap;
    gap:10px;
    padding:10px;
    width:100%;
  }
  .inputs-wrapper{
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    padding:10px 0px;
    gap:30px;
    @media screen and (max-width:650px){
      flex-direction: column;
    }
  }
  .country-inputs-wrapper{
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    padding:10px 0px;
    gap:30px;
    @media screen and (max-width:1350px){
      flex-direction: column;
    }
  }
  .login-input{
    width: 100%;
    flex-basis:50%;
    height: 35px;
    padding: 10px;
    font-size: 14px;
    background:var(--style-white);
    border: thin solid var(--style-light-grey);
    &:focus{
      padding: 10px;
      outline: none;
      background-color: var(--style-white);
    }
    }
    .unavailable-title{
      color:var(--style-error);
     font-size: 16px;
     font-weight: 300;
     padding:10px;
     text-align:center;
    }
    .available-title{
      color:var(--style-success);
     font-size: 16px;
     font-weight:300;
     padding:10px;
     text-align:center;
    }
    .unavailable-name{
     color:var(--style-error);
     font-size: 14px;
     padding:10px;
     color:var(--style-error);
     width: 100%;
    flex-basis:50%;
    height: 35px;
     text-align:center;
    }
    .available-name{
     color:var(--style-success);
     font-size: 14px;
     padding:10px;
     color:var(--style-error);
     width: 100%;
    flex-basis:50%;
    height: 35px;
     text-align:center;
    }
  .country-input{
    flex-basis:30%;
    width:100%;
    height: 35px !important;
    padding: 10px;
    font-size: 14px;
    padding: 4px;
    border: 1px solid var(--style-grey);
    &:focus{
      padding: 10px;
      outline: none;
      background-color: var(--style-white);
    }
    &:active{
      color: var(--style-white);
      padding: 10px;
      outline: none;
      background-color: var(--style-white);
    }
  }
  .phone-input{
    flex-basis:28%;
    height: 35px;
    width:100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid var(--style-grey);
    &:focus{
      padding: 10px;
      outline: none;
      background-color: var(--style-white);
    }
    &:active{
      color: var(--style-white);
      padding: 10px;
      outline: none;
      background-color: var(--style-white);
    }
  }
  .eye{
    color:#555;
    cursor:pointer;
  }
  .eye-slash{
    color:#555;
  }
  .button-group{
    align-content:center;
    width:100%;
    margin-top:15px;
  }
  .register-spinner{
    color:var(--style-blue-dark);
  }
  .create-button{
    width:30%;
    color: var(--style-blue-cyan);
    background-color: var(--style-blue-dark);
    &:hover{
      background-color: var(--style-blue-cyan);
      color:#333;
      font-weight:bolder;
    }
  }
.button{
    width:50%;
    color:var(--style-blue-cyan);
    background-color:var(--style-white);
    border:1px solid var(--style-blue-cyan);
    border-radius: 3;
    text-align:center;
    text-decoration:none;
    padding: 10px;
  }
  .forgot-link{
    text-decoration: none;
  }
  .link{
    color: rgb(252, 193, 0);
    text-align: center;
    text-decoration: none;
  }
  .title {
    text-align: center;
  }
  .error-msg{
    color:red;
    text-align:center;
  }
  .success{
    color:green;
    text-align:center;
  }
  .btn-group{
    display:flex;
    justify-content:space-around;
    align-items:center;
    flex-wrap:wrap;
    width:70%;
    margin:auto;
}
.agree-btn{
    padding: 5px 30px;
    color: var(--style-blue-dark);
    background-color: var(--style-white);
    border:thin solid var(--style-blue-dark);
    border-radius: 4px;
    outline: none;
    border:none;
    margin: 10px;
    cursor: pointer;
    font-size:12px;
    transform:scale(1);
    &:hover{
      transition: var(--transition);
      transform:scale(1.1);
    }
}
.disagree-btn{
  padding: 5px 30px;
    color: var(--style-error);
    background-color: var(--style-white);
    border-radius: 4px;
    margin: 10px;
    outline: none;
    border:none;
    cursor: pointer;
    font-size:12px;
    transform:scale(1);
    &:hover{
      transition: var(--transition);
      transform:scale(1.1);
    }
}
.submit-btn{
    padding: 5px 30px;
    color: var(--style-blue-dark);
    background-color: var(--style-bright);
    border-radius: 4px;
    border:thin solid var(--style-blue-dark);
    margin: 10px;
    cursor: pointer;
    font-size:12px;
    transform:scale(1);
    &:hover{
      transition: var(--transition);
      transform:scale(1.1);
    }
}
.submit-btn:hover{
    transition: all 0.3s ease;
}
`
export default Confirmation