import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "./Api";

export const paymentsApi = createApi({
  reducerPath: "paymentsApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${url}`}),
  endpoints: (builder) => ({
    getAllpayments: builder.query({
      query: () => `paymentsApi`,
    }),
  }),
});

export const { useGetAllpaymensQuery } = paymentsApi;
