import React from "react";
import Paper from '@material-ui/core/Paper';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  LineSeries,
  BarSeries,
  PieSeries,
  Title
} from '@devexpress/dx-react-chart-material-ui';
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
  
const styles = makeStyles((theme)=>({
  root: {
    backgroundColor:'white',
    minWidth:'100%'
  }
}))
  
export const LineChart = () => {
  const style = styles()
  const users = useSelector(state=>state.users.items)
// Sample data
const data = [
  { x: 1, y: 30 },
  { x: 2, y: 40 },
  { x: 3, y: 5 },
  { x: 4, y: 2 },
  { x: 5, y: 21 },
];
// const data = users.forEach((item,index)=>{
//   return {x:index,y:index}
// });

return (
    <Paper className={style.root}>
    <Chart
      data={data}
      >
      <ArgumentAxis />
      <ValueAxis />
  
      <LineSeries valueField="y" argumentField="x"
      // style={{background: '#16191e', color:'#fff',innerWidth:'50px'}}
      />
    </Chart>
  </Paper>
);
}

const makeStyle = makeStyles((theme)=>({
  root: {
    backgroundColor:'white',
    minWidth:'500px'
  }
}))
export const UsersBarChart = () => {
  const style = makeStyle()
  const users = useSelector(state=>state.users.items)
    // Sample data
    let data = [
      { argument: 'Jan', value:0},
      { argument: 'Feb', value:0},
      { argument: 'Mar', value:0},
      { argument: 'Apr', value:0},
      { argument: 'May', value:0},
      { argument: 'Jun', value:0},
      { argument: 'Jul', value:0},
      { argument: 'Aug', value:0},
      { argument: 'Sep', value: 0},
      { argument: 'Oct', value: 0},
      { argument: 'Nov', value: 0},
      { argument: 'Dec', value:0},
    ];
data?.forEach(element => {
  users?.forEach(user=>{
    if(user.date.slice(0,4)=== window.Date().slice(11,15)){
      if(user.date.slice(5,7)==='01'){
        if(element.argument==='Jan'){
          element.value += 1
        }
      }else if(user.date.slice(5,7)==='02'){
        if(element.argument==='Feb'){
          element.value += 1
        }
      }else if(user.date.slice(5,7)==='03'){
        if(element.argument==='Mar'){
          element.value += 1
        }
      }else if(user.date.slice(5,7)==='04'){
        if(element.argument==='Apr'){
          element.value += 1
        }
      }else if(user.date.slice(5,7)==='05'){
        if(element.argument==='May'){
          element.value += 1
        }
      }else if(user.date.slice(5,7)==='06'){
        if(element.argument==='Jun'){
          element.value += 1
        }
      }else if(user.date.slice(5,7)==='07'){
        if(element.argument==='Jul'){
          element.value += 1
        }
      }else if(user.date.slice(5,7)==='08'){
        if(element.argument==='Aug'){
          element.value += 1
        }
      }else if(user.date.slice(5,7)==='09'){
        if(element.argument==='Sep'){
          element.value += 1
        }
      }else if(user.date.slice(5,7)==='10'){
        if(element.argument==='Oct'){
          element.value += 1
        }
      }else if(user.date.slice(5,7)==='11'){
        if(element.argument==='Nov'){
          element.value += 1
        }
      }else if(user.date.slice(5,7)==='12'){
        if(element.argument==='12'){
          element.value += 1
        }
      }
    }
  })
});

    return (
      <Paper className={style.root}>
        <Chart
          data={data}
          >

          <ArgumentAxis />
          <ValueAxis />
          <BarSeries valueField="value" argumentField="argument" />
        </Chart>
      </Paper>

      
    );
    }

    export const GroovesBarChart = () => {
      const style = styles()
      const grooves = useSelector(state=>state.grooves.items)
      
      // Sample data
      let data = [
        { argument: 'Jan', value:0},
        { argument: 'Feb', value:0},
        { argument: 'Mar', value:0},
        { argument: 'Apr', value:0},
        { argument: 'May', value:0},
        { argument: 'Jun', value:0},
        { argument: 'Jul', value:0},
        { argument: 'Aug', value:0},
        { argument: 'Sep', value: 0},
        { argument: 'Oct', value: 0},
        { argument: 'Nov', value: 0},
        { argument: 'Dec', value:0},
      ];
  data.forEach(element => {
    grooves?.forEach(groove=>{
      if(groove.date?.slice(0,4)=== window.Date().slice(11,15)){
        if(groove.date?.slice(5,7)==='01'){
          if(element.argument==='Jan'){
            element.value += 1
          }
        }else if(groove.date?.slice(5,7)==='02'){
          if(element.argument==='Feb'){
            element.value += 1
          }
        }else if(groove.date?.slice(5,7)==='03'){
          if(element.argument==='Mar'){
            element.value += 1
          }
        }else if(groove.date?.slice(5,7)==='04'){
          if(element.argument==='Apr'){
            element.value += 1
          }
        }else if(groove.date?.slice(5,7)==='05'){
          if(element.argument==='May'){
            element.value += 1
          }
        }else if(groove.date?.slice(5,7)==='06'){
          if(element.argument==='Jun'){
            element.value += 1
          }
        }else if(groove.date?.slice(5,7)==='07'){
          if(element.argument==='Jul'){
            element.value += 1
          }
        }else if(groove.date?.slice(5,7)==='08'){
          if(element.argument==='Aug'){
            element.value += 1
          }
        }else if(groove.date?.slice(5,7)==='09'){
          if(element.argument==='Sep'){
            element.value += 1
          }
        }else if(groove.date?.slice(5,7)==='10'){
          if(element.argument==='Oct'){
            element.value += 1
          }
        }else if(groove.date?.slice(5,7)==='11'){
          if(element.argument==='Nov'){
            element.value += 1
          }
        }else if(groove.date?.slice(5,7)==='12'){
          if(element.argument==='12'){
            element.value += 1
          }
        }
      }
    })
  });
        return (
          <Paper className={style.root}>
          <Chart
            data={data}
            >
            <ArgumentAxis />
            <ValueAxis />
            <BarSeries valueField="value" argumentField="argument" />
          </Chart>
        </Paper>
        );
        }
        
        export const RefereesBarChart = () => {
          const style = makeStyle()
          const referees = useSelector(state=>state.referrers.items)[0]
            // Sample data
            let data = [
              { argument: 'Jan', value:0},
              { argument: 'Feb', value:0},
              { argument: 'Mar', value:0},
              { argument: 'Apr', value:0},
              { argument: 'May', value:0},
              { argument: 'Jun', value:0},
              { argument: 'Jul', value:0},
              { argument: 'Aug', value:0},
              { argument: 'Sep', value: 0},
              { argument: 'Oct', value: 0},
              { argument: 'Nov', value: 0},
              { argument: 'Dec', value:0},
            ];
        data.forEach(element => {
          referees?.forEach(referee=>{
            if(referee?.date.slice(0,4)=== window.Date().slice(11,15)){
              if(referee.date.slice(5,7)==='01'){
                if(element.argument==='Jan'){
                  element.value += 1
                }
              }else if(referee.date.slice(5,7)==='02'){
                if(element.argument==='Feb'){
                  element.value += 1
                }
              }else if(referee.date.slice(5,7)==='03'){
                if(element.argument==='Mar'){
                  element.value += 1
                }
              }else if(referee.date.slice(5,7)==='04'){
                if(element.argument==='Apr'){
                  element.value += 1
                }
              }else if(referee.date.slice(5,7)==='05'){
                if(element.argument==='May'){
                  element.value += 1
                }
              }else if(referee.date.slice(5,7)==='06'){
                if(element.argument==='Jun'){
                  element.value += 1
                }
              }else if(referee.date.slice(5,7)==='07'){
                if(element.argument==='Jul'){
                  element.value += 1
                }
              }else if(referee.date.slice(5,7)==='08'){
                if(element.argument==='Aug'){
                  element.value += 1
                }
              }else if(referee.date.slice(5,7)==='09'){
                if(element.argument==='Sep'){
                  element.value += 1
                }
              }else if(referee.date.slice(5,7)==='10'){
                if(element.argument==='Oct'){
                  element.value += 1
                }
              }else if(referee.date.slice(5,7)==='11'){
                if(element.argument==='Nov'){
                  element.value += 1
                }
              }else if(referee.date.slice(5,7)==='12'){
                if(element.argument==='12'){
                  element.value += 1
                }
              }
            }
          })
        });
        
            return (
              <Paper className={style.root}>
                <Chart
                  data={data}
                  >
        
                  <ArgumentAxis />
                  <ValueAxis />
                  <BarSeries valueField="value" argumentField="argument" />
                </Chart>
              </Paper>
        
              
            );
            }
        export const ReferrersBarChart = (props) => {
          const style = makeStyle()
          const referrers = props.data;
          console.log('referrers chart',referrers)
            // Sample data
            let data = [
              { argument: 'Jan', value:0},
              { argument: 'Feb', value:0},
              { argument: 'Mar', value:0},
              { argument: 'Apr', value:0},
              { argument: 'May', value:0},
              { argument: 'Jun', value:0},
              { argument: 'Jul', value:0},
              { argument: 'Aug', value:0},
              { argument: 'Sep', value: 0},
              { argument: 'Oct', value: 0},
              { argument: 'Nov', value: 0},
              { argument: 'Dec', value:0},
            ];
        data.forEach(element => {
          referrers.forEach(referrer=>{
            if(referrer.date.slice(0,4)=== window.Date().slice(11,15)){
              if(referrer.date.slice(5,7)==='01'){
                if(element.argument==='Jan'){
                  element.value += 1
                }
              }else if(referrer.date.slice(5,7)==='02'){
                if(element.argument==='Feb'){
                  element.value += 1
                }
              }else if(referrer.date.slice(5,7)==='03'){
                if(element.argument==='Mar'){
                  element.value += 1
                }
              }else if(referrer.date.slice(5,7)==='04'){
                if(element.argument==='Apr'){
                  element.value += 1
                }
              }else if(referrer.date.slice(5,7)==='05'){
                if(element.argument==='May'){
                  element.value += 1
                }
              }else if(referrer.date.slice(5,7)==='06'){
                if(element.argument==='Jun'){
                  element.value += 1
                }
              }else if(referrer.date.slice(5,7)==='07'){
                if(element.argument==='Jul'){
                  element.value += 1
                }
              }else if(referrer.date.slice(5,7)==='08'){
                if(element.argument==='Aug'){
                  element.value += 1
                }
              }else if(referrer.date.slice(5,7)==='09'){
                if(element.argument==='Sep'){
                  element.value += 1
                }
              }else if(referrer.date.slice(5,7)==='10'){
                if(element.argument==='Oct'){
                  element.value += 1
                }
              }else if(referrer.date.slice(5,7)==='11'){
                if(element.argument==='Nov'){
                  element.value += 1
                }
              }else if(referrer.date.slice(5,7)==='12'){
                if(element.argument==='12'){
                  element.value += 1
                }
              }
            }
          })
        });
        
            return (
              <Paper className={style.root}>
                <Chart
                  data={data}
                  >
        
                  <ArgumentAxis />
                  <ValueAxis />
                  <BarSeries valueField="value" argumentField="argument" />
                </Chart>
              </Paper>
        
              
            );
            }