import React, { useEffect, useState } from 'react';
import './StackPay.css';
import { useNavigate, useParams } from "react-router-dom"
import { url } from '../../Redux/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Spinner } from '../../Loading';

const ManualPayment = () => {
const{amount,phone,name,nextWithdrawal,type,duration,period,description,requiredMembers,grooveId} = useParams();
const user = useSelector(state=>state.auth.user)
const users = useSelector(state=>state.users.items)
const app = useSelector(state=>state.apps.items)

const lastOrbit = user.orbits[user.orbits.length-1]
const navigate = useNavigate()

  const[cashier, setCashier] = useState({})

const cashiers = users.filter(filt=>filt.isCashier&&filt.email!==user?.email&&filt?.bank?.bankName)

useEffect(()=>{
        if(cashiers.length>0){
        const choose = cashiers[(Math.floor(Math.random()*cashiers.length))]
        return setCashier(choose)
        }
},[cashiers])
const [loading, setLoading] = useState(false)

const date = new Date().getTime()
const lastFourDate = date.toString().slice(7)
const lastThreeUser =  user?._id?.toString().slice(21)
const lastFourShop = cashier?._id?.toString().slice(21)
const vals = lastFourShop+lastThreeUser+lastFourDate

const handlePayment = (e)=>{
  setLoading(true)
  e.preventDefault()
  sessionStorage.setItem('current-payment',JSON.stringify({
                      amount:amount,
                      receiver:cashier.email,
                      phone:cashier.phone,
                      bankName:cashier.bank.bankName,
                      accountName:cashier.bank.bankAccountName,
                      accountNumber:cashier.bank.bankAccountNumber,
                      time:new Date()
  }))
  async function fetchData(){
    await fetch(`${url}/make-manual-payment`,{
      method:'post',
      headers:{
        'Content-Type':'application/json',
        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
      },body:JSON.stringify({
        amount,phone,name,nextWithdrawal,cashier:cashier.email,id:vals,payee:user.email,type,duration,period,description,requiredMembers,grooveId
      })
    }).then(res=>res.json())
    .then(resp=>{
      if(resp.resp){
        setLoading(false)
        toast.success(resp.resp, {position: toast.POSITION.TOP_RIGHT})
        return navigate(-1)
      }else if(resp.err){
        setLoading(false)
        return toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
      }else{
        setLoading(false)
        return toast.error('Something went wrong. Try again.', {position: toast.POSITION.TOP_RIGHT})
      }
    }).catch(err=>{toast.error(err.message, {position: toast.POSITION.TOP_RIGHT})})
  }
  fetchData()
}
 
return (
  <Main>
    <ToastContainer />
    <div className='container'>
      <p classNmae='pay-title'>{type==='groove'?'Groove top-up':type==='personal'?'Personal Top-up':type==='groove-upgrade'?'Groove upgrade payment':''}</p>
      <div className='pay-wrapper'><span className='pay-span'>
  {type==='groove'||type==='groove-upgrade'?'Groove Id':type==='personal'?`Orbit: ${lastOrbit.orbit} Level: ${lastOrbit.level}`:''}
        </span><p className='pay-detail'>{type==='groove'?grooveId:type==='personal'?'Personal Top-up':type==='groove-upgrade'?'Groove upgrade payment':''}</p></div>
        <div className='horizontal-line'></div>
      <div className='pay-wrapper'><span className='pay-span'>Amount</span><p className='pay-detail'>{app?.currency}{amount}</p></div>
      <div className='pay-wrapper'><span className='pay-span'>Bank Name</span><p className='pay-detail'>{cashier?.bank?.bankName}</p></div>
      <div className='pay-wrapper'><span className='pay-span'>Account Name</span><p className='pay-detail'>{cashier?.bank?.bankAccountName}</p></div>
      <div className='pay-wrapper'><span className='pay-span'>Account Number</span><p className='pay-detail'>{cashier?.bank?.bankAccountNumber}</p></div>
      <div className='pay-wrapper'><span className='pay-span'>Recipient's phone</span><p className='pay-detail'>{cashier?.phone}</p></div>
        {
          loading?<Spinner />:<button onClick={handlePayment} className='pay-btn'>I've paid {app?.currency}{amount} to the above details.</button>
        }
    </div>
    </Main>
  );
}
const Main = styled.div`
width: 90%;
padding: 20px 50px;
margin: auto;
background: var(--style-bright);
display:flex;
justify-content:space-between;
flex-direction:column;
box-shadow: none;
align-items:center;
@media screen and (max-width:1300px){
    padding:20px;
    width: 100%;
  }
.container{
  padding:20px;
  width: 50%;
  margin:auto;
  display:flex;
justify-content:space-between;
flex-direction:column;
align-items:center;
  background:var(--style-white);
  box-shadow: none;
  @media screen and (max-width:1300px){
    padding:20px;
    width: 100%;
  }
}
.horizontal-line{
  width:100%;
  height:1px;
  background-color: var(--style-grey);
}
.pay-btn{
margin-bottom: 20px;
  padding:10px 15px;
  background:var(--style-bright);
  color:var(--style-blue-dark);
  outline:none;
  border:thin solid var(--style-blue-dark);
  border-radius: 4px;
  transform:scale(1);
  cursor:pointer;
  &:hover{
    transform: scale(1.1);
    transition:var(--transition);
    /* color: var(--style-white); */
  }
}
.pay-title{
  font-size:14px;
  padding:10px;
  text-align:center;
  color: var(--style-grey);
}
.pay-wrapper{
  display:flex;
justify-content:space-between;
align-items:center;
width:100%;
}
.pay-span{
  font-size:14px;
  padding:10px;
  text-align:center;
  font-weight:500;
  color: var(--style-grey);
}
.pay-detail{
  font-size:14px;
  padding:10px;
  text-align:center;
  font-weight:500;
  color: var(--style-grey);
}
`

export default ManualPayment;