import { Button } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { url } from '../../../Redux/Api'
import { logOut } from '../../../Redux/Slices/AuthSlice'
import logo from '../../assets/logo.png'
import './Nav.css'
import moment from 'moment'

export default function Nav() {
  const {pathname} = useLocation()
    const [toggleMenu, setToggleMenu] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const[open, setOpen] = useState(true)
    const user = useSelector(state=>state.auth.user)
    const app = useSelector(state=>state.apps.items)
    const navigate = useNavigate()
    const noNavbar = [`/${user?._id}/dashboard`]

    const toggleNav = () => {
        setToggleMenu(!toggleMenu)
        setOpen(!open)
      }

      useEffect(() => {
        const changeWidth = () => {
          setScreenWidth(window.innerWidth);
        }
        window.addEventListener('resize', changeWidth)
        return () => {
            window.removeEventListener('resize', changeWidth)
        }
      }, [])
            const dispatch = useDispatch()
          const handleLogout = async ()=>{
          async function fetchData(){
            await  fetch(`${url}/last-visited`,{
              method:'put',
              headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
              }
            }).then(res=>res.json())
            .then(resp=>{
              if(resp.response){
                dispatch(logOut())
                return navigate('/login')
              }else if(resp.err){
                alert(resp.err)
              }
            })
          }
          fetchData()
          }

          if(noNavbar.some(item=>item===pathname))
           return null 
           return (
            <nav>
              <img src={logo} alt='' className='app-logo' onClick={()=>navigate('/')}/>
            {(toggleMenu || screenWidth > 500) && (
            <ul className="list">
              <Link to='/' className="items">Home</Link>
            {user?<Link to={`/${user._id}/profile`} className="items">Profile</Link>:null}
            {user?<Link to={`/${user._id}/dashboard`} className="items">Dashboard</Link>:null}
            <Link to='/learn' className="items">Learn</Link>
            <Link to='/support' className="items">Support</Link>
            <Link to='/grooves' className="items">Grooves</Link>
            {user?<Button onClick={handleLogout} className="logout">Logout</Button>:
            [
              <Link to='/login' className="items">Login</Link>,
            <Link to='/register' className="items">Register</Link>
            ]}
          </ul>
            )}
            <button onClick={toggleNav} className="btn">
                {
                    open?<FaBars className='toggle-menu' />:<FaTimes className='toggle-menu' />
                }
            </button>
          </nav>
        )
          
    
  }