import { ContactSupport, DashboardOutlined, People, Settings } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { FaBars,FaDollarSign,FaEnvelope, FaHeadset, FaHistory, FaHome, FaNewspaper, FaSignOutAlt, FaTimes, FaUser } from 'react-icons/fa';
import UsersComponent from './UsersComponent';
import './Dashboard.css'
import AppSetting from './AppSetting';
import Activity from './Activity';
import { useDispatch, useSelector } from 'react-redux';
import Reports from './Reports';
import { logOut } from '../../../Redux/Slices/AuthSlice';
import NewsLetter from './NewsLetter';
import NewSupport from '../Support/NewSupport';
import { useNavigate } from 'react-router-dom';
import UsersForCashier from './UserForCashier';
import TransactionHistory from './TransactionHistory';

const Dashboard = () => {
    const navigate = useNavigate()
const[sentMessages, setSentMessages] = useState([])
const[receivedMessages, setReceivedMessages] = useState([])
const[readMessages, setReadMessages] = useState([])
const[unreadMessages, setUnreadMessages] = useState([])
const[repliedMessages, setRepliedMessages] = useState([])
const[unrepliedMessages, setUnrepliedMessages] = useState([])

const transactionHistoryRender = <TransactionHistory />
const usersRender = <UsersComponent />
const userForCashierRender = <UsersForCashier />
const settingsRender = <AppSetting />
const activityRender = <Activity />
const newsLetter = <NewsLetter />
const support = <NewSupport />

const app = useSelector(state=>state.apps.items)
const user = useSelector(state=>state.auth.user)
const dispatch = useDispatch()

const unreadReports = <Reports 
sentMessages={sentMessages} 
receivedMessages={receivedMessages}
repliedMessages={repliedMessages}
unrepliedMessages={unrepliedMessages} 
readMessages={readMessages}
unreadMessages={unreadMessages}
/>

const [render, setRender] = useState(activityRender)
const[menu, setMenu] = useState('left-widget-min-ul')
const[toggle, setToggle] = useState(false)

useEffect(()=>{
        if(toggle===false){
            setMenu('left-widget-min-ul')
        }else{
            setMenu('left-widget-max-ul')
        }
},[toggle])
    
 const refs = useRef()

 useEffect(()=>{
     refs.current = render
 },[render])
 const manualPayments = user?.manualPayments?.filter(filt=>filt.confirmed===false)
 const logoutHandler = ()=>{
dispatch(logOut())
return navigate('/login')
}
 return (
        <div id='app-main'>
            {
                toggle&&<div id='app-left-widget'>
                <div id='app-left-navigation'>
                        <ul id={menu} className='app-left-widget-min-ul'>
                        <li onClick={()=>setRender(activityRender)}><div id='app-list-left'><DashboardOutlined id='app-icon' /></div></li>
                        {
                            user.isAdmin&&<li onClick={()=>setRender(usersRender)}><div id='app-list-left'><People id='app-icon' /></div></li>
                        }
                            <li onClick={()=>setRender(transactionHistoryRender)}><div id='app-list-left'><FaHistory id='app-icon' /></div></li>
                        {
                            user.isAdmin||user?.isCashier?
                            <>
                            {
                                manualPayments.length>0&&<li onClick={()=>setRender(userForCashierRender)}><div id='app-list-left'><FaDollarSign id='app-icon' /></div></li>
                            }
                            </>:null
                        }
                        {
                            user.isAdmin&&<li onClick={()=>setRender(newsLetter)}><div id='app-list-left'><FaHeadset id='app-icon' /></div></li>
                        }
    
                        {
                            user.isAdmin&&<li onClick={()=>setRender(support)}><div id='app-list-left'><ContactSupport id='app-icon' /></div></li>
                        }
    
                        {
                            user.isAdmin&&<li onClick={()=>setRender(settingsRender)}><div id='app-list-left'><Settings id='app-icon' /></div></li>
                        }
                        <li onClick={()=>navigate('/')}><div id='app-list-left'><FaHome id='app-icon' /></div></li>
                        <li onClick={()=>navigate(`/${user._id}/profile`)}><div id='app-list-left'><FaUser id='app-icon' /></div></li>
                        {
                            // user?.orbits[0].level<1?<li onClick={()=>navigate(`/${user._id}/chat`)}><div id='app-list-left'><FaHeadset id='app-icon' /></div></li>:null
                        }
                        <li onClick={logoutHandler}><div id='app-list-left'><FaSignOutAlt id='app-logout-icon' /></div></li>
                        </ul>
                </div>
                </div>
            }
            <div id='app-right-widget'>
                <div id='app-right-top-bar'>
                    <div id='app-left'>
                        {toggle===false?<FaBars id='app-hamburger-icon' onClick={()=>setToggle(true)} />:<FaTimes id='app-hamburger-icon' onClick={()=>setToggle(false)}/>}
                    </div>
                    {
                        app?.reports?.length>0?
                        <div id='app-right'> 
                    <div id='app-wrapper'><FaEnvelope id={unreadMessages?.length>0?'app-unread':'app-read'} />
                    <div>
                        <p>{unreadMessages?.length>99?'99+':unreadMessages?.length>0&&unreadMessages?.length}</p>
                        </div></div>
                     </div> :
                     <div id='app-right'> 
                     <div id='app-wrapper'><FaEnvelope id={unreadMessages?.length>0?'app-unread':'app-read'} 
                     onClick={()=>setRender(unreadReports)}/>
                     <div>
                         <p>{unreadMessages?.length>99?'99+':unreadMessages?.length>0&&unreadMessages?.length}</p>
                         </div></div>
                      </div> 
                    }
                </div>
                <div id='app-right-body'>
                   {render}
                </div>
            </div>
        </div>
    );
};
export default Dashboard;