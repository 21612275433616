import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TramRounded } from '@material-ui/icons';
import React, { useEffect, useState, useMemo } from 'react';
import Select from 'react-select'
import { FaSpinner } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { url } from '../../../../Redux/Api';
import countryList from 'react-select-country-list'

const PersonalComponent = () => {
    const[country, setCountry] = useState(null)
    const[oldPassword, setOldPassword] = useState(null)
    const[newPassword, setNewPassword] = useState(null)
    const[passwords, setPasswords] = useState('')
    const[passwordUsedBefore, setPasswordUsedBefore] = useState('')
    const[loading, setLoading] = useState(false)
    const[success, setSuccess] = useState('')
    const[error, setError] = useState('')
    const options = useMemo(() => countryList().getData(), [])
    const user = useSelector(state=>state.auth.user)

    const handleEditUser = (e)=>{
        e.preventDefault()
        setLoading(true)
        async function fetchData(){
            await fetch(`${url}/update-user-detail`,{
                method:'put',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                },
                body:JSON.stringify({
                    password:newPassword,
                    country:country
                })
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    toast.success(resp.resp, {position: toast.POSITION.TOP_RIGHT})
                    setPasswords('')
                    setCountry('')
                    setSuccess(resp.resp)
                }else if(resp.err){
                    toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
                    setError(resp.err)
                }
            }).catch(err=>{toast.error(err.message, {position: toast.POSITION.TOP_RIGHT});setError(err.message)})
        }
        fetchData()
    }
const handlePasswordCompare = ()=>{
    if(user?.usedPasswords[user?.usedPasswords.length-1]===oldPassword){
        setPasswords('equal')
    }else{
        setPasswords('unequal')
    }
}

const handleCompareNewPassword = ()=>{
    if(user?.usedPasswords.includes(newPassword)){
        setPasswordUsedBefore('yes')
    }else{
        setPasswordUsedBefore('no')
    }
}
const handleResetCompareNewPassword = ()=>{
    // setNewPassword(null)
}
const changeHandler = value => {
    setCountry(value)
  }
  
return (
        <Main>
            <ToastContainer />
            <form className='profile-page-form' onSubmit={handleEditUser}>
{
    passwordUsedBefore==='no'&&<>
    {success!==''?<span>{success}</span>:error!==''?<span>{error}</span>:passwords==='unequal'?<span>Passwords don't match</span>:passwords==='equal'?<span>Passwords Match</span>:passwords===''&&<span>Edit Details</span>}
    </>
}

{
     passwordUsedBefore==='yes'&&<span>Password used before. Try another.</span>
}
  {/* <input type='text' placeholder='Edit Country' className='input-edit' onChange={(e)=>setCountry(e.target.value)}/> */}
  <Select options={options} value={country} className='input-edit' onChange={changeHandler}/>
  <input type='password' placeholder='Old Password' className='input-edit' onKeyUp={handlePasswordCompare} onChange={(e)=>setOldPassword(e.target.value)}/>
  <input type='password' placeholder='New Password' className='input-edit' onMouseLeave={handleResetCompareNewPassword} onKeyUp={handleCompareNewPassword} onChange={(e)=>setNewPassword(e.target.value)}/>
  <hr />
  {
    loading?<FaSpinner />:
    passwordUsedBefore!=='yes' && passwords!=='unequal'?
    <button type='submit' className = 'save-changes'>Save Changes</button>:null
  }
</form>
        </Main>
    );
};

const Main = styled.div`
.input-edit{
    background-color: var(--style-white);
    height: 35px;
}
.input-edit:active{
    background-color: var(--style-bright);
}
.save-changes{
    padding: 10px 20px;
    color:var(--style-bright);
    background-color: var(--style-blue-dark);
    border-radius: 4px;
    border:none;
    cursor: pointer;
}
`
export default PersonalComponent;