import React, { useEffect, useState } from 'react';
import './StackPay.css';
import { useNavigate, useParams } from "react-router-dom"
import { url } from '../../Redux/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Spinner } from '../../Loading';

const ManualWithdrawal = () => {
const{amount,type,paymentId} = useParams();
const user = useSelector(state=>state.auth.user)
const app = useSelector(state=>state.apps.items)

const lastOrbit = user.orbits[user.orbits.length-1]
const navigate = useNavigate()
  const[cashier, setCashier] = useState({})
  const[queryPayee, setQueryPayee] = useState({})

  const payments = useSelector(state=>state.payments.items)
  const users = useSelector(state=>state.users.items)

useEffect(()=>{
        if(payments.length>0){
            const payment = payments.filter(filt=>filt._id===paymentId)
            const payer = users.filter(filt=>filt.email===payment[0].payer) 
            const vals = users.filter(filt=>filt.email===payment[0].payee)  
            setQueryPayee(vals[0])
        return setCashier(payer[0])
        }
},[users,payments,paymentId])
const [loading, setLoading] = useState(false)
const[open, setOpen] = useState(false)

const handleWithdrawalConfirmation = (e)=>{
  setLoading(true)
  e.preventDefault()
  async function fetchData(){
    await fetch(`${url}/confirm-personal-manual-withdrawal`,{
      method:'post',
      headers:{
        'Content-Type':'application/json',
        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
      },body:JSON.stringify({
        paymentId
      })
    }).then(res=>res.json())
    .then(resp=>{
      if(resp.resp){
        setLoading(false)
        toast.success(resp.resp, {position: toast.POSITION.TOP_RIGHT})
        return navigate(-1)
      }else if(resp.err){
        setLoading(false)
        return toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
      }else{
        setLoading(false)
        return toast.error('Something went wrong. Try again.', {position: toast.POSITION.TOP_RIGHT})
      }
    }).catch(err=>{toast.error(err.message, {position: toast.POSITION.TOP_RIGHT})})
  }
  fetchData()
}

return (
  <Main>
    <ToastContainer />
    {
      open?
      <div  className='modal'>
        <h1 className='warn-title'>Please note!</h1>
        <p className='warn-body'>Click 'Yes I've received the payment' only if you have received the payment. If you know you have not received this payment, kindly click 'Cancel' as this action cannot be undone.</p>
        <p className='warn-body'>However, if you have received the payment, in order not to tamper with your account's health, it's advisable to approve payments on time. <a href='/learn'>Learn more</a> about payments delayal penalties.</p>
        <div className='btn-wrapper'>
        {loading?<Spinner />:<>
        <button onClick={handleWithdrawalConfirmation} className='yes'>Yes I've received the payment</button>
        <button onClick={()=>setOpen(false)} className='no'>Cancel</button>
        </>}
        </div>
    </div>:
    <div className='container'>
      <p className='pay-title'>{type==='groove'?'Groove top-up':type==='personal'?'Personal Top-up':type==='groove-upgrade'?'Groove upgrade payment':''}</p>
      <div className='pay-wrapper'><span className='pay-span'>
  {type==='groove'||type==='groove-upgrade'?'Groove Id':type==='personal'?`Orbit: ${lastOrbit.orbit} Level: ${lastOrbit.level}`:''}
        </span><p className='pay-detail'>{type==='groove'?paymentId:type==='personal'?'Personal Top-up':type==='groove-upgrade'?'Groove upgrade payment':''}</p></div>
        <h6 style={{textAlign:'center',color:'var(--style-grey)'}}>Receiver's information</h6>
        <div className='pay-wrapper'><span className='pay-span'>Amount</span><p className='pay-detail'>{app?.currency}{amount}</p></div>
      <div className='pay-wrapper'><span className='pay-span'>Bank Name</span><p className='pay-detail'>{queryPayee?.bank?.bankName}</p></div>
      <div className='pay-wrapper'><span className='pay-span'>Account Name</span><p className='pay-detail'>{queryPayee?.bank?.bankAccountName}</p></div>
      <div className='pay-wrapper'><span className='pay-span'>Account Number</span><p className='pay-detail'>{queryPayee?.bank?.bankAccountNumber}</p></div>
        <div className='horizontal-line'></div>
        <h6 style={{textAlign:'center',color:'var(--style-grey)'}}>Payer's information</h6>
        <div className='pay-wrapper'><span className='pay-span'>Name</span><p className='pay-detail'>{`${cashier?.firstName} ${cashier?.middleName} ${cashier?.lastName}`}</p></div>
      <div className='pay-wrapper'><span className='pay-span'>Phone</span><p className='pay-detail'>{cashier?.phone}</p></div>
      <button onClick={()=>setOpen(true)} className='pay-btn'>I've received {app?.currency}{amount} from the above payer.</button>
    </div>}
    </Main>
  );
}
const Main = styled.div`
width: 90%;
padding: 20px 50px;
margin: 50px auto 50px auto;
background: var(--style-bright);
display:flex;
justify-content:space-between;
flex-direction:column;
box-shadow: none;
align-items:center;
@media screen and (max-width:1300px){
    padding:20px;
    width: 100%;
  }
  .modal{
    padding:20px;
  width: 60%;
  margin:0 auto;
  display:flex;
justify-content:space-between;
flex-direction:column;
align-items:center;
  background:var(--style-white);
  box-shadow: none;
  @media screen and (max-width:1300px){
    padding:20px;
    width: 100%;
  }  
  .warn-title{
    color:var(--style-error);
    text-align: center;
    padding:20px;
    font-size: 14px;
  }
  .warn-body{
    color:var(--style-grey);
    text-align: justify;
    padding:20px;
  }
  }
.container{
  padding:20px;
  width: 50%;
  margin:auto;
  display:flex;
justify-content:space-between;
flex-direction:column;
align-items:center;
  background:var(--style-white);
  box-shadow: none;
  @media screen and (max-width:1300px){
    padding:20px;
    width: 100%;
  }
}
.horizontal-line{
  width:100%;
  height:1px;
  background-color: var(--style-grey);
}
.pay-btn{
margin-bottom: 20px;
  padding:10px 15px;
  background:var(--style-bright);
  color:var(--style-blue-dark);
  outline:none;
  border:thin solid var(--style-blue-dark);
  border-radius: 4px;
  transform:scale(1);
  cursor:pointer;
  &:hover{
    transform: scale(1.1);
    transition:var(--transition);
    /* color: var(--style-white); */
  }
}
.pay-title{
  font-size:14px;
  padding:10px;
  text-align:center;
  color: var(--style-grey);
}
.pay-wrapper{
  display:flex;
justify-content:space-between;
align-items:center;
width:100%;
}
.pay-span{
  font-size:14px;
  padding:10px;
  text-align:center;
  font-weight:500;
  color: var(--style-grey);
}
.pay-detail{
  font-size:14px;
  padding:10px;
  text-align:center;
  font-weight:500;
  color: var(--style-grey);
}
.btn-wrapper{
  display:flex;
  justify-content: center;
  align-items: center;
  gap:20px;
  width:100%;
  padding:20px;
  flex-wrap:wrap;
}
.yes{
    color:var(--style-error);
    padding:10px;
    flex-basis:40%;
    width:100% !important;
    border-radius: 4px;
    border: none;
    background-color: var(--style-bright);
    cursor: pointer;
    font-weight: 600;
    &:hover{
    transition: var(--transition);
    font-weight: 500;
    }
}
.no{
    color:var(--style-blue-dark);
    padding:10px;
    flex-basis:40%;
    width:100%;
    border-radius: 4px;
    border: none;
    background-color: var(--style-bright);
    cursor: pointer;
    font-weight: 600;
    &:hover{
    color: var(--style-blue-cyan);
    transition: var(--transition);
    font-weight: 500;
    }
}
`

export default ManualWithdrawal;