import React,{useEffect, useState} from 'react';
import styled from 'styled-components';
import image1 from '../assets/assets/image1.png'
import image2 from '../assets/assets/image2.png'
import image3 from '../assets/assets/image3.png'
import image4 from '../assets/assets/image4.png'
import image5 from '../assets/assets/image5.png'
import image6 from '../assets/assets/spend-it.jpg'
import { FaArrowLeft, FaArrowRight, FaDotCircle } from 'react-icons/fa';
import { AboutContent } from '../pages/JsonContent';

const ImageSlide = () => {
    const images = [{img:image1,content:AboutContent[0].title}, {img:image2,content:AboutContent[1].title}, {img:image3,content:AboutContent[2].title},{img:image4,content:AboutContent[1].title}, {img:image5,content:AboutContent[5].title}, {img:image6,content:AboutContent[7].title}]
    const[current, setCurrent] = useState(0)
    const[upcoming, setUpcoming] = useState(1)

    useEffect(()=>{
       const intervals = setInterval(()=>{
            if(current===(images.length-1)){
                return setCurrent(0)
            }else if(current<images.length-1){
                return setCurrent(current+1)
            }
         },5000)
         return ()=>{
            clearInterval(intervals)
         }
    })

    const handleNext = ()=>{
        setUpcoming(upcoming+1)
        if(upcoming===(images.length-1)){
            setUpcoming(0)
        }else if(upcoming<images.length-1){
            setUpcoming(upcoming+1)
        }
        
        setCurrent(current+1)
        if(current===(images.length-1)){
            setCurrent(0)
        }else if(current<images.length-1){
            setCurrent(current+1)
        }
    }

    const handlePrev = ()=>{
        setCurrent(current-1)
        if(current===0){
            setCurrent(images.length-1)
        }else if(current>=1){
            setCurrent(current-1)
        }

        setUpcoming(upcoming-1)
        if(upcoming===0){
            setUpcoming(images.length-1)
        }else if(upcoming>=1){
            setUpcoming(upcoming-1)
        }
    }
    return (
        <Main>
            <div className='image-list'>
                    <div className='upcoming-image-container'>
                    <img src={images[upcoming].img} alt='' className='upcoming-image-item'/>
                    </div>
                <div className='image-content'>
                <p className='image-prev'>
                    {
                        current>0?
                        <FaArrowLeft className='prev-icon-active' onClick={handlePrev} />
                        :<FaArrowLeft className='prev-icon' />
                        
                    }
                    </p>
                 <img src={images[current].img} alt='' className='image-item'/>
                   
                <p className='image-next'>
                {
                        current<images.length-1?
                        <FaArrowRight className='next-icon-active' onClick={handleNext} />
                        :<FaArrowRight className='next-icon' />
                        
                    }
                </p>
                </div>
                 <div className='image-reader-container'>
                    <p className='content-image'>{images[current].content}</p>
                    <div className='dots-container'>
                    {
                        images.map((item,index)=>{
                            return<div key={index} className={index===current?'image-reader-dot-active':'image-reader-dot'}>
                                <FaDotCircle className='image-reader-dot-icon' />
                            </div>
                        })
                    }
                    </div>
                 </div>
                </div>
        </Main>
    );
};
const Main = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
    .image-list{
        width:60%;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap:20px;
        @media screen and (max-width:800px){
            width: 100%;
            padding:0;
        }
    }
    .upcoming-image-container{
        width:100%;
        padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:20px;
    position: absolute;
    top: auto;
    z-index: 0;
    opacity: 0.2;
}
.upcoming-image-item{
    border-radius: 10px;
    width: 1000px;
    height: 380px;
    z-index: 1;
    @media screen and (max-width: 800px){
        width: 100%;
    }
}
.image-content{
    width:100%;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:20px;
    z-index: 3;
    @media screen and (max-width: 800px){
        padding: 0px;
        position: relative;
    }
}
.prev-icon,.next-icon{
    background-color: var(--style-grey);
    opacity: 0.3;
    border-radius: 50%;
    padding: 20px;
    font-size: 80px;
    z-index: 3;
}
@media screen and (max-width:800px){
        .prev-icon-active,
        .prev-icon{
            position: absolute;
            font-size: 30px;
            padding: 5px;
            left:55px;
            bottom: 170px;
        }
        .next-icon,.next-icon-active{
            position: absolute;
            padding: 5px;
            font-size: 30px;
            right:55px;
            bottom: 170px;
        }
}
@media screen and (max-width:500px){
        .prev-icon-active,
        .prev-icon{
            position: absolute;
            font-size: 30px;
            padding: 5px;
            left:5px;
            bottom: 170px;
        }
        .next-icon,.next-icon-active{
            position: absolute;
            padding: 5px;
            font-size: 30px;
            right:5px;
            bottom: 170px;
        }
}
.prev-icon-active,.next-icon-active{
    color: gray;
    background-color: var(--style-blue-dark);
    border-radius: 50%;
    padding: 20px;
    font-size: 80px;
    cursor: pointer;
    z-index: 3;
    @media screen and (max-width:800px){
        font-size: 30px;
        padding: 5px;
    }
}
.image-item{
    min-width: 500px;
    max-width:500px;
    height: 350px;
    border-radius: 10px;
    margin: 30px;
    @media screen and (max-width:800px){
        min-width: 100%;
        max-width:100%;
        margin: auto;
    }
}
.image-reader-container{
    position: absolute;
    top:65%;
    background-color: var(--style-blue-dark);
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 500px;
    gap:20px;
    height: fit-content;
    padding: 20px 10px;
    z-index: 4;
    border-radius: 5px;
    @media screen  and (max-width: 800px){
        width: 60%;
    }
    @media screen  and (max-width: 500px){
        width: 80%;
    }
    @media screen  and (max-width: 430px){
        width: 60%;
    }
}
.content-image{
    color: var(--style-white);
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
}
.dots-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 50%;
    height: fit-content;
    width: 100%;
}
.image-reader-dot-active{
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    background:var(--style-blue-cyan);
    border-radius:50%;
    height:10px;
    width:10px;

}
.image-reader-dot{
    display:flex;
    justify-content: center;
    align-items: center;
    background:var(--style-grey);
    border-radius:50%;
    height:10px;
    width:10px;
    padding: 5px 5px;
}

.image-reader-dot-icon-wrapper{
    color: #000;
    text-align: center;
    opacity: 0.5;
}
.image-reader-dot-icon{
    color: #000;
    text-align: center;
    opacity: 0.5;
}
`
export default ImageSlide;