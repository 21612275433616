import React, { useState } from 'react';
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import { url } from '../../../Redux/Api';
import { FaSpinner } from 'react-icons/fa';
import { AboutContent } from '../JsonContent';
import ImageSlide from '../../Parts/ImageSlide';

const About = () => {
    const[email, setEmail] = useState('')
    const[subscriptionSuccess, setSubscriptionSuccess] = useState('')
    const[subscriptionError, setSubscriptionError] = useState('')
    const[subscriptionLoading, setSubscriptionLoading] = useState(false)

const app = useSelector(state=>state.apps.items)
console.log('newsletterSubscribers',app?.newsletterSubscribers?.length)
const handleSubmit = e=>{
    e.preventDefault()
    setSubscriptionLoading(true)
    async function fetchData(){
await fetch(`${url}/newsletter-subsrciption`,{
    method:'post',
    headers:{
        'Content-Type':'application/json',
        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
    },
    body:JSON.stringify({
        email,appId:app?._id
    })
}).then(res=>res.json())
.then(resp=>{
    if(resp.response){
        setSubscriptionSuccess(resp.response)
        setSubscriptionLoading(false)
    }else if (resp.err){
        setSubscriptionError(resp.err)
        setSubscriptionLoading(false)
    }
}).catch(err=>{
    if(err){
        setSubscriptionError(err.message)
        setSubscriptionLoading(false)
    }
})
    }
    fetchData()
}
    
    return (
        <Main>
            <div className='about-top'>
                <ImageSlide />
            </div>
            <div className='bottom'>
            {/* <div className='about-left'>
                <div className='left-add-wrapper'>
               <img src={image6} className='left-add' alt='' />
                </div>
                </div> */}
                <div className='about-middle'>
                <div  className='about-middle-content'>
                    <h5 className='about-middle-section-title'>About {app?.name}</h5>
                    {
                        AboutContent.map((item,index)=>{
                            return(<div key={index} className='about-middle-content-items'>
                                <h6 className='about-title'>{item.title}</h6><p className='about-body'>{item.body}</p></div>)
                        })
                    }
                </div>
                </div>
                <div className='about-right'>
                    <form onSubmit={handleSubmit} className='newsletter-form'>
                        {
                            subscriptionSuccess!==''?<p className='subscription-success'>{subscriptionSuccess}</p>:
                            subscriptionError!==''?<p className='subscription-error'>{subscriptionError}</p>:<p className='newsletter-form-title'>Subscribe to newsletter</p>
                        }
                        <input type='email' placeholder='Enter email' onChange={e=>setEmail(e.target.value)} className='newsletter-input' />
                        {
                            subscriptionLoading?
                            <FaSpinner className='about-image-loading' />
                            :<button type='submit' className='newsletter-btn'>Subscribe</button>
                            }
                    </form>
                </div>
            </div>
        </Main>
    );
};

const Main = styled.div`
width:100%;
padding: 20px;
height: auto;
display: flex;
    justify-content: center;
    flex-direction: column;
    background: var(--style-blue-dark);
    .about-top{
        width: 100%;
        height: 450px;
        overflow-y: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
.bottom{
    width: 100%;
    padding: 20px 50px;
    background-color: var(--style-bright);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @media screen and (max-width: 850px){
        flex-direction: column;
        padding: 5px 5px;
    }
}
.about-left{
flex-basis: 20%;
/* box-shadow: 1px 1px 1px rgb(450,450,450); */
@media screen and (max-width: 800px) {
    flex-basis: 100%;
    width:100%;
}
}
.left-add-wrapper{
    /* flex-basis: 20%;
display: flex;
justify-content: space-between;
align-items: center;
/* box-shadow: 1px 1px 1px rgb(450,450,450); */ 
margin-top: 20px;
flex-basis: 20%;
display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* box-shadow: 2px 3px 2px rgb(220,220,220); */
}
.left-add{
    width: 100%;
    padding: 20px;
}
.about-middle{
    flex-basis: 75%;
    /* box-shadow: 1px 1px 1px rgb(210,210,210); */
    padding: 20px 0px;
    @media screen and (max-width: 800px) {
    flex-basis: 100%;
    width:100%;
}
}
.about-middle-content{
    background-color: var(--style-bright);
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 1px 1px 1px rgb(450,450,450); */
    border-radius: 10px;
    margin-top: 20px;
    flex-direction: column;
    gap:20px;
}
.about-middle-section-title{
    text-align: center;
    padding: 10px;
    color: var(--style-blue-dark);
    font-size: 20px;
    font-weight: bold;
}
.about-middle-content-items{
    width: 100%;
    padding: 10px 5px;
    @media screen and (max-width: 800px){
        padding: 5px 5px;
    }
}
.about-title{
 font-size:20px;
 font-weight: bold;
 padding: 20px 0px;
 color: var(--style-grey);
}
.about-body{
font-weight: 16px;
text-align: justify;
color: var(--style-blue-dark);
}
.about-right{
    margin-top: 20px;
    flex-basis: 25%;
display: flex;
justify-content: space-between;
align-items: center;
/* box-shadow: 1px 1px 1px rgb(450,450,450); */
@media screen and (max-width: 800px) {
    flex-basis: 100%;
    width:100%;
}
}
.newsletter-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height:150px;
    /* box-shadow: 2px 3px 2px rgb(220,220,220); */
}
.subscription-success{
color: var(--style-success);
margin-bottom: 15px;
text-align:center;
}
.subscription-error{
    color: var(--style-error);
    margin-bottom: 15px;
    text-align:center;
}
.newsletter-form-title{
text-align: center;
color: var(--style-blue-dark);
font-size: 16px;
} 
.newsletter-input{
height: 35px;
width: 90%;
border-radius: 5px;
padding: 5px;
border: 1px solid lightgray;
} 
.newsletter-btn{
    background-color: var(--style-blue-dark);
    color: var(--style-blue-cyan);
    margin-bottom: 20px;
    padding:5px 10px;
    border-radius:3px;
    cursor:pointer;
    outline: none;
    border: none;
}
.about-image-loading{
    color: var(--style-blue-dark);
}
`
export default About;