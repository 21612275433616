import { Button, Input } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {FaSpinner} from 'react-icons/fa'
import styled from 'styled-components';
import { loginUser } from '../../../../Redux/Slices/AuthSlice';
import image from '../../../assets/referrer.jpg'
import { url } from '../../../../Redux/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const[register, setRegister] = useState(false)
  const {referrer} = useParams()
    const[sent, setSent] = useState(false)
  const[email, setEmail] = useState('')
  const[tokenValue, setTokenValue] = useState('')
  const[mailError, setMailError] = useState(null)
  const[isLoading, setIsLoading] = useState(false)
  const[emailNotAvailable, setEmailNotAvailable] = useState('')
  const[captured, setCaptured] = useState('')
  const[password, setPassword] = useState('')
  const[lastMailTried, setLastMailTried] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loginStatus = useSelector(state=>state.auth.loginStatus)
  const user = useSelector(state=>state.auth.user)

  const handleLogin = (e)=>{
    e.preventDefault()
     dispatch(loginUser({email,password}))
  }
   
if(user){
  navigate(-1)
}
    const token = ()=>{
        setTokenValue(setTimeout(()=>{
            Math.floor(Math.random() * 300)
        },3000))
    }

    const handleSubmit = async(e)=>{
    e.preventDefault()
    token()
    setIsLoading(true)
    await fetch(`${url}/check-email`,{
        method:'post',
        headers:{
            'Content-Type':'application/json'
        },
        body:JSON.stringify({
            email
        })
    }).then(res=>res.json())
    .then(data=>{
        if(data.resp){
            fetch(`${url}/create-account`,{
                method:'post',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+localStorage.getItem('token')
                },
                body:JSON.stringify({
                    email,referrer:referrer===undefined?'self':referrer
                })
            }).then(res=>res.json())
            .then((data)=>{
                if(data.mailData&&data.token){
                  toast.success(`Check ${email} for confirmation.`, {position: toast.POSITION.TOP_RIGHT})
                  setIsLoading(false)
                  setSent(true)
                }else if(data.err){
                    setMailError(data.err)
                    setIsLoading(false)
                    toast.error(data.err, {position: toast.POSITION.TOP_RIGHT})
                }
            }).catch(err=>{
              setIsLoading(false)
              toast.error(err.message, {position: toast.POSITION.TOP_RIGHT});
            })
        }else if(data.err){
          setLastMailTried(email)
          setIsLoading(false)
          setCaptured(email)
            return setEmailNotAvailable(data.err)
        }else{
          return setEmailNotAvailable('Sorry, try again.')
      }
    })
}
useEffect(()=>{
  if(email.length<1){
    setEmailNotAvailable('')
  }
},[email])
useEffect(()=>{if(referrer!==undefined){
  setRegister(true)
}},[referrer])
  return (
    <Main>
      <ToastContainer />
      <div className='right-circle'></div>
      <div className='sub-container'>
      <div className='login-left-widget' img={image}></div>
      <div className='login-right-widget'>
        {
          register===true?
          <div>
          {sent===true?
          [<div className='login-right-login'>
            <p className='check-link'>We sent a link to {email}. Kindly click the link to complete the process within 24hrs.</p>
            <button onClick={()=>setRegister(false)} className='login'>Login</button>
          </div>,
          <div className='login-right-login'>
          <p className='check-link'>Didn't get the mail?</p>
          <p onClick={handleSubmit} className='resend-mail'>Resend</p>
        </div>]
          :<form className='login-form' onSubmit={handleSubmit}>
            <h3 className='register-title'>Get Started</h3>
            {
              emailNotAvailable===''&&<div>
              <p className='already-have-account'>Already have an account?</p><p onClick={()=>setRegister(false)} className='login-instead'>Login</p>
              </div>
            }
            <input type='email' placeholder='Email' required className='login-input' onChange={(e)=>setEmail(e.target.value)} />
            {
              emailNotAvailable!==''&&<div>
                <div className='already-used-wrapper'>
                {
                  email.length>0&&captured.length===email.length&&<p className='already-used'>{email} is already used. Login in instead?</p>
                }
                <p onClick={()=>setRegister(false)} className='register-now'>Login</p>
                </div>
              </div>
            }
          {
            lastMailTried!==email&&<div>
            {isLoading===true?<FaSpinner className='register-spinner' />:<button type='submit' className='login-btn'>Submit</button>}
            </div>
          }
          </form>}
          </div>:
          <form className='login-form' onSubmit={handleLogin}>
            <h3 className='login-title'>Login</h3>
            <p>Don't have an account?</p><span onClick={()=>setRegister(true)} className='register-now'>Register</span>
          <input type='email' placeholder='Email' required className='login-input' onChange={(e)=>setEmail(e.target.value)} />
          <input type='password' placeholder='Password' required className='login-input' onChange={(e)=>setPassword(e.target.value)} />
          {
            loginStatus==='rejected'&&<p className='incorrect-details'>Incorrect details</p>
          }

            {loginStatus==='pending'?<FaSpinner className='spinner' />:<div>
              {loginStatus==='fulfilled'?null:
              <button className='login-btn' type='submit'>Login</button>}
              </div>
            }
            <Link to='/forgotten-password'>Fogotten password?</Link>

          </form>
        }
      </div>
      </div>
      <div className='left-circle'></div>
    </Main>
  );
};
const Main = styled.div`
background-color: var(--style-blue-dark);
max-width: 60vw;
width: 100%;
min-height: 80vh;
height: 100%;
margin: 40px auto;
padding:100px;
position: relative;
z-index: 1;
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
.sub-container{
  display: flex;
justify-content: space-between;
align-items: center;
background-color: var(--style-blue-cyan);
position: absolute;
width: 80%;
margin: auto;
z-index: 0;
padding: 30px;
}
@media screen and (max-width:1000px){
  max-width: 100vh;
width: 100%;
min-height: 100vh;
height: 100%;
margin: 14px auto;
padding:10px;
  .sub-container{
    display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column-reverse;
background-color: var(--style-white);
position: relative;
width: 80%;
margin: auto;
height:fit-content;
z-index: 30;
padding: 30px;
/* gap: 20px; */
}
}
.login-left-widget{
  background-image: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  flex-basis: 50%;
  height: 50vh;
  width: 100%;
}
@media screen and (max-width: 1000px){
  .login-left-widget{
  display: none;
}
}
.welcome{
  /* position: absolute;
   top: 100px;
  left: 100px; 
  z-index: 300;
  font-size: 16px;
  line-height: 10px;
  text-shadow: #fc0 11px 0 10px;
  margin-left: 50px;
  margin-top: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bolder; */
  /* color: gray; */
}
.login-right-widget{
  flex-basis: 50%;
  background-color: var(--style-white);
  padding: 30px;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width:1000px){
  .login-right-widget{
    flex-basis: 100%;
    width: 100%;
    padding: 0px;
  }
}
.login-right-login{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login{
  padding: 5px 10px;
  color: var(--style-blue-cyan);
  border-radius: 5px;
  background-color: var(--style-blue-dark);
}
.resend-mail{
  cursor:pointer;
  color:var(--style-blue);
}
.did-not-get{
  color:var(--style-grey);
  font-size: 14px;
  font-weight: 100;
}
.check-link{
  font-weight: bold;
  color: var(--style-blue-dark);
}
.login-form{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}
.login-input{
  width: 100%;
  height: 35px;
  padding: 10px;
  font-size: 14px !important;
  border: none;
  border-bottom: 1px solid gray;
  background-color: var(--style-bright) !important;
}
.login-input:focus{
    padding: 10px;
    outline: none;
  }
  .login-input:active{
    color: var(--style-white);
    padding: 10px;
    outline: none;
    background-color: var(--style-blue-cyan) !important;
  }
  .incorrect-details{
    padding: 0 10px;
    font-weight: 10px;
    color: red;
  }
.left-circle{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 200px;
  width: 200px;
  z-index: 2;
  background-color: var(--style-blue-cyan);
  border-top-right-radius: 200px;
  border-bottom-left-radius: 10px;
}
.register-title,.login-title{
  margin-bottom: 5px;
}
.right-circle{
  position: absolute;
  top: 0;
  right: 0;
  height: 200px;
  width: 200px;
  z-index: 2;
  background-color: var(--style-blue-cyan);
  border-bottom-left-radius: 200px;
  border-top-right-radius: 10px;
}
.register-spinner{
  color: var(--style-blue-dark);
}
.login-btn{
    padding: 5px 35px;
    border:thin solid var(--style-blue-dark);
    color: var(--style-blue-dark);
    background-color: var(--style-white);
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
    font-size:12px;
  &:hover{
    background-color: var(--style-blue);
    color: var(--style-blue-dark);
  }
}
.spinner{
  color: var(--style-blue-dark);
}
.already-have-account{
  font-size: 14px;
  text-align: center;
}
.already-used-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.already-used{
  font-size: 14px;
  color: red;
  text-align: center;
}
.login-instead,.register-now{
  color: var(--style-blue-cyan);
  font-weight: bold;
  cursor: pointer;
}
`
export default Login;