import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "./Api";

export const myGroovesApi = createApi({
  reducerPath: "myGroovesApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${url}`}),
  endpoints: (builder) => ({
    getAllgrooves: builder.query({
      query: () => `myGrooves`,
    }),
  }),
});

export const { useGetAllgroovesQuery } = myGroovesApi;
