import React,{useEffect, useState} from 'react';
import styled from 'styled-components';
import Moment from 'moment'
import './UserComponent.css'
import { makeStyles, Modal } from '@material-ui/core';
import {  FaTimes} from 'react-icons/fa'
import {  GridOn, List } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { url } from '../../../Redux/Api';

const styles = makeStyles(theme=>({
    modalDetail:{
        width:'100%',
        height:'fit-content',
        marginBottom:'40px',
        margin:'150px auto',
        [theme.breakpoints.down('sm')]:{
            margin:'100px auto'
        }
    },
    noBtn:{
        background: 'var(--style-bright)',
        padding:'8px 20px',
        color:'var(--style-blue)',
        border:'thin solid var(--style-blue)',
        outline:'none',
        cursor:'pointer',
        borderRadius:'4px'
    },
    yesBtn:{
        background: 'var(--style-bright)',
        padding:'8px 20px',
        color:'var(--style-error)',
        border:'thin solid var(--style-error)',
        outline:'none',
        cursor:'pointer',
        borderRadius:'4px'
    }
}))
const TransactionHistory = () => {
    const style = styles()
    const[detail,setDetail] = useState(null)
    const[paymentDetail,setPaymentDetail] = useState(null)
    const[display, setDisplay] = useState(true)
    const user = useSelector(state=>state.auth.user)
    const[withdrawals, setWithdrawals] = useState([])

    useEffect(()=>{
     async function fetchData(){
             await fetch(`${url}/withdrawals`,{
                method:'get',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(resp=>resp.json())
            .then(resp=>{
                if(resp.resp){
                    setWithdrawals(resp.resp)
                }
            })
        }
        return fetchData()
    },[])
    
    const app = useSelector(state=>state.apps.items)
    const formatDate = Moment().format('DD-MM-YYYY')
    const[open,setOpen] = useState(false)
    const handleModal = (e)=>{
        setOpen(true)
        setDetail(e)
    }
    const handlePaymentModal = (e)=>{
        setOpen(true)
        setPaymentDetail(e)
    }
    const currentPayment = JSON.parse(sessionStorage.getItem('current-payment'))
    return (
        <Main> 
            <ToastContainer /><h4>{withdrawals.length}&nbsp;{withdrawals.length>1? 'payments':' payment'} as of {formatDate}</h4>
            <SubContainer>
          <DetailsContainer>
          {
            withdrawals&&<div className='display-container'>
            <GridOn onClick={()=>setDisplay(true)} className={display?'grid-view-active':'grid-view-in-active'} />
             <List onClick={()=>setDisplay(false)} className={!display?'list-view-active':'list-view-in-active'} />
      </div>
          }
         <div className={display?'detail-grid-view':'detail-list-view'}>
            {
                currentPayment&&
              <div className={display?'grid-user-item':'list-user-item'}>
                <h5 style={{textAlign:'center',color:'var(--style-lemon)',fontSize:'14px'}}>Current payment</h5>
              <span>Amount {currentPayment.amount}</span>
              <span>Receiver {currentPayment.receiver}</span>
              <span>Phone {currentPayment.phone}</span>
              <span onClick={()=>handlePaymentModal(currentPayment)} style={{cursor:'pointer',fontSize:'25px'}}>...</span>
               <Modal open={open} className={style.modalDetail}>
                <UserDetail>
                    <h5 style={{textAlign:'center',color:'var(--style-lemon)',fontSize:'14px'}}>Current payment</h5>
                      <li><p>Amount &nbsp; {app.currency}{paymentDetail?.amount}</p></li>
                      <li><p>Receiver &nbsp; {paymentDetail?.receiver}</p></li>
                      <li><p>Phone &nbsp; {paymentDetail?.phone}</p></li>
                      <li><p>Bank &nbsp; {paymentDetail?.bankName}</p></li>
                      <li><p>Account Name &nbsp; {paymentDetail?.accountName}</p></li>
                      <li><p>Account Number &nbsp; {paymentDetail?.accountNumber}</p></li>
                      <li><p>Innitiated on &nbsp; {paymentDetail?.time}</p></li>
  <span><FaTimes onClick={()=>setOpen(false)} className='close' /></span>
</UserDetail>
                </Modal> 
</div>
            }
      {
        user.isAdmin?
        withdrawals.map(item=>{
                  return (
                      <div key={item._id} className={display?'grid-user-item':'list-user-item'}>
                        {user.email===item.payee&&<h1 className='own'>Own</h1>}
                        {item.withdrawn?<h1 className='withdrawn'>.</h1>:<h1 className='un-withdrawn'>.</h1>}
                      <span>Paid on {moment(detail?.date).format('DD-MM-YYYY HH:MM:SS')}</span>
                      <span>Amount {item.amount}</span>
                      <span onClick={()=>handleModal(item)} style={{cursor:'pointer',fontSize:'25px'}}>...</span>
                      <Modal open={open} className={style.modalDetail}><UserDetail>
        <li><p>Payment type</p>{detail?.type}</li>
          <li><p>Amount</p>{detail?.amount}</li>
          <li><p>withdrawal ID</p>{detail?._id}</li>
          <li><p>Paid on</p>{moment(detail?.date).format('DD-MM-YYYY HH:MM:SS')}</li>
          <span><FaTimes onClick={()=>setOpen(false)} className='close' /></span>
      </UserDetail>
  </Modal>
                      </div>
                  )
              })
        :
        withdrawals.filter(filt=>filt.payee===user.email).map(item=>{
                  return (
                      <div key={item._id} className={display?'grid-user-item':'list-user-item'}>
                        {item.withdrawn?<h1 className='withdrawn'>.</h1>:<h1 className='un-withdrawn'>.</h1>}
                      <span>Paid on {moment(detail?.lastName).format('DD-MM-YYYY')}</span>
                      <span>Amount {item.amount}</span>
                      <span onClick={()=>handleModal(item)} style={{cursor:'pointer',fontSize:'25px'}}>...</span>
                      <Modal open={open} className={style.modalDetail}><UserDetail>
        <li><p>Payment type</p>{detail?.type}</li>
          <li><p>Amount</p>{detail?.amount}</li>
          <li><p>withdrawal ID</p>{detail?._id}</li>
          <li><p>Paid on</p>{moment(detail?.date).format('DD-MM-YYYY')}</li>
          <span><FaTimes onClick={()=>setOpen(false)} className='close' /></span>
      </UserDetail>
  </Modal>
                      </div>
                  )
              })
          
      }
         </div>
         </DetailsContainer>
            </SubContainer>
        </Main>
    );
};
const Main = styled.div`
width:95%;
padding:1rem;
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
margin: 20px auto;
@media screen and (max-width:1000px){
    margin:30px auto;
}
background-color:var(--style-dark-bg);
.approval-btn{
    color:var(--style-bright);
padding:10px 25px;
    border-radius: 4px;
    border: none;
    background-color: var(--style-blue-dark);
    cursor: pointer;
    font-weight: 600;
    &:hover{
    color: var(--style-blue-cyan);
    transition: var(--transition);
    font-weight: 500;
    }
}
@media screen and (max-width:1000px){
    gap: 10px;
    padding: 5px 5px;
    margin-bottom: 30px;
}
h4{
    text-align: center;
    font-size: 16px;
    color: gray;
}
.grid-view-active{
    background: gray;
    cursor:pointer;
    color: black;
}
.grid-view-in-active{
    background: black;
    cursor:pointer;
    color: gray;
}
.list-view-active{
    background: black;
    cursor:pointer;
    color: gray;
}
.list-view-in-active{
    background: gray;
    cursor:pointer;
    color: black;
}
.display-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    padding: 10px 5px;
    box-shadow: 1px 1px 1px rgb(225, 225, 225);
    border-radius: 5px;
    margin: 30px 0;
}
`
const SubContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
width: 100%;
@media screen and (max-width: 1000px){
width:70vw;
}
`
const DetailsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .delete-user{
        background: transparent;
        cursor:pointer;
        &:hover{
            font-size: 26px;
            transition: all 3ms ease;
        }
    }
    .detail-grid-view{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        max-width: 100%;
        width:auto;
        gap: 40px;
    }
    .detail-list-view{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 100%;
        width:auto;
    }
    .grid-user-item{
    display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
border: 1px solid #333;
box-shadow:#333;
border-radius: 1rem;
gap: 1rem;
padding: 10px 20px;
min-height: 240px;
height: 100px;
width: 300px;
.own{
color:var(--style-lemon);
 font-size:12px;
}
.withdrawn{
 color:var(--style-grey);
 font-size:30px;
 padding:10px;
}
.un-withdrawn{
    color:var(--style-lemon) !important;
    font-size:30px;
    padding:10px;
}
span{
    font-size: 16px;
    padding: 0;
    margin: 0;
}}
.list-user-item{
    display: flex;
justify-content: space-between;
align-items: center;
border: 1px solid #333;
box-shadow:#333;
border-radius: 1rem;
gap: 1rem;
padding: 1rem;
margin:1rem 0;
min-height: 80px;
width: 100%;
flex-wrap: wrap;
span{
    font-size: 16px;
    padding: 0;
    margin: 0;
}
@media screen and (max-width:1000px){
    width: 90%;
    border-radius: 5px;
    justify-content: space-evenly;
}
}
`
const UserDetail = styled.div`
width:70%;
border:none;
outline: none;
padding: 1rem;
border-radius: 5px;
box-shadow: 1px 2px 2px 1px var(--style-grey);
margin: auto;
background: hsl(210,25%,8%);
color: gray;
position: relative;
@media screen and (max-width:1300px){
    width:95%;
padding: 20px;
margin: auto;
}
li{
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.close{
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
}
`
export default TransactionHistory;