import React from 'react';
import styled from 'styled-components';

const Loading = () => {
    return (
        <Main>
            <div className='loader'></div>
        </Main>
    );
};

export const Spinner = ()=>{
    return (
        <Sub>
<div className='spinner'></div>
        </Sub>
    )
}
const Sub = styled.div`
margin:10px;
width: 80px;
 .spinner{
    margin:auto;
    border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid var(--style-blue-dark);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
 }
  /* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

`
const Main = styled.div`
margin: auto;
width: 100%;
 .loader{
    margin:200px auto;
    border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--style-blue-dark);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
 }
  /* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`
export default Loading;