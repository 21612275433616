export const url= process.env.NODE_ENV==='production'?process.env.REACT_APP_RemoteServer:process.env.REACT_APP_LocalServer;
export const clientUrl = process.env.NODE_ENV==='production'?process.env.REACT_APP_RemoteClient:process.env.REACT_APP_LocalClient;
export const publicKey = process.env.REACT_APP_PublicKey;
export const secreteKey = process.env.REACT_APP_SecreteKey;


export const setHeader = ()=>{
    const headers = {
        headers:{'Content-Type':'application/json'}
    }
    return headers
}

export const setAuthHeader = ()=>{
    const headers = {
        headers:{'Content-Type':'application/json','Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))}
    }
    return headers
}