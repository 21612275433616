import { ArrowRightAlt } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { GroovesBarChart, UsersBarChart } from './Charts';
import { Modal, makeStyles } from '@material-ui/core';
import { FaTimes } from 'react-icons/fa';
import { Spinner } from '../../../Loading';
import moment from 'moment';
import { url } from '../../../Redux/Api';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getReferrers } from '../../../Redux/Slices/ReferrersSlice';

const styles = makeStyles(prop=>({
modal:{
    width:500,
    height:'fit-content',
    margin:'100px auto',
    borderRadius:'5px',
    padding:'20px',
    [prop.breakpoints.down('sm')]:{
        width:'100%',
        margin:'auto'
    }
},
modalContainer:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:'100%',
    margin:'auto',
    background:'var(--style-bright)',
    gap:'10px',
    flexDirection:'column'
},
type:{
paddingTop:'20px',
color:'var(--style-dark-bg)',
fontSize:'20px',
fontWeight:500,
textAlign:'center',
textTransform:'capitalize'
},
account:{
color:'var(--style-dark-bg)',
fontSize:'14px',
fontWeight:200,
textAlign:'center',
textTransform:'capitalize'
},
form:{
    display:'flex',
    justifyContent:'space-between',
    width:'100%',
    margin:'auto',
    padding:'20px',
    alignItems:'center',
    gap:'15px',
    flexDirection:'column',
    position:'relative'
},
formItem:{
    display:'flex',
    justifyContent:'space-around',
    width:'100%',
    margin:'auto',
    padding:'5px',
    alignItems:'center',
    gap:'10px',
},
nwbtn:{
    color:'var(--style-bright)',
    backgroundColor:'var(--style-blue-dark)',
    cursor:'pointer',
    padding:'5px 10px',
    borderRadius:'4px',
    border:'none',
    outline:'none'
},
activeNwbtn:{
    color:'var(--style-grey)',
    backgroundColor:'var(--style-bright)',
    padding:'5px 10px',
    borderRadius:'4px',
    border:'none',
    outline:'none'
},
span:{
    color:'var(--style-gray)',
    fontSize:'14px',
    textAlign:'left'
},
required:{
    color:'red',
    fontSize:'14px'
},
input:{
width:'80%',
padding:'10px',
borderRadius:'4px',
border:'none',
outline:'none'
},
button:{
    color:'var(--style-bright)',
    padding:'10px 25px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: 'var(--style-blue-dark)',
    cursor: 'pointer',
    fontWeight: 600,
    'hover':{
    color: 'var(--style-blue-cyan)',
    transition: 'var(--transition)',
    fontWeight: 500
    }

},
closer:{
    color:'var(--style-dark-bg)',
    cursor:'pointer',
    fontSize:'18px',
    position:'absolute',
    right:'30px',
    top:'30px'
}
}))
const Activity = () => {
    const dispatch = useDispatch()
    useEffect(()=>{
    dispatch(getReferrers())
    },[])

    const app = useSelector(state=>state.apps.items)
    const user = useSelector(state=>state.auth.user)
    const users = useSelector(state=>state.users.items)
    
    const referrersData = useSelector(state=>state.referrers.items)
    const[referees, setReferees] = useState([])
    const[referrers, setReferrers] = useState([])
    const[myReferrers, setMyReferrers] = useState([])
    const allBeneficiaries = users?.filter(filt=>filt?.beneficiary?.user===user?.userName)


    function handleJoinStaffs(){
        setLoadingPayment(true)
        async function fetchData(){
            await fetch(`${url}/join-staffs`,{
                method:'post',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    toast.success(resp.resp, {position: toast.POSITION.TOP_RIGHT})
                    setLoadingPayment(false)
                }else if(resp.err){
                    toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
                    setLoadingPayment(false)
                }else{
                    toast.error('Something went wrong. Try again.', {position: toast.POSITION.TOP_RIGHT})
                    setLoadingPayment(false)
                }
            }).catch(err=>toast.error(err.message, {position: toast.POSITION.TOP_RIGHT}))
        }
        return fetchData()
    }
    useEffect(()=>{
        if(referrersData?.length>0){
        setReferees(referrersData[0])
        setReferrers(referrersData[1])
        setMyReferrers(referrersData[2])
        }
    },[referrersData])
    const lastOrbit = user?.orbits[user?.orbits.length-1]
    
    const grooves = useSelector(state=>state.grooves.items)
    const myGrooves = useSelector(state=>state.myGrooves.items)
        
    const[grooveData, setGrooveData] = useState({})
    const[modalProp, setModalProp] = useState({type:'personal',amount:0,placeholder:'',nextWithdrawal:0,buttonText:''});
    const[amount, setAmount] = useState(0)
    const[duration, setDuration] = useState(0)
    const[period, setPeriod] = useState(0)
    const[description, setDescription] = useState('descrition')
    const[requiredMembers, setRequiredMembers] = useState(0)
    let name = 'name';
    
    const grooveMinTarget = grooveData?.target-(app?.grooveRate*grooveData?.target);
    const grooveMinPeriodicTarget = grooveMinTarget/grooveData?.requiredMembers
    const myGroovePayments = grooveData?.members?.filter(filt=>filt?.member===user?.email)
    const[grooveMaxWithdrawable,setGrooveMaxWithdrawable] = useState(0)
    const[nextWithdrawal, setNextWithdrawal] = useState(24)

    const haveWithdrawn = grooveData?.haveWithdrawn?.length;

useEffect(()=>{
    if(myGroovePayments?.length>0){
        setGrooveMaxWithdrawable(myGroovePayments[0]?.withdrawable)
    }
},[myGroovePayments])

    const [loadingPayment, setLoadingPayment] = useState(false);
let savings = [];
user?.savings.forEach(element => {
    return savings.push(element)
});
  function savedRed(a,v){
    return v.amount+a
  }

 const personalSaved = savings?.reduce(savedRed,0)

    const[selectedGroove, setSelectedGroove] = useState('')
    const[selectedReferrer, setSelectedReferrer] = useState('')
    const[referrerData, setReferrerData] = useState({})
    const payments = useSelector(state=>state.payments.items)
    const myPayments = payments?.filter(filt=>filt?.payee===user?.email&&filt?.canWithdraw);
    const currentPayment = myPayments?.length>=2?myPayments[myPayments?.length-2]:myPayments?.length>0&&myPayments[myPayments?.length-1]
    const personalNextWithdrawal = new Date(currentPayment?.nextWithdrawal);
    
    const personalWithdrawable = ()=>{
        if(currentPayment){
            if(lastOrbit?.orbit===1&&lastOrbit?.level<3&&lastOrbit?.level>0&&currentPayment?.canWithdraw){
                return currentPayment?.amount/2+(currentPayment?.amount/2*app?.personalRate)
            }else if(lastOrbit?.orbit>=1&&lastOrbit?.level>=1&&currentPayment?.canWithdraw){
                return currentPayment?.amount+(currentPayment?.amount*app?.personalRate)
            }else if(!currentPayment?.canWithdraw){
                return 0
            }
        }
        return 0
    }

    const personalBalance = ()=>{
        if(currentPayment){
            if(lastOrbit?.orbit===1&&lastOrbit?.level<3&&lastOrbit?.level>0){
                return currentPayment?.amount/2+(currentPayment?.amount/2*app?.personalRate)
            }else if(lastOrbit?.orbit>=1&&lastOrbit?.level>=3&&currentPayment){
                return currentPayment?.amount+(currentPayment?.amount*app?.personalRate)
            }else {
                return 0
            }
        }
        return 0
    }
    
function eligiblePersonal(){
    if(currentPayment){
        if(lastOrbit.orbit===1&&lastOrbit.level>=1&&myPayments?.length>=2&&currentPayment?.canWithdraw&&personalNextWithdrawal<=new Date()&&user?.bank!==undefined&&personalWithdrawable()>=app?.withdrawable){
            return true
        }else if(lastOrbit.orbit>=2&&lastOrbit.level>=1&&myPayments?.length>=2&&currentPayment?.canWithdraw&&personalNextWithdrawal<=new Date()&&user?.bank!==undefined&&personalWithdrawable()>=app?.withdrawable){
            return true
        }else{
            return false
        }
    }
    return false
}

    const[open, setOpen] = useState(false)
    
const navigate = useNavigate()
const salaryHandler = (e)=>{
e.preventDefault()
setLoadingPayment(true)
async function fetchData(){
await fetch(`${url}/salary-withdrawal`,{
    method:'post',
    headers:{'Content-Type':'application/json','Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))}
}).then(res=>res.json()).then(resp=>{
    if(resp.resp){
        toast.success(resp.resp, {position: toast.POSITION.TOP_RIGHT})
        setLoadingPayment(false)
    }else if(resp.err){
        toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
        setLoadingPayment(false)
    }
}).catch(err=>toast.error(err.message, {position: toast.POSITION.TOP_RIGHT}))
}
fetchData()
}
    function handleSubmit(e){
        e.preventDefault()
    if(modalProp.type==='savings'){
        async function fetchData(){
            setLoadingPayment(true)
            await fetch(`${url}/save`,{
              method:'post',
              headers:{
               'Content-Type':'application/json',
               'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
              },body:JSON.stringify({dueDate:nextWithdrawal*24*7,amount:amount})
             }).then(res=>res.json())
             .then(resp=>{
               if(resp.resp){
                toast.success(resp.resp, {position: toast.POSITION.TOP_RIGHT})
                 setLoadingPayment(false)
                 setModalProp({type:'personal',amount:app?.personalMin,placeholder:'',nextWithdrawal:0,buttonText:''})
                return navigate(`/${user?._id}/dashboard`)
               }else if(resp.err){
                toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
                setLoadingPayment(false)
               }else{
                toast.error('Try again.', {position: toast.POSITION.TOP_RIGHT})
               }
             }).catch(err=>{
               if(err){
                toast.error(err.message, {position: toast.POSITION.TOP_RIGHT});
                setLoadingPayment(false)
               }
             })
           }
           setLoadingPayment(false)
           return fetchData()
    }else if(modalProp.type==='personal'||modalProp.type==='groove'||modalProp.type==='groove-top-up'){
        setLoadingPayment(true)
        // setStarted(true)
        async function fetchData(){
        await  fetch(`${url}/make-payment`,{
           method:'post',
           headers:{
            'Content-Type':'application/json',
            'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
        },body:JSON.stringify({grooveId:grooveData._id,type:modalProp.type,nextWithdrawal:nextWithdrawal*24*7,amount:amount})
          }).then(res=>res.json())
          .then(resp=>{
            if(resp.resp){
            //   setpaymentSuccess(false)
            return navigate(`/${user?._id}/${user.email}/${amount}/${grooveData._id}/${user.phone}/${app?.currency}/${modalProp.type}/${nextWithdrawal}/${requiredMembers}/${description}/${name}/payment`)
            //   navigate('/')
            }else if(resp.err){
                // setStarted(false)
            //  setpaymentError(resp.err)
            toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
             setLoadingPayment(false)
            }
          }).catch(err=>{
            if(err){
                toast.error(err.message, {position: toast.POSITION.TOP_RIGHT})
            }
          })
        }
        setLoadingPayment(false)
        
        return fetchData()
    
}else if(modalProp.type==='groove-upgrade'){
    async function fetchData(){
        await fetch(`${url}/upgrade-orbit`,{
            method:'post',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },body:JSON.stringify({
                target:amount,duration:duration*24,period:period*24,description,requiredMembers,grooveId:grooveData._id
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                return navigate(`/${user?._id}/${user.email}/${amount}/${grooveData._id}/${user.phone}/${app?.currency}/${modalProp.type}/${nextWithdrawal*24*7}/${requiredMembers}/${description}/${name}/payment`)
            }else if(resp.err){
                toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
            }else{
                toast.error('Something went wrong.', {position: toast.POSITION.TOP_RIGHT})
            }
        }).catch(err=>toast.error(err.message, {position: toast.POSITION.TOP_RIGHT}))
    }
    fetchData()
}else if(modalProp.type==='groove-withdrawal'){
    async function fetchData(){
        await fetch(`${url}/groove-withdrawal`,{
            method:'post',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },body:JSON.stringify({
                amount,grooveId:grooveData._id
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                return navigate(`/${user?._id}/${amount}/${lastOrbit?.paymentId}/${modalProp.type}/withdrawal`)
            }else if(resp.err){
                toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
            }else{
                toast.error('Something went wrong.', {position: toast.POSITION.TOP_RIGHT})
            }
        }).catch(err=>toast.error(err.message, {position: toast.POSITION.TOP_RIGHT}))
    }
    fetchData()
}else if(modalProp.type==='personal-withdrawal'){
    async function fetchData(){
        await fetch(`${url}/personal-manual-withdrawal`,{
            method:'post',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },body:JSON.stringify({
                amount,paymentId:currentPayment._id
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                return navigate(`/${user?._id}/${amount}/${currentPayment._id}/${modalProp.type}/withdrawal`)
            }else if(resp.err){
                toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
            }else{
                toast.error('Something went wrong.', {position: toast.POSITION.TOP_RIGHT})
            }
        }).catch(err=>toast.error(err.message, {position: toast.POSITION.TOP_RIGHT}))
    }
    fetchData()
}
}

    useEffect(()=>{
        const grooveFilt = myGrooves?.filter(filt=>filt.name===selectedGroove)
        if(grooves?.length>0){
            if(selectedGroove!==''){
                setGrooveData(grooveFilt[0])
            }else{
                if(myGrooves?.length>0){
                    setGrooveData(myGrooves[0])
                }else{
                    return
                }
            }
        }else{
            return
        }
        
    },[selectedGroove,myGrooves,grooves]);
    useEffect(()=>{
        const ReferrerFilt = myReferrers?.filter(filt=>filt.userName===selectedReferrer)
        if(referrers?.length>0){
            if(selectedReferrer!==''){
                setReferrerData(ReferrerFilt[0])
            }else if(selectedReferrer===''&&myReferrers?.length>0){
                setReferrerData(myReferrers[0])
            }
        }else{
            return
        }
        
    },[selectedReferrer,referrers,myReferrers]);

    const personalMinTopUp = ()=>{
        if(user.orbits.length>1){
            return (lastOrbit?.worth)+(app?.increment*lastOrbit?.worth)
        }else{
            return app?.personalMin
        }
    }
    personalMinTopUp()

function modalHandler(e){
    if(e==='personal-top-up'){
        setModalProp({
            type:'personal',
            grooveId:'',
            amount:amount,
            placeholder:'Enter investment amount.',
            nextWithdrawal:nextWithdrawal*24*7,
            buttonText:'Continue Top-up'
        })
    }else if(e==='personal-withdrawal'){
        setModalProp({
            type:'personal-withdrawal',
            grooveId:'',
            amount:amount>lastOrbit?.worth+(app?.personalRate*lastOrbit?.worth)?lastOrbit?.worth+(app?.personalRate*lastOrbit?.worth):amount,
            placeholder:`Max ${app.currency}${personalWithdrawable()}`,
            nextWithdrawal:nextWithdrawal*24*7,
            buttonText:'Continue Withdrawal'
        })
    }else if(e==='personal-savings'){
        setModalProp({
            type:'savings',
            grooveId:'',
            amount:personalWithdrawable(),
            placeholder:'Enter amount to save.',
            nextWithdrawal:nextWithdrawal*24*7,
            buttonText:'Save Now'
        })
    }else if(e==='groove-top-up'){
        setModalProp({
            type:'groove-top-up',
            grooveId:grooveData?._id,
            amount:amount<grooveMinPeriodicTarget?grooveMinPeriodicTarget:amount,
            placeholder:`Pay minimum of ${grooveMinPeriodicTarget}`,
            nextWithdrawal:nextWithdrawal*24*7,
            buttonText:'Continue Top-up'
        })
    }  else if(e==='groove-withdrawal'&&(grooveData?.target-grooveData?.target*app?.grooveRate)<=grooveData?.payments?.filter(filt=>filt.member===user.email)[0]?.amount){
        setModalProp({
            type:'groove-withdrawal',
            grooveId:grooveData?._id,
            amount:amount>grooveMaxWithdrawable?grooveMaxWithdrawable:amount,
            placeholder:'Enter withdrawal amount.',
            nextWithdrawal:nextWithdrawal*24*7,
            buttonText:'Continue withdrawal'
        })
    }else if(e==='groove-upgrade'){
        setModalProp({
            type:'groove-upgrade',
            grooveId:grooveData?._id,
            amount:0,
            duration:duration,
            period:period,
            description:description,
            requiredMembers:requiredMembers,
            placeholder:'Orbit\'s target',
            nextWithdrawal:nextWithdrawal*24*7,
            buttonText:'Continue Orbit Upgrade'
        })
    }
    setOpen(true)
}

const style = styles()
const activeReferrals = myReferrers?.filter(
    filt=>
    filt?.referrer?.used===false&&filt.referrer?.expired===false
   &&(new Date(new Date(filt?.referrer?.date).setDate(new Date(filt?.referrer?.date).getDate()+app?.referralExpiration))>new Date())
    )
    const refReducer = (acc,val)=>{
        return val.worth+acc
    }

    let referralOrbits = [];
    activeReferrals?.forEach(ele=>{
        const val = users.filter(filt=>filt?.email===ele?.email).map(item=>item.email);
        let data = []
        data.push(val)
        data.forEach(element=>{
            const value = payments?.filter(filt=>filt?.payee===element);
            referralOrbits.push(value)
        })
    }
        )
    const referralBonus = referralOrbits?.reduce(refReducer,0);
    const handleCashier = ()=>{
         function fetchData(){
            fetch(`${url}/cashier-application`,{
                method:'post',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    return toast.success(resp.resp,{position:toast.POSITION.TOP_RIGHT})
                }else if(resp.err){
                    return toast.error(resp.err,{position:toast.POSITION.TOP_RIGHT})
                }else{
                    return toast.error('Something went wrong. Try again.',{position: toast.POSITION.TOP_RIGHT})
                }
            })
            .catch(err=>toast.error(err.message,{position: toast.POSITION.TOP_RIGHT}))
        }
        fetchData()
    }
    
    const[showInfo, setShowInfo] = useState(true)

    useEffect(()=>{
        sessionStorage.getItem('showInfo')&&setShowInfo(sessionStorage.getItem('showInfo'))
    },[])
    return (
        <Main>
      <ToastContainer />
             <div id='top'>
                   <div className='main-account'>
                    <Modal open={open} className={style.modal}>
<div className={style.modalContainer}>
    <h3 className={style.type}>{modalProp.type}</h3>
    <p className={style.account}>{modalProp.type==='groove'||modalProp.type==='groove-upgrade'?grooveData.name:user.userName}</p>
    <FaTimes className={style.closer} onClick={()=>setOpen(false)} />
    <form className={style.form} onSubmit={handleSubmit}>
    <div className={style.formItem}>
    <input type='number' step='any' min={0} required placeholder={modalProp.placeholder} className={style.input} onChange={e=>setAmount(e.target.value)} /></div>
       {modalProp.type!=='groove-upgrade'&&modalProp.type!=='personal-withdrawal'?
       <div className={style.formItem}>
{
    new Date('2024-05-01')<=new Date()&&
<button type='submit' onClick={()=>setNextWithdrawal(24)} className={nextWithdrawal===24?style.activeNwbtn:style.nwbtn}>1 day</button>}
<button type='submit' onClick={()=>setNextWithdrawal(24*7)} className={nextWithdrawal===7*24?style.activeNwbtn:style.nwbtn}>1 week</button>
<button type='submit' onClick={()=>setNextWithdrawal(24*30)} className={nextWithdrawal===30*24?style.activeNwbtn:style.nwbtn}>1 month</button>
        {/* <input type='number' step='any' min={0} required placeholder='Next withdrawal' className={style.input} onChange={e=>setNextWithdrawal(e.target.value)} /> */}
        </div>:modalProp.type==='personal-withdrawal'&&null}
       {
        modalProp.type==='groove-upgrade'&&[
            
            <div className={style.formItem}><input type='number' step='any' min={0} required placeholder='Period' className={style.input} onChange={e=>setPeriod(e.target.value)} /><span className={style.required}>*</span></div>,
            <div className={style.formItem}><input type='number' step='any' min={0} required placeholder='Duration' className={style.input} onChange={e=>setDuration(e.target.value)} /><span className={style.required}>*</span></div>,
            <div className={style.formItem}><input type='text' required placeholder='Description' className={style.input} onChange={e=>setDescription(e.target.value)} /><span className={style.required}>*</span></div>,
            <div className={style.formItem}><input type='number' step='any' min={0} required placeholder='Required members' className={style.input} onChange={e=>setRequiredMembers(e.target.value)} /><span className={style.required}>*</span></div>
        ]
       }
       
        {
            loadingPayment?<Spinner />:
           <>
           {
             nextWithdrawal===24||modalProp.type!=='personal'?<button type='submit' className={style.button}>{modalProp.buttonText}</button>:null
           }
           </>
        }
    </form>
</div>
                    </Modal>
                    <div id='account-header'>
                    <h2 className='account-type'>Personal Account</h2>
                    <div id='account-header-details'>
                    <div id='account-header-wrap'>
                    {new Date('2024-06-01')>=new Date()?<div><h6 style={{color:'gold',textAlign:'center'}}>Insights!</h6>
                    <p className='personal-info'>
                    Having delayed payments? Well, beside the delayed payments caused by our server maintenance, one of the major causes of delayed payments is due to cues. We pay thousands of investors every day and so we control ripple effects from this by priotizing payees. Those who help to keep the chain strong by bringing in than they take out (either by making frequent top-ops or by engaging in our martketing and bring in other investors. This helps us to gain more strength in the deteroriating and unstable economy). But this does not by any means imply that investors must have to bring in other investors to get paid, this only happens when the market is not favorable usually due to Government policies etc. So if you happen to be affected, please ensure you have been telling friends about cash orbits. Thanks.
                    </p>
                    </div>:
                    lastOrbit?.level<1&&!user.isStaff&&showInfo&&<div className='personal-info-wrapper'><p className='personal-info'>{`${user.firstName} our marketers are paid ${app?.currency}${app?.baseSalary} monthly salary.`}</p><FaTimes style={{cursor:'pointer'}} onClick={()=>{setShowInfo(false);return sessionStorage.setItem('showInfo',false);}} /></div>
                    }
                    {
                        currentPayment?.canWithdraw&&personalWithdrawable()>=app.withdrawable&&!user.bank&&personalNextWithdrawal<=new Date()&&<p className='personal-info'>Kindly setup a bank account in order to be able to withdraw.</p>
                    }
                        </div>
                        <div id='sales-arrow'>
                            {/* <ArrowRightAlt id={new Date(new Date(filt?.referrer?.date).setDate(new Date(filt?.referrer?.date).getDate()-app?.referralExpiration))>new Date()?'profit-arrow':'lose-arrow'} /> */}
                        </div>
                        </div>
                        
                    {
                        app?.applicants?.filter(filt=>filt===user?.email).length>0||user?.health<1||user?.isCashier===true?null:<button onClick={handleCashier} className='withdrawable-personal'>Apply for cashier position</button>
                    }
                    </div>
                    <div className='charts-wrapper'>
                    <div id='charts'>
                        <div id='sales-details'>
                            <div id='price-wrap'>
                            <p id='price'>
                            {lastOrbit?.orbit} | {lastOrbit?.level}</p>
                            <p id='percentage'>{user.userName}</p>
                            </div>
                            <div id='sales-arrow'>
                <ArrowRightAlt id={
                    lastOrbit?.level>0?'profit-arrow':'lose-arrow'} />
                            </div>
                        </div>
                        <h6>Orbit | Level</h6>
                    </div>
                    <div id='charts'>
                        <div id='sales-details'>
                            <div id='price-wrap'>
                            <p id='price'>{lastOrbit?.worth>0&&app?.currency}{lastOrbit?.worth}</p>
                            <p id='percentage'>{personalBalance()>0&&app?.currency}{personalBalance()}</p>
                            </div>
                            <div id='sales-arrow'>
                <ArrowRightAlt id={
                    lastOrbit?.worth
                    >=app?.personalMin?'profit-arrow':'lose-arrow'} />
                            </div>
                        </div>
                        <h6>Worth | Balance</h6>
                    </div>
                    <div id='charts'>
                        <div id='sales-details'>
                            <div id='price-wrap'>
                            <p id='price'>{app?.currency}{lastOrbit?.level>0?personalWithdrawable():app?.personalMin}</p>
                            <p id='percentage'>{lastOrbit?.level>0&&moment(personalNextWithdrawal).format('DD-MM-YYYY hh:mm:ss')}</p>
                            </div>
                            <div id='sales-arrow'>
                <ArrowRightAlt id={
                   lastOrbit?.level>0&& eligiblePersonal()
                    ?'profit-arrow':'lose-arrow'} />
                            </div>
                        </div>
                        <h6>{lastOrbit?.level>0?'Withdrawable':'Minimum payment'}</h6>
                    </div>
                    <div id='charts'>
                        <div id='sales-details'>
                            <div id='price-wrap'>
                            <p id='price'>{`${user.health}%`}</p>
                            {/* <p id='percentage'>+$8.50%</p> */}
                            </div>
                            <div id='sales-arrow'>
                <ArrowRightAlt id={user?.health>=100?'profit-arrow':'lose-arrow'} />
                            </div>
                        </div>
                        <h6>Account Health</h6>
                    </div>
                    <div id='charts'>
                    <div id='sales-details'>
                    <div id='price-wrap'>
                        <p id='price'>{user?.paid>0?'Active':'Inactive'}</p>
                            {/* <p id='percentage'>+$5.44%</p> */}
                            </div>
                            <div id='sales-arrow'>
                            <ArrowRightAlt id={user?.paid>0?'profit-arrow':'lose-arrow'} />
                            </div>
                        </div>
                        <h6>Investor Status</h6>
                        
                    </div>
                    <div id='charts'>
                    <div id='sales-details'>
                    <div id='price-wrap'>
                        <p id='price'>{personalSaved>0&&app?.currency}{personalSaved}</p>
                            {/* <p id='percentage'>+$5.44%</p> */}
                            </div>
                            <div id='sales-arrow'>
                            <ArrowRightAlt id={personalSaved>0?'profit-arrow':'lose-arrow'} />
                            </div>
                        </div>
                        <h6>Savings</h6>
                        
                    </div>
                    
                    </div>
                    <div className='btnWrap'>
                    <button id='button' onClick={()=>modalHandler('personal-top-up')} className='withdrawable-personal'>Top up</button>
                    {
                        eligiblePersonal()&&
                        <button id='button' onClick={()=>modalHandler('personal-withdrawal')} className='withdrawable-personal'>Withdraw</button>
                    }
                       {personalWithdrawable()>=app?.withdrawable&&personalNextWithdrawal>=new Date() && <button id='button' onClick={()=>modalHandler('personal-savings')} className='withdrawable-personal'>Save</button>}

                    </div>
                   </div>
                   {/* referral start*/}
                   {
                    user?.isStaff?
                    <div className='groove-account'>
                    <h2 className='account-type'>Staff's Record</h2>
                    <p className='groove-description'>{referrerData?.userName}</p>
                    <div className='charts-wrapper'>
                        {
                            myReferrers?.length>1?
                            <select id='select-groove' onChange={e=>setSelectedReferrer(e.target.value)}>
                            <option disabled>Select a referrence</option>
                            {
                                myReferrers?.map((referrer,ind)=>{
                                    return (
                                        <option key={ind} value={referrer.name}>{referrer.name}</option>
                                    )
                                })}
                        </select>
                            :null
                        }
                        
                    <div id='charts'>
                    <div id='sales-details'>
                    <div id='price-wrap'>
                            <p id='price'>{`${myReferrers?.length} | 30`}</p>
                            <p id='percentage'>
                            {`${Math.round(myReferrers?.length*100/30)}%`}
                            </p>
                        </div>
                        <div id='sales-arrow'>
                            {/* <ArrowRightAlt id={new Date(new Date(filt?.referrer?.date).setDate(new Date(filt?.referrer?.date).getDate()-app?.referralExpiration))>new Date()?'profit-arrow':'lose-arrow'} /> */}
                        </div>
                        </div>
                        <h6>Accomplished | Task</h6>
                    </div>
                    <div id='charts'>
                    <div id='sales-details'>
                    <div id='price-wrap'>
                            <p id='percentage'>
                            {`${myReferrers?.length}`} | {`${activeReferrals?.length}`}</p>
                            </div>
                            <div id='sales-arrow'>
                            <ArrowRightAlt id={
                                referralOrbits?.length>0
                                ?'profit-arrow':'lose-arrow'} />
                            </div>
                        </div>
                        <h6>Referrers | Active</h6>
                        
                    </div>
                    <div id='charts'>
                    <div id='sales-details'>
                    <div id='price-wrap'>
                        <p id='price'> 
                        {`${app?.currency}${app?.baseSalary}`}
                        </p>
                            <p id='percentage'>{referralBonus>0&&app?.currency}
                            {`${referralBonus}`}</p>
                            </div>
                            <div id='sales-arrow'>
                            <ArrowRightAlt id={
                                activeReferrals?.length>0
                                ?'profit-arrow':'lose-arrow'} />
                            </div>
                        </div>
                        <h6>Base Salary | Bonus</h6>
                        
                    </div>
                    </div>
<div className='btnWrap'>
<button id='button' onClick={()=>navigate(`/${user?._id}/profile`)} className='withdrawable-groove'>Get to work</button>

{app?.salaryPaymentRequests?.filter(filt=>filt.payee===user?.email)?.length>0?
    app?.salaryPaymentRequests?.filter(filt=>filt.payee===user?.email)[0]?.status==='pending'?'Pending payment':
null:
allBeneficiaries?.length>=app?.salaryTarget&&<button id='button' onClick={salaryHandler} className={activeReferrals?.length<=30?'withdrawable-groove':'unwithdrawable-groove'}>Withdraw</button>}
</div>
                      </div>
                    :user?.isStaff===false&&<button onClick={handleJoinStaffs} className='withdrawable-groove'>Join our marketers</button>
                   }
                   {/* referral end */}
                   {
                    myGrooves?.length>0?
                    <div className='groove-account'>
                        <div id='account-header'>
                        <h2 className='account-type'>Groove Account</h2>
                    <div id='account-header-details'>
                    <div id='account-header-wrap'>
                    <h2 className='groove-name'>{grooveData?.name}&nbsp;@&nbsp;{grooveData?.orbit}</h2>
                    <p className='groove-description'>{grooveData?.description}</p>
                        </div>
                        <div id='sales-arrow'>
                            {/* <ArrowRightAlt id={new Date(new Date(filt?.referrer?.date).setDate(new Date(filt?.referrer?.date).getDate()-app?.referralExpiration))>new Date()?'profit-arrow':'lose-arrow'} /> */}
                        </div>
                        </div>
                    </div>
                    <div className='charts-wrapper'>
                        {
                            myGrooves?.length>1?
                            <select id='select-groove' onChange={e=>setSelectedGroove(e.target.value)}>
                            <option disabled>Select a groove</option>
                            {
                                myGrooves?.map((groove,ind)=>{
                                    return (
                                        <option key={ind} value={groove.name}>{groove.name}</option>
                                    )
                                })}
                        </select>
                            :null
                        }
                        
                        <div id='charts'>
                    <div id='sales-details'>
                    <div id='price-wrap'>
                            <p id='price'>{app?.currency}{grooveData?.target}</p>|
                            <p id='percentage'>{app?.currency}{grooveData?.target-grooveData?.target*app?.grooveRate}</p>
                        </div>
                        <div id='sales-arrow'>
                            <ArrowRightAlt id={
                                (grooveData?.payments?.filter(filt=>filt.member===user?.email)[0]?.amount)/(grooveData?.target-grooveData?.target*app?.grooveRate)>=1?'profit-arrow':'lose-arrow'} />
                        </div>
                        </div>
                        <h6>Target | Task</h6>
                    </div>
                    {grooveData?.members?.filter(filt=>filt.member===user?.email).length>0&&
                     <div id='charts'>
                    <div id='sales-details'>
                    <div id='price-wrap'>
                            <p id='price'>{app?.currency}{grooveData?.payments?.filter(filt=>filt.member===user?.email)[0]?.amount}</p>
                            <p id='percentage'>{`${Math.round((grooveData?.payments?.filter(filt=>filt.member===user?.email)[0]?.amount)/(grooveData?.target-grooveData?.target*app?.grooveRate)*100)}%`}</p>
                        </div>
                        <div id='sales-arrow'>
                            <ArrowRightAlt id={
                                (grooveData?.payments?.filter(filt=>filt.member===user?.email)[0]?.amount)/(grooveData?.target-grooveData?.target*app?.grooveRate)
                                >=1?'profit-arrow':'lose-arrow'} />
                        </div>
                        </div>
                        <h6>Acomplishment</h6>
                    </div>}
                    <div id='charts'>
                    <div id='sales-details'>
                    <div id='price-wrap'>
                            <p id='price'>{`${grooveData?.members?.filter(filt=>filt.status==='active').length}/${grooveData?.requiredMembers}`}</p>
                            <p id='percentage'>
                            {`${(grooveData?.members?.filter(filt=>filt.status==='active').length*100)/grooveData?.requiredMembers}`}%</p>
                            
                        </div>
                        <div id='sales-arrow'>
                            <ArrowRightAlt id={
                                (grooveData?.members?.filter(filt=>filt.status==='active').length)/grooveData?.requiredMembers<1?'profit-arrow':'lose-arrow'} />
                        </div>
                        </div>
                        <h6>Groove Members</h6>
                    </div>
                     <div id='charts'>
                        <div id='sales-details'>
                            <div id='price-wrap'>
                            <p id='price'>{`${grooveData?.duration}Days`}</p>|
                            <p id='percentage'>{`${grooveData?.period}`}</p>
                            </div>
                        </div>
                        <h6>Duration | Period</h6>
                    </div>
                    {grooveData?.members?.filter(filt=>filt.member===user?.email).length>0&& <div id='charts'>
                        <div id='sales-details'>
                            <div id='price-wrap'>
                            <p id='price'>{grooveData?.members?.filter(filt=>filt.member===user?.email)[0]?.nextPayment===null?'Pending payment':
                            moment(grooveData?.members?.filter(filt=>filt.member===user?.email)[0]?.nextPayment).format('DD-MM-YYYY HH:MM:SS')
                             <=new Date()?'Pending payment':
                           moment(grooveData?.members?.filter(filt=>filt.member===user?.email)[0]?.nextPayment)
                            .format('DD-MM-YYYY HH:MM:SS')}</p>
                            </div>
                            <div id='sales-arrow'>
                <ArrowRightAlt id={grooveData?.members?.filter(filt=>filt.member===user?.email)[0]?.nextPayment
                     <=new Date()?'profit-arrow':'lose-arrow'} />
                            </div>
                        </div>
                        <h6>Next Payment</h6>
                    </div>}
                    {grooveData?.members?.filter(filt=>filt.member===user?.email).length>0&&<div id='charts'>
                    <div id='sales-details'>
                    <div id='price-wrap'>
                        <p id='price'> {`${Math.round(grooveData?.members?.filter(filt=>filt.member===user?.email)[0]?.progress*100)}%`}</p>
                            {/* <p id='percentage'>+$5.44%</p> */}
                            </div>
                            <div id='sales-arrow'>
                            <ArrowRightAlt id={
                                grooveData?.members?.filter(filt=>filt.member===user?.email)[0]?.progress>=1
                                ?'profit-arrow':'lose-arrow'} />
                            </div>
                        </div>
                        <h6>Progress</h6>
                        
                    </div>}
                    <div id='charts'>
                    <div id='sales-details'>
                    <div id='price-wrap'>
                            <p id='price'>{grooveData?.members?.filter(filt=>filt.member===user?.email)[0]?.status}</p>
                            <p id={
                                grooveData?.members?.filter(filt=>filt.member===user?.email)[0]?.status
                                ==='active'?'percentage-profit':'percentage-lose'}>{grooveData?.members?.filter(filt=>filt.member===user?.email)[0]?.status
                                    ==='active'?'Member':'Not a member'}</p>
                        </div>
                        <div id='sales-arrow'>
                            <ArrowRightAlt id={
                                grooveData?.members?.filter(filt=>filt.member===user?.email)[0]?.status
                                ==='active'
                                ?'profit-arrow':'lose-arrow'} />
                        </div>
                        </div>
                        <h6>Membership status</h6>
                    </div>
                    </div>
<div className='btnWrap'>
{grooveData?.members?.filter(filt=>filt.member===user?.email).length>0&&<button id='button' onClick={()=>modalHandler('groove-top-up')} className='withdrawable-groove'>Top up</button>}
{/* {grooveMaxWithdrawable>0&&<button id='button' onClick={()=>modalHandler('groove-withdrawal')} className='withdrawable-groove'>Withdraw</button>} */}
{/* <button id='button' onClick={()=>modalHandler('groove-withdrawal')} className='withdrawable-groove'>Withdraw</button> */}
{
    grooveData?.members?.filter(filt=>filt.member===user?.email)?.length<1&&grooveData.members.length<grooveData.requiredMembers&&<button id='button' onClick={()=>navigate('/grooves')} className='withdrawable-groove'>Join{grooveData.name}</button>
}
{
    haveWithdrawn<1&&grooveData?.members?.filter(filt=>filt.member===user?.email)?.length>0&&<button id='button' onClick={()=>modalHandler('groove-upgrade')} className='withdrawable-groove'>Upgrade {grooveData.name}</button>
}


</div>
                      </div>:
                    //join a groove.
                   
                    grooves?.length>0?
                    <select onChange={e=>selectedGroove(e.target.value)}>
                    {
                        grooves?.map((item,index)=>{
                            return <option key={index} value={item.name}>{item.name}</option>
                        })
                    }
                </select>:
                <button className='withdrawable-groove' style={{marginTop:'20px'}} onClick={()=>navigate(`/${user?._id}/profile`)}>Be the first to create a groove</button>
                   
                   }
                    </div>
                    <div id='bottom'>
                        {
                            user?.isAdmin?<>
                            <div id='bottom-left'>
                            <p id='chart-title'>Users</p>
                            <UsersBarChart />
                        </div>
                        <div id='bottom-right'>
                            <p id='chart-title'>grooves</p>
                            <GroovesBarChart />
                        </div></>:null
                        }
                    </div>
        </Main>
    );
};

const Main = styled.div`
height: auto;
display: flex;
align-items: center;
flex-direction: column;
width: 95%;
background-color:var(--style-dark-bg);
margin: 30px auto;
padding: 20px;
#top{
display: flex;
justify-content: space-evenly;
align-items: center;
flex-wrap: wrap;
gap: 20px;
overflow-x: hidden;
@media screen and (max-width:950px) {
    width: 100%;
    padding: 5px;
    gap: 0px;
}
}
.account-type{
    padding:10px;
    font-size: 16px;
    font-weight: 300;
    color:var(--style-blue);
}
.groove-name{
    padding:10px;
    font-size: 16px;
    font-weight: 300;
    color:var(--style-bright);
}
.personal-info-wrapper{
    display:flex;
    align-items: center;
justify-content:space-between;
width: 95%;
background-color:var(--style-dark-bg);
margin: auto;
padding:5px;
border-radius:3px;
}
.personal-info{
color:var(--style-grey);
text-align: center;
padding:5px;
font-weight:lighter;
  animation-name: info;
  animation-duration: 20s;
}
@keyframes info {
  from {
    color: var(--style-grey);
}
  to {
    color: var(--style-lemon);
    text-transform:uppercase;
}
}
.groove-description{
    padding:10px;
    font-size: 16px;
    font-weight: 100;
    color:var(--style-grey);
}
.main-account, .groove-account{
display:flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.withdrawable-personal,
.withdrawable-groove{
color:var(--style-bright);
padding:10px 25px;
    border-radius: 4px;
    border: none;
    background-color: var(--style-blue-dark);
    cursor: pointer;
    font-weight: 600;
    &:hover{
    color: var(--style-blue-cyan);
    transition: var(--transition);
    font-weight: 500;
    }
}
.unwithdrawable-personal,.unwithdrawable-groove{
color:var(--style-light-grey);
padding:10px 25px;
    border-radius: 4px;
    border: none;
    background-color: var(--style-grey);
}
.charts-wrapper{
display:flex;
justify-content: center;
align-items: center;
width: 80%;
flex-wrap:wrap;
gap:20px;
}
.btnWrap{
    display:flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
    width: 100%;
    margin: 30px auto;
    gap: 20px;
}
#bottom{
display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;
gap: 20px;
margin:0;
width: 100%;
@media screen and (max-width:950px){
overflow-x: hidden;
    padding: 0;
}
}
#bottom #bottom-left{
padding: 10px;
border-radius: 5px;
box-shadow: 1px 1px 1px gray;
height: auto;
flex-basis:48%;
gap:30px;
display:flex;
align-items:center;
justify-content:space-between;
flex-direction:column;
@media screen and (max-width:950px){
    overflow-x: auto;
    align-items:flex-start;
    flex-basis:100%;
    width:50vw !important;
}
}
#bottom #bottom-right{
padding: 10px;
border-radius: 5px;
box-shadow: 1px 1px 1px gray;
height: auto;
flex-basis:48%;
gap:30px;
display:flex;
align-items:center;
justify-content:space-between;
flex-direction:column;
@media screen and (max-width:950px){
    overflow-x: auto;
    align-items:flex-start;
    flex-basis:100%;
    width:50vw !important;
}
}
#chart-title{
    color:gray;
}
#select-groove{
    background-color: #16191e;
    padding: 25px 10px !important;
    border-radius: 5px;
    box-shadow: 1px 1px 1px gray;
    border:none;
    height: 80px;
    min-width: 270px;
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px auto;
    gap: 10px;
    color:#fff;
    @media screen and (max-width: 950px) {
        padding: 5px;
        height: auto;
        width: 100%;
        margin: 20px auto;
        gap: 5px;
    }
}
#account-header{
    background-color: #16191e;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px gray;
    min-height: 80px;
    max-height:fit-content;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px auto;
    gap: 10px;
    @media screen and (max-width: 950px) {
        padding: 5px;
        height: auto;
        width: 100%;
        margin: 20px auto;
        gap: 5px;
    }
}
#account-header-details{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
#account-header-wrap{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
#charts{
    background-color: #16191e;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px gray;
    min-height: 80px;
    max-height:100px;
    min-width: 270px;
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px auto;
    gap: 10px;
    @media screen and (max-width: 950px) {
        padding: 5px;
        height: auto;
        width: 100%;
        margin: 20px auto;
        gap: 5px;
    }
}
#charts #sales-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
}
#charts #sales-details #price-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
#charts #sales-details #price{
font-size: 16px;
color:var(--style-blue);
@media screen and (max-width:950px){
    font-size: 14px;
}
}
#charts #sales-details #percentage{
    color: green;
    font-size: 11px;
}
#charts #sales-details #percentage-profit{
    color: green;
    font-size: 11px;
}
#charts #sales-details #percentage-lose{
    color: red;
    font-size: 11px;
}
#charts #sales-details #sales-arrow #profit-arrow{
    color: green;
    font-size: 38px;
    transform: rotate(-50grad) !important;
    background-color: #000;
    padding: 5px;
    border-radius: 50%;
}
#charts #sales-details #sales-arrow #lose-arrow{
    color: red;
    font-size: 38px;
    transform: rotate(150grad) !important; 
    background-color: #000;
    padding: 5px;
    border-radius: 50%;
}
#big-chart{
    background-color: #16191e;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px gray;
    height: 250px;
    width: 500px;
}
`
export default Activity;