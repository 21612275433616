import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CircularStatic from '../../../imageUpload';
import { url } from '../../../Redux/Api';

const NewsLetter = () => {
    const[title, setTitle] = useState('')
    const[body, setBody] = useState('')
    const[disclaimer, setDisclaimer] = useState('')
    const[recipient, setRecipient] = useState('')
    const[loading, setLoading] = useState(false)
    const[success, setSuccess] = useState('')
    const[error, setError] = useState('')
    const app = useSelector(state=>state.apps.items)
    const handleSubmit = (e)=>{
        e.preventDefault()
        setLoading(true)
        async function fetchData(){
            await fetch(`${url}/newsletter`,{
                method:'post',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                },body:JSON.stringify({
                    recipient,title,body,appId:app?._id,disclaimer,site:app?.name
                })
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.response){
                    setSuccess(resp.response)
                    setLoading(false)
                }else if(resp.err){
                    setError(resp.err)
                    setLoading(false)
                }
            }).catch(err=>{
                if(err){
                    setError(err.message)
                    setLoading(false)
                }
            })
        }
        fetchData()
    }

    return (
        <Main>
            <div className='create-newsletter-top'>
    {
        success!==''?<p className='create-newsletter-success'>{success}</p>:error!==''?<p className='create-newsletter-error'>{error}</p>:
        <p className='create-newsletter-default'>Send newsletter</p>
    }
            </div>
<form onSubmit={handleSubmit} className='create-newsletter-form'>
    <select onChange={e=>setRecipient(e.target.value)} className='create-newsletter-select'>
        <option className='create-newsletter-option'>select recipient category</option>
        <option value='personal' className='create-newsletter-option'>Personal</option>
        <option value='regular' className='create-newsletter-option'>Regular Businesses</option>
        <option value='businesses' className='create-newsletter-option'>All Businesses</option>
        <option value='premium' className='create-newsletter-option'>Premium Businesses</option>
    </select>
    <input type='text' onChange={e=>setTitle(e.target.value)} placeholder='Tile' className='create-newsletter-input' />
    <textarea type='text' onChange={e=>setBody(e.target.value)} placeholder='Body...' className='create-newsletter-textarea' />
    <textarea type='text' onChange={e=>setDisclaimer(e.target.value)} placeholder='Set disclaimer...(optional)' className='create-newsletter-textarea' />
    {loading?<CircularStatic className='create-newsletter-loading' />:<button type='submit' className='create-newsletter-btn'>Send</button>}
</form>            
        </Main>
    );
};
const Main = styled.div`
width:95%;
margin-bottom: 50px;
padding: 50px 5px;
margin-top: 50px;
margin:20px auto;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
background:var(--style-dark-bg);
@media screen and (max-width:850px){
    overflow-x: hidden;
    align-items: flex-end;
}
.create-newsletter-top{
    width: 50%;
    padding: 10px 20px;
    @media screen and (max-width:850px){
    padding: 5px 5px;
    align-items: flex-end;
    width: 90%;
    margin:auto;
}
}
    .create-newsletter-success{
        text-align: center;
        color: var(--success);
    }
    .create-newsletter-default{
        text-align: center;
        color: var(--gray-color);
    }
    .create-newsletter-error{
        text-align: center;
        color: var(--error);
    }
    .create-newsletter-form{
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
gap: 20px;
width:50%;
@media screen and (max-width:850px){
    padding: 5px 5px;
    width: 90%;
    margin:auto;
}
    }
    .create-newsletter-select{
        padding: 10px 10px;
        width: 100%;
    }
    .create-newsletter-option{
        text-align: center;
        padding: 5px;
    }
    .create-newsletter-input{
        height: 35px;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
    }
    .create-newsletter-textarea{
        padding: 10px;
        border-radius: 5px;
        max-height: fit-content;
        min-height: 300px;
        width: 100%;
    }
    .create-newsletter-loading{
        background-color: white;
    }
    .create-newsletter-btn{
        width: 50%;
padding: 5px;
color:var(--style-blue-cyan);
background-color: var(--style-blue-dark);
border-radius: 4px;
cursor:pointer;
outline: none;
border:none;
transform: scale(1);
&:hover{
transform: scale(1.1);
transition:var(--transition);
}
}
`
export default NewsLetter;