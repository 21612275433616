import React,{useState} from 'react';
import styled from 'styled-components';
import Moment from 'moment'
import './UserComponent.css'
import { makeStyles, Modal } from '@material-ui/core';
import { FaSpinner, FaTimes} from 'react-icons/fa'
import { Delete, GridOn, List } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { url } from '../../../Redux/Api';
import { useNavigate } from 'react-router-dom';

const styles = makeStyles(theme=>({
    modalDetail:{
        width:'90%',
        height:'fit-content',
        margin:'auto',
    },
    deleteModal:{
        maxWidth:'90%',
        minWidth:'fit-content',
        height:'fit-content',
        margin:'100px auto',
        borderRadius:5,
        padding:'1rem',
        background:'hsl(210,25%,8%)',
        // color:'gray',
        // boxShadow:'1px 2px 1px var(--bg-primary-color)',
        // [theme.breakpoints.down('md')]:{
        //     width:'20%',
        //     background:'red'
        // }
    },
    deleteFirstCentent:{
        margin: '1rem auto',
        display: 'flex',
        flexDirection:'column',
        alignItems:'center',
        gap: '2rem',
        p:{
            color: 'red'
        }
    }
    ,
    deleteModalCentent:{
        padding: '1rem',
    },
    deleteModalBtnGroup:{
        display: 'flex',
        justifyContent:'space-around',
        alignItems:'center',
        flexWrap:'wrap',
        marginBottom:'1rem',
    },
    noBtn:{
        background: 'var(--style-bright)',
        padding:'8px 20px',
        color:'var(--style-blue)',
        border:'thin solid var(--style-blue)',
        outline:'none',
        cursor:'pointer',
        borderRadius:'4px'
    },
    yesBtn:{
        background: 'var(--style-bright)',
        padding:'8px 20px',
        color:'var(--style-error)',
        border:'thin solid var(--style-error)',
        outline:'none',
        cursor:'pointer',
        borderRadius:'4px'
    }
}))
const UsersForCashier = () => {
    const[detail,setDetail] = useState(null)
    const[openManualPaymentModal, setOpenManualPaymentModal] = useState(false)
    const user = useSelector(state=>state.auth.user)
    const manualPayments = user?.manualPayments?.filter(filt=>filt.confirmed===false)
    const[currentPayment, setCurrentPayment] = useState(manualPayments[0])
    const{amount,phone,name,cashier,nextWithdrawal,payee,type,duration,period,description,requiredMembers,grooveId} = currentPayment?.tools;
    const [success, setSuccess] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [serverError, setServerError] = useState('')
    const [isloading, setIsloading] = useState(false)
    const[display, setDisplay] = useState(true)
    const [approvalsLoading, setApprovalsLoading] = useState(false);
    
    const confirmManualPayment = (e)=>{
        setApprovalsLoading(true)
        async function fetchData(){
            if(type==='create-groove'){
                await fetch(`${url}/confirm-create-groove/${grooveId}`,{
                method:'post',
                headers:{
                 'Content-Type':'application/json',
                 'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
             },body:JSON.stringify({
                cashier,payee,teller:currentPayment.id,name,target:parseInt(amount),duration:parseInt(duration),period:parseInt(period),description,paymentStatus:'success',image:'',requiredMembers:parseInt(requiredMembers),grooveId})
               }).then(res=>res.json())
               .then(resp=>{
                 if(resp.resp){
                 fetch(`${url}/${currentPayment.id}/re-confirm-manual`,{
                    method:'post',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    }
                 }).then(res=>res.json())
                 .then(resp=>{
                    if(resp.err){
                        setApprovalsLoading(false)
                        return toast.error(resp.err,{position:toast.POSITION.TOP_RIGHT})
                    }else if(resp.resp){
                        setApprovalsLoading(false)
                        toast.success(resp.resp,{position:toast.POSITION.TOP_RIGHT})
                        return setOpenManualPaymentModal(false)
                    }
                 }).catch(err=>toast.error(err.message,{position:toast.POSITION.TOP_RIGHT}))
                 }else if(resp.err){
                 return toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
                 }
               }).catch(err=>{
                 if(err){
                    setApprovalsLoading(false)
                     return toast.error(err.message, {position: toast.POSITION.TOP_RIGHT})
                 }
               })
             
            }else if(type==='groove-upgrade'){
                  await fetch(`${url}/confirm-upgrade-orbit`,{
                    method:'post',
                    headers:{
                     'Content-Type':'application/json',
                     'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                 },body:JSON.stringify({
                    teller:currentPayment.id,cashier,payee,target:parseInt(amount),duration:parseInt(duration),period:parseInt(period),description,paymentStatus:'success',requiredMembers:parseInt(requiredMembers),grooveId})
                   }).then(res=>res.json())
                   .then(resp=>{
                    if(resp.resp){
                        
                 fetch(`${url}/${currentPayment.id}/re-confirm-manual`,{
                    method:'post',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    }
                 }).then(res=>res.json())
                 .then(resp=>{
                    if(resp.err){
                        setApprovalsLoading(false)
                        return toast.error(resp.err,{position:toast.POSITION.TOP_RIGHT})
                    }else if(resp.resp){
                        setApprovalsLoading(false)
                        toast.success(resp.resp,{position:toast.POSITION.TOP_RIGHT})
                        return setOpenManualPaymentModal(false)
                    }
                 }).catch(err=>toast.error(err.message,{position:toast.POSITION.TOP_RIGHT}))
                 
                    }
                   }).catch(err=>{
                     if(err){
                        setApprovalsLoading(false)
                         return toast.error(err.message, {position: toast.POSITION.TOP_RIGHT})
                     }
                   })  
            }else if(type==='personal'){
                  await fetch(`${url}/confirm-personal-top-up`,{
                    method:'post',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                 },body:JSON.stringify({
                    teller:currentPayment.id,cashier,payee,amount:parseInt(amount),type,paymentStatus:'success',nextWithdrawal:parseInt(nextWithdrawal)})
                   }).then(res=>res.json())
                   .then(resp=>{
                     if(resp.resp){
                 fetch(`${url}/${currentPayment.id}/re-confirm-manual`,{
                    method:'post',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    }
                 }).then(res=>res.json())
                 .then(resp=>{
                    if(resp.err){
                        setApprovalsLoading(false)
                        return toast.error(resp.err,{position:toast.POSITION.TOP_RIGHT})
                    }else if(resp.resp){
                        setApprovalsLoading(false)
                        toast.success(resp.resp,{position:toast.POSITION.TOP_RIGHT})
                        return setOpenManualPaymentModal(false)
                    }
                 }).catch(err=>toast.error(err.message,{position:toast.POSITION.TOP_RIGHT}))
                 
                     }else if(resp.err){
                    setApprovalsLoading(false)
                     return toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
                     }
                   }).catch(err=>{
                     if(err){
                         return toast.error(err.message, {position: toast.POSITION.TOP_RIGHT})
                     }
                   })
          }else if(type==='groove'){
          await fetch(`${url}/confirm-groove-top-up`,{
            method:'post',
            headers:{
             'Content-Type':'application/json',
             'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
          },body:JSON.stringify({
            teller:currentPayment.id,cashier,payee,amount:parseInt(amount),paymentStatus:'success',grooveId,type})
           }).then(res=>res.json())
           .then(resp=>{
             if(resp.resp){
                fetch(`${url}/${currentPayment.id}/re-confirm-manual`,{
                    method:'post',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    }
                 }).then(res=>res.json())
                 .then(resp=>{
                    if(resp.err){
                        setApprovalsLoading(false)
                        return toast.error(resp.err,{position:toast.POSITION.TOP_RIGHT})
                    }else if(resp.resp){
                        setApprovalsLoading(false)
                        toast.success(resp.resp,{position:toast.POSITION.TOP_RIGHT})
                        return setOpenManualPaymentModal(false)
                    }
                 }).catch(err=>toast.error(err.message,{position:toast.POSITION.TOP_RIGHT}))
                 
             }else if(resp.err){
                setApprovalsLoading(false)
             return toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
             }
           }).catch(err=>{
             if(err){
                 return toast.error(err.message, {position: toast.POSITION.TOP_RIGHT})
             }
           })
          }else if(type==='join-groove'){
                await fetch(`${url}/confirm-join-groove`,{
                method:'post',
                headers:{
                 'Content-Type':'application/json',
                 'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
             },body:JSON.stringify({
                teller:currentPayment.id,cashier,payee,amount:parseInt(amount),paymentStatus:'success',grooveId,type})
               }).then(res=>res.json())
               .then(resp=>{
                 if(resp.resp){
                 fetch(`${url}/${currentPayment.id}/re-confirm-manual`,{
                    method:'post',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    }
                 }).then(res=>res.json())
                 .then(resp=>{
                    if(resp.err){
                        setApprovalsLoading(false)
                        return toast.error(resp.err,{position:toast.POSITION.TOP_RIGHT})
                    }else if(resp.resp){
                        setApprovalsLoading(false)
                        toast.success(resp.resp,{position:toast.POSITION.TOP_RIGHT})
                        return setOpenManualPaymentModal(false)
                    }
                 }).catch(err=>toast.error(err.message,{position:toast.POSITION.TOP_RIGHT}))
                 
                 }else if(resp.err){
                setApprovalsLoading(false)
                 return toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
                 }
               }).catch(err=>{
                 if(err){
                     return toast.error(err.message, {position: toast.POSITION.TOP_RIGHT})
                 }
               })
            }
          }
          fetchData()
    }
const handleRemove =(e)=>{
    
    fetch(`${url}/${e.id}/remove-payment`,{
        method:'delete',
        headers:{
            'Content-Type':'application/json',
            'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
        }
     }).then(res=>res.json())
     .then(resp=>{
        if(resp.err){
            return toast.error(resp.err,{position:toast.POSITION.TOP_RIGHT})
        }else if(resp.resp){
            toast.success(resp.resp,{position:toast.POSITION.TOP_RIGHT})
        }
     }).catch(err=>toast.error(err.message,{position:toast.POSITION.TOP_RIGHT}))
     
}
const style = styles()
    const manualPayment = (e)=>{
        setOpenManualPaymentModal(true)
        setCurrentPayment(e)
    }
    
    const formatDate = Moment().format('DD-MM-YYYY')
    const[open,setOpen] = useState(false)
    const handleModal = (e)=>{
        setOpen(true)
        setDetail(e)
    }
    
    return (
        <Main> 
            <ToastContainer />
       {
            success !==''?<h4>{success}</h4>:errorMsg !==''?<h4>{errorMsg}</h4>:
            serverError !==''?<h4>{serverError}</h4>:<h4>{manualPayments?.length}&nbsp;{manualPayments?.length>1? 'payments':' user'} as of {formatDate}</h4>
        }
            <SubContainer>
          <DetailsContainer>
          {
            manualPayments&&<div className='display-container'>
            <GridOn onClick={()=>setDisplay(true)} className={true?'grid-view-active':'grid-view-in-active'} />
             <List onClick={()=>setDisplay(false)} className={false?'list-view-active':'list-view-in-active'} />
      </div>
          }
          <Modal open={openManualPaymentModal} className={style.modalDetail}>
                  {/* <div className={style.deleteModalCentent}> */}
                  <UserDetail>
                  <div className={style.deleteFirstCentent}>
                  <p style={{textAlign:'center'}}>The payment ID {currentPayment?.id} will be confirmed and can't be undone.</p>
                  <h6 style={{textAlign:'center'}}>Click 'Yes' to continue or 'No' to cancel the process.</h6>
                  <h5 style={{textAlign:'center'}}>Do you want to continue?</h5>
                  </div>
                  <div className={style.deleteModalBtnGroup}>
              <button className={style.noBtn} onClick={()=>setOpenManualPaymentModal(false)}>No</button>
              {isloading? <FaSpinner />:<button className={style.yesBtn} onClick={confirmManualPayment}>Yes</button>}
              </div>
                  </UserDetail>
                  {/* </div> */}
          </Modal>
         <div className={display?'detail-grid-view':'detail-list-view'}>
         {
              manualPayments?.length >=1? manualPayments?.map(item=>{
                  return (
                      <div key={item.id} className={display?'grid-user-item':'list-user-item'}>
                      {
                        manualPayments.length>0&&
                        <>
                        {
                            approvalsLoading?<FaSpinner />:
                            <button onClick={()=>manualPayment(item)} className='approval-btn'>Confirm Payment</button>
                        }
                        {
                            <button onClick={()=>handleRemove(item)} className='removal-btn'>Remove</button>
                        }
                        </>
                      }
                      <span>Amount: {item?.tools?.amount}</span>
                      <span>Paid on: {moment(item?.date).format('DD-MM-YYYY HH:MM:SS')}</span>
                      <span onClick={()=>handleModal(item)} style={{cursor:'pointer',fontSize:'25px'}}>...</span>
                      <Modal open={open} className={style.modalDetail}>
                        <UserDetail>
          <li><p>Payee:</p>{detail?.tools?.payee?.userName}</li>
          <li><p>Phone</p>{detail?.tools?.phone}</li>
          <li><p>Amount:</p> {detail?.tools?.amount}</li>
          <li><p>Paid on:</p>{moment(detail?.date).format('DD-MM-YYYY HH:MM:SS')}</li>
          <span><FaTimes onClick={()=>setOpen(false)} className='close' /></span>
      </UserDetail>
  </Modal>
                      </div>
                  )
              }):''
          }
         </div>
         </DetailsContainer>
            </SubContainer>
        </Main>
    );
};
const Main = styled.div`
padding:1rem;
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
margin:50px 10px;
background-color:var(--style-dark-bg);
width:90%;
.approval-btn{
    color:var(--style-bright);
padding:5px 15px;
    border-radius: 4px;
    border: none;
    background-color: var(--style-blue-dark);
    cursor: pointer;
    &:hover{
    color: var(--style-blue-cyan);
    transition: var(--transition);
    font-weight: 600;
    }
}
.removal-btn{
    background-color:var(--style-bright);
padding:5px 15px;
    border-radius: 4px;
    border: thin solid var(--style-error);
    color: var(--style-error);
    cursor: pointer;
    &:hover{
    transition: var(--transition);
    font-weight: 600;
    }
}
@media screen and (max-width:1000px){
    margin: 20px 10px;
    gap: 10px;
    padding: 5px 5px;
    margin-bottom: 30px;
}
h4{
    text-align: center;
    font-size: 16px;
    color: gray;
}
.grid-view-active{
    background: gray;
    cursor:pointer;
    color: black;
}
.grid-view-in-active{
    background: black;
    cursor:pointer;
    color: gray;
}
.list-view-active{
    background: black;
    cursor:pointer;
    color: gray;
}
.list-view-in-active{
    background: gray;
    cursor:pointer;
    color: black;
}
.display-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    padding: 10px 5px;
    box-shadow: 1px 1px 1px rgb(225, 225, 225);
    border-radius: 5px;
    margin: 30px 0;
}
`
const SubContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
width: 60%;
@media screen and (max-width: 1000px){
width:95%;
margin:auto;
}
`
const DetailsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .delete-user{
        background: transparent;
        cursor:pointer;
        &:hover{
            font-size: 26px;
            transition: all 3ms ease;
        }
    }
    .detail-grid-view{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        max-width: 100%;
        width:auto;
        gap: 40px;
    }
    .detail-list-view{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .grid-user-item{
    display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
border: 1px solid #333;
box-shadow:#333;
border-radius: 1rem;
gap: 1rem;
padding: 10px 20px;
min-height: 240px;
height: 100px;
width: 300px;
span{
    font-size: 16px;
    padding: 0;
    margin: 0;
}}
.list-user-item{
    display: flex;
justify-content: space-between;
align-items: center;
border: 1px solid #333;
box-shadow:#333;
border-radius: 1rem;
gap: 1rem;
padding: 1rem;
margin:1rem 0;
min-height: 80px;
width: 100%;
flex-wrap: wrap;
span{
    font-size: 16px;
    padding: 0;
    margin: 0;
}
@media screen and (max-width:1000px){
    width: 90%;
    border-radius: 5px;
    justify-content: space-evenly;
}
}
`
const ChartsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
`
const UserDetail = styled.div`
max-width:50%;
min-width:fit-content;
border:none;
outline: none;
padding: 1rem;
border-radius: 1rem;
margin:80px auto;
color: var(--style-grey);
background-color: var(--style-dark-bg);
position: relative;
li{
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.close{
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
}
`
export default UsersForCashier;