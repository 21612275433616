import { Button, ButtonGroup } from '@material-ui/core';
import { CameraEnhance } from '@material-ui/icons';
import axios from 'axios';
import React, { useState } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import CircularStatic from '../../../../imageUpload';
import { url } from '../../../../Redux/Api';
import { useNavigate } from 'react-router-dom';

const GrooveForm = () => {
    const[name, setName] = useState('')
    const[image, setImage] = useState('')
    const[description, setDescription] = useState('')
    const[duration, setDuration] = useState(0)
    const[requiredMembers, setRequiredMembers] = useState(0)
    const[target, setTarget] = useState(0)
    const[period, setPeriod] = useState(0)
    const[loading, setLoading] = useState(false)
    const[agree, setAgree] = useState(false)
    const[disagree, setDisagree] = useState(false)
    const[questionDisplay, setQuestionDisplay] = useState('')
    const[questionDisplayStyle, setQuestionDisplayStyle] = useState('')

    function questionHandler(e){
        if(e==='name'){
            setQuestionDisplayStyle('question-display-name')
            setQuestionDisplay('Give the groove a uniquie name! Note, while every other things about the groove can be changed as the orbit ends, the groove\'s name remains the same.')
        }else if(e==='description'){
            setQuestionDisplayStyle('question-display-description')
            setQuestionDisplay('Explain to fuure members what the groove is about so they can decide whether or not to join.')
        }else if(e==='target'){
            setQuestionDisplayStyle('question-display-target')
            setQuestionDisplay('Set a target for the groove. The amount you want each grove member to receive after the duration.')
        }else if(e==='duration'){
            setQuestionDisplayStyle('question-display-duration')
            setQuestionDisplay('Specify the time the groove should start paying its members. Note, the site adds 30 days to any time specified here. If you want to use the site\'s default duration, then leave it blank.')
        }else if(e==='period'){
            setQuestionDisplayStyle('question-display-period')
            setQuestionDisplay('Specify the interval between each pay time. If you want members to make payments daily, weekly, monthly etc.')
        }else if(e==='requiredMembers'){
            setQuestionDisplayStyle('question-display-requiredMembers')
            setQuestionDisplay('Specify how many members you want the groove to have. Each member will contribute the same amount.')
        }else{
            setQuestionDisplay('')
            setQuestionDisplayStyle('')
        }
    }

    const[goodImage, setGoodImage] = useState(false)
    const[imagePreview,setImagePreview] = useState('')
  const[grooveImageError, setGrooveImageError] = useState('')
  const[grooveImageSuccess, setGrooveImageSuccess] = useState('')
  
  const user = useSelector(state=>state.auth.user)

const handleAgree = ()=>{
    setDisagree(false)
    setAgree(true)
}
const handleDisagree = ()=>{
    setDisagree(true)
    setAgree(false)
}

const uploadHandler = async(e)=>{
    e.preventDefault()
  const fileData = e.target.files[0]
  const base64 = await Converter(fileData)
  setImagePreview(base64)

  if(fileData){
   if(fileData.size>2000000){
    setGrooveImageError(`Sorry, ${fileData.size}kb is high. Max file file size is 2MB`)
   }else{
    setGrooveImageError('')
    const extensions = ['image/png','image/jpg','image/jpeg','image/ico','image/jfif','image/gif']
    const extension = ['png','jpg','jpeg','ico','jfif','gif']
    
    const checkExtension = extensions.indexOf(fileData.type)
   if(checkExtension<0){
    setGrooveImageError(`Unsupported file. Try any of these ${extension}.`)
   }else{
    setGrooveImageError('')
    setGoodImage(true)
   }
   }
}
}

const Converter = (e)=>{
    return new Promise((resolve,reject)=>{
      const reader = new FileReader()
      reader.readAsDataURL(e)
      reader.onload = ()=>{
        resolve(reader.result)
        setImage(reader.result)
        setImagePreview(reader.result)
      }
      reader.onerror = (error)=>{
        reject(error)
      }
    })
  }
const app = useSelector(state=>state.apps.items)
const navigate = useNavigate()
  const handleSubmit = (e)=>{
    e.preventDefault()
    setLoading(true)
    async function fetchData(){
        const baseUrl = `${url}/create-groove`
        const data = {name,image,description,duration:duration*24,requiredMembers,target,period:period*24}
        const config = {
          headers:{
            'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
          }
        }
        await axios.post(baseUrl,data,config)
        // .then(res=>res.json())
        .then(resp=>{
            if(resp.data.resp){
                setGrooveImageSuccess(resp.data.resp)
                toast.success(resp.data.resp, {position: toast.POSITION.TOP_RIGHT})
                return navigate(`/${user?._id}/${user.email}/${target}/${user._id}/${user.phone}/${app?.currency}/create-groove/1/${requiredMembers}/${description}/${name}/payment`)
              setLoading(false)
            }else if(resp.data.err){
                setLoading(false)
                setGrooveImageError(resp.data.err)
                toast.error(resp.data.err, {position: toast.POSITION.TOP_RIGHT})
            }
        }).catch(err=>{
            if(err){
              setLoading(false)
              setGrooveImageError(err.message)
              toast.error(err.message, {position: toast.POSITION.TOP_RIGHT})
            }
        })
      }
      fetchData()
    }       
    return (
        <Main>
            <ToastContainer />
            <h3 className='groove-form-title'>Create Groove </h3>
            <form  className='groove-form' onSubmit={handleSubmit} onMouseLeave={()=>{setQuestionDisplay('');setQuestionDisplayStyle('')}}
            encType='multipart/form-data'>
                <label className='file-wrapper-groove-form' for='groove-form-file-input'>
                  <CameraEnhance className='image-change-icon-groove-form'/>
                  <input type='file' id='groove-form-file-input' className='file-input' 
                  accept='image/png, image/jpg, image/gif, image/jpeg' 
                  onChange={uploadHandler} 
                />
                </label>
            {imagePreview!==''&&<img src={imagePreview} alt='' className='image-preview' />}
                <div className='input-wrapper'><span className={questionDisplayStyle==='question-display-name'?'active-display':'display-span'} onMouseEnter={()=>questionHandler('name')}><FaQuestionCircle className='question-display-icon' /></span><input type='text' required placeholder='Groove Name'  className='groove-form-input' onChange={(e)=>setName(e.target.value)}  /></div>
                <div className='input-wrapper'><span className={questionDisplayStyle==='question-display-description'?'active-display':'display-span'} onMouseEnter={()=>questionHandler('description')}><FaQuestionCircle className='question-display-icon' /></span><input type='text' required placeholder='Groove description'  className='groove-form-input' onChange={(e)=>setDescription(e.target.value)}  /></div>
                <div className='input-wrapper'><span className={questionDisplayStyle==='question-display-target'?'active-display':'display-span'} onMouseEnter={()=>questionHandler('target')}><FaQuestionCircle className='question-display-icon' /></span><input type='number' step='any' min={app?.grooveMin} required placeholder='Target'  className='groove-form-input' onChange={(e)=>setTarget(e.target.value)}  /></div>
                <div className='input-wrapper'><span className={questionDisplayStyle==='question-display-duration'?'active-display':'display-span'} onMouseEnter={()=>questionHandler('duration')}><FaQuestionCircle className='question-display-icon' /></span><input type='number' step='1' min='0' required placeholder='Duration'  className='groove-form-input' onChange={(e)=>setDuration(e.target.value)}  /></div>
                <div className='input-wrapper'><span className={questionDisplayStyle==='question-display-period'?'active-display':'display-span'} onMouseEnter={()=>questionHandler('period')}><FaQuestionCircle className='question-display-icon' /></span><input type='number' step='1' min='0' required placeholder='Period'  className='groove-form-input' onChange={(e)=>setPeriod(e.target.value)}  /></div>
                <div className='input-wrapper'><span className={questionDisplayStyle==='question-display-requiredMembers'?'active-display':'display-span'} onMouseEnter={()=>questionHandler('requiredMembers')}><FaQuestionCircle className='question-display-icon' /></span><input type='number' step='1' min='1' required placeholder='requiredMembers'  className='groove-form-input' onChange={(e)=>setRequiredMembers(e.target.value)}  /></div>
                {
                    questionDisplay===''?<p className='groove-form-terms'>Please kindly comply to our <a style={{color:'var(--style-blue)'}} href ='https://terms.cashorbits.com' target='_blank' rel='noreferrer' className='link'>Terms and conditions</a></p>:<p className='question-display'>
                    {questionDisplay}
                </p>
                }
               <div className='btn-group'>
               {
                    agree===false&&<button onClick={handleAgree} className='agree-btn'>Agree</button>
                }
                {
                    disagree===false&&<button onClick={handleDisagree} className='disagree-btn'>Disagree</button>
                }
                {
                    loading===true?<CircularStatic />:
                    <>{
                        agree===true?<button type='submit' className='submit-btn'>Create</button>:null
                    }
                    </>
                }
               </div>
            </form>
        </Main>
    );
};

const Main =styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding:2px 15px;
background-color: var(--style-white);
.groove-form-title{
    font-size: 20px;
    font-weight: bold;
    color: var(--style-blue-dark);
    padding: 10px;
}
.response-message{
    color: var(--style-bright);
    background-color: green;
    font-weight: bold;
    font-family: var(--font-family);
    padding: 10px 50px;
    border-radius: 5px;
}
.error-message{
    color: var(--style-bright);
    background-color: red;
    font-weight: bold;
    font-family: var(--font-family);
    padding: 10px 50px;
    border-radius: 5px;
}
.groove-form-terms{
    color: var(--style-grey);
    padding: 10px;
    font-family: var(--font-family);
    font-weight: lighter;
    text-align: center;
    font-size:14px;
}
.link{
    color: var(--gold-color);
}
.image-preview{
    width: 100%;
    height: 300px;
    border-radius: 5px;
}
.file-wrapper-groove-form{
    position: relative;
}
.image-change-icon-groove-form{
    color: var(--style-grey);
    font-size: 24px;
    cursor: pointer;
}
.groove-form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.question-display{
    font-size: 12px;
    text-align: justify;
    color:var(--style-grey);
    padding: 10px;
}
.input-wrapper{
    display:flex;
    justify-content: center;
    width:100%;
    align-items: center;
}

.display-span{
    color:var(--style-blue-dark);
    font-size: 10px;
    transform: scale(1);
}
.active-display{
    color:var(--style-bright);
    font-size: 10px;
    transform: scale(1);
}
.active-display:hover{
    transition: var(--transition);
    transform: scale(1.1);
}
.question-display-icon{
font-size: 15px !important;
cursor: pointer;
}
.groove-form-input{
    height: 35px;
    width: 90%;
    border: none;
    color: gray;
    background-color: var(--style-bright);
    margin: 5px;
    border-radius: 5px;
    padding: 10px;
}
.groove-form-input:focus{
    outline: none;
    background-color: var(--style-white);
    color: green;
}
.btn-group{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width:50%;
}
.agree-btn{
    padding: 5px 35px;
    border:thin solid var(--style-blue-dark);
    color: var(--style-blue-dark);
    background-color: var(--style-white);
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
    font-size:12px;
}
.disagree-btn{
    padding: 5px 35px;
    border:thin solid var(--style-error);
    color: darkred;
    background-color: var(--style-white);
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
    font-size:12px;
}
.submit-btn{
    padding: 5px 35px;
    border:thin solid var(--style-blue-dark);
    color: var(--style-blue-dark);
    background-color: var(--style-white);
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
    font-size:12px;
}
.submit-btn:hover{
    transition: all 0.3s ease;
}
`

export default GrooveForm;