import React, {useState} from 'react';
import copy from "copy-to-clipboard";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import bgImg from '../assets/background.avif';
import growth from '../assets/growth.gif';
import groove from '../assets/groove.gif';
import { Spinner } from '../../Loading';
// import testimonial from '../assets/testimonials.mkv';
import move from '../assets/move.gif';
import referImg from '../assets/bg.png';
import referBg from '../assets/refer.jpg';
import { MonetizationOn, Share } from "@material-ui/icons";
import { FaClock, FaCopy, FaHandsHelping, FaTimes } from "react-icons/fa";
import { Modal, makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clientUrl } from '../../Redux/Api';


const styles = makeStyles(prop=>({
    modal:{
        width:'60%',
        height:'150px !important',
        margin:'110px auto',
        color:'#333',
        [prop.breakpoints.down('md')]:{
            width:'85%',
            margin:'100px auto'
        }
    },
    modalContent:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'baseline',
        borderRadius:'5px',
        height:'150px !important',
        padding:'1.5rem',
        position:'relative',
    },
    refItems:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        flexDirection:'column',
        gap:'15px',
        flexBasis:'90%',
        padding:'15px',
        height:'150px !important',
        background:'var(--style-white)'
    },
    refOpts:{
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        flexWrap:'wrap',
        gap:'30px',
        width:'100%'
    },
    refLinks:{
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        width:'100%'
    },
    refLink:{
fontSize:'15px',
fontWeight:'bold',
textAlign:'center',
color:[prop.palette.primary.main]
},
refOpt:{
    fontSize:'14.5px',
    fontWeight:'lighter',
    cursor:'pointer',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    '&:hover':{
        transition:'ease-in 3ms',
        fontSize:'15px'
    }
},
icon:{
    fontSize:'24px',
    cursor:'pointer'
},
cancelWrapper:{
    flexBasis:'10%',
    position:'absolute',
    right:'20px',
    top:'20px',
},
cancel:{
    fontSize:'30px',    
    borderRadius:'50%',
    padding:'6px',
    cursor:'pointer',
    'hover':{
        color:'#fff'
    }
}
}))

function Home() {
    const style = styles()
    const[open, setOpen] = useState(false)
    const user = useSelector(state=>state.auth.user)
    const app = useSelector(state=>state.apps.items)
    const navigate = useNavigate()
    function handleCopy(){
        setOpen(false)
        copy(`${clientUrl}/${user?.userName}/register`)
      toast.success('Referral link copied!.', {position: toast.POSITION.TOP_RIGHT})
      return navigate(-1)
    }
 return (
    <Main >
             <ToastContainer />
        <Header img={bgImg}>
        <div className="header-container"><h1 className="bold-title">{app?.name?app.name:<Spinner />}</h1>
            <h6 className="light-title" >Spend it! Save it!</h6>
            <p className="text">Should you not spend because you want to save? Should you not save because you want to spend? Cash orbits helps you maintain a balance, save and spend without feeling it. Invest and withdraw every {app?.minTime} days.
            </p>
            <p className="text-second">Save up and get that car or house! Spend while doing so simply by moving your initial cash in orbits and let {app?.name} do the rest!</p>
            <Link to ={`/${user?._id}/dashboard`} className="button">Get started</Link>
            {/* <img src={bg} alt='' className="header-img" /> */}
            </div>
        </Header>
        {/* <div className='divider'></div> */}
        <div className="refer">
            <div className='refer-left'>
                <img src={referImg} alt='' className="refer-left-image" />
            </div>
             <div className='refer-right'>
                <h1 className="refer-title">Purely Investment</h1>
                <p className="refer-body">Please note, we don't pay you for referring people and you don't need referrals to earn. This is purely investment and you earn interest on the capital invested.</p>
                <Link to ={`/${user?._id}/dashboard`} className="link">Invest Now</Link>
             </div>
             </div>
             <div className="growth">
            <div className='refer-left'>
                <img src={growth} alt='' className="refer-left-image" />
            </div>
             <div className='refer-right'>
                <h1 className="refer-title">Grow It</h1>
                <p className="refer-body">The platform supports your investment's growth. You can invest and watch it grow! You can now fix your investment to a date convenient to you. </p>
                <Link to ={`/${user?._id}/dashboard`} className="link">Fix Invest Now</Link>
             </div>
             </div>
             

             {/* <div className="growth">
             <div className='refer-left'>
                <video src={testimonial} controls />
            </div>
             </div> */}

             <div className="refer">
            <div className='refer-left'>
                <img src={referBg} alt='' className="refer-left-image" />
            </div>
             <div className='refer-right'>
                <h1 className="refer-title">Be our worker</h1>
                <p className="refer-body">You can earn {app?.currency}{app?.baseSalary} as monthly base salary and more from allowances. You can work from home simply by posting our ads on your social platforms or by telling friends and families to join.</p>
                {
                    !user?<Link to='#' onClick={navigate('/login')} className="link">Get to work</Link>:
                    <Link to='#' onClick={()=>setOpen(true)} className="link">Get to work</Link>
                }
             </div>
              <Modal open={open} className={style.modal}>
<div classNclassNameame={style.modalContent}>
    <div className={style.refItems}>
<p className={style.refLink}>My Referral link</p>
<div className={style.refOpts}>
            <li className={style.refOpt}>{`${clientUrl}/${user?.userName}/register`}</li>
            <div className={style.refLinks}>
            <FaCopy className={style.icon} onClick={handleCopy} /><a href={`https://wa.me/?text=cash orbits - spend it save it ${clientUrl}/${user?.userName}/register`} rel='noreferrer' target="_blank">
        <Share className={style.icon} /></a> 
            </div>
        </div>
    </div>
    <div className={style.cancelWrapper}>
    <FaTimes onClick={()=>{setOpen(false);return navigate(-1)}} className={style.cancel} />
    </div>
    
    
</div>
             </Modal>
             </div>
            
             <div className="growth">
            <div className='refer-left'>
                <img src={move} alt='' className="refer-left-image" />
            </div>
             <div className='refer-right'>
                <h1 className="refer-title">Move It</h1>
                <p className="refer-body">Money kept at a spot does no work hence earns no interest! To make more money, simply move more money! Invest, withdraw, re-invest and make more!</p>
                <Link to ={`/${user?._id}/dashboard`} className="link">Invest Now</Link>
             </div>
             </div>
             <div className="refer">
            <div className='refer-left'>
                <img src={groove} alt='' className="refer-left-image" />
            </div>
             <div className='refer-right'>
                <h1 className="refer-title">Groove Move</h1>
                <p className="refer-body">If you have large budget and don't have enough money, you can adopt the groove! This is a contribution that pays members interest and ensures all members pay and  get paid with an interest. Each member of the groove can withdraw the whole profit realized.</p>
                <Link to ={`/${user?._id}/profile`} className="link">Create A Grove</Link>
             </div>
             </div>
        <div className="information">
            <div className='information-left'>
                
                    <MonetizationOn className='information-icon' />
                <h1 className='information-title'>Earn interest on it</h1>
                <p className='information-body'>
                Instead of keeping it at the bank where no interest is made, invest it and earn big while you focus on your work.
                </p>
            </div>
             <div className='information-middle'>
                    <FaHandsHelping className='information-icon' />
                
                <h1 className='information-title'>Work with us</h1>
                <p className='information-body'>This platform is aimed at fixing finacial problems. Join other employees at cash orbits and work! You can be earning a wooping {app?.currency}{app?.baseSalary} working from home using your spare time. You don't need a laptop nor big phone to start working with us. Contact {app?.help} to know how to apply.
                </p></div>
             <div className='information-right'>
                    <FaClock className='information-icon' />
                
                <h1 className='information-title'>Early Payment</h1>
                <p className='information-body'>
                    You don't have to wait too long to get your business started. You can withdraw and start that project within {app?.minTime*24} hours after payment.
                    </p></div>
        </div>
    </Main>
  );
}
const Main = styled.div`
    background-color:var(--style-bright);
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    width:100%;
    gap:30px;
    height: auto; 
    position:relative;
    margin: auto;
    h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.button{
    font-size: 14.5px;
    font-weight: 600;
    padding: 0.6rem 1.2rem;
    border-radius: 4px;
    display: inline-block;
    background-color: var(--style-blue-dark);
    color:var(--style-blue-cyan);
    text-transform:capitalize;
    border:thin solid var(--style-white);
    }
    .button:hover{
    transition: var(--transition);
    background-color: var(--style-bright);
    color:var(--style-blue-dark);
    font-weight: 200;
    }   
.link{
    font-size: 14.5px;
    /* font-weight: 600; */
    padding: 0.6rem 1.2rem;
    border-radius: 4px;
    display: inline-block;
    background-color: var(--style-white);
    color:var(--style-blue-dark);
    border:thin solid var(--style-grey);
    text-transform:capitalize;
    }
    .link:hover{
    transition: var(--transition);
    background-color: var(--style-bright);
    }
      .refer{
        background-color:var(--style-white);
    width:70%;
    margin: auto;
        padding:10px 60px;
        display:flex;
        justify-content:space-around ;
        align-items: center;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        @media screen and (max-width:650px){
        padding: 10px;
        width:100%;
        flex-direction: column;
    }
    }
    .refer-left{
        padding :20px;
        display:flex;
        /* flex-basis: 60%; */
    }
    .refer-left-image{
        padding :20px;
        width:70%;
        @media screen and (max-width:650px){
        padding: 10px;
        width:100%;
    }
    }
    .refer-right{
        padding :20px;
        display:flex;
        justify-content:space-around;
        align-items: center;
        flex-direction:column;
        flex-basis: 40%;
        @media screen and (max-width:650px){
        padding: 10px;
        width:100%;
        flex-basis: 100%;
    }
    }
    .refer-title{
        padding :10px;
        text-align:center;
        font-weight:600;
        font-size: 20px;
        color: var(--style-blue-dark);
        }
        .refer-body{
        padding:20px;
        font-size: 14.5px;
        text-align:center;
        line-height: 18px;
        color:var(--style-grey);
        }
        .growth{
        background-color:var(--style-white);
        padding :20px;
        display:flex;
    width:70%;
    margin: auto;
        justify-content:center;
        align-items: center;
        flex-wrap: wrap;
        @media screen and (max-width:650px){
        padding: 10px;
        width:100%;
        flex-direction: column;
    }
    }
    .information{
        width:100%; 
        padding :20px;
        gap:20px;
        display:flex;
        justify-content:center;
        align-items: baseline;
        flex-wrap: wrap;
    }
    .information-left,.information-middle,.information-right{
        background-color:var(--style-bright);
        flex-basis:30%; 
        min-height:3cap;
        padding :20px;
        display:flex;
        justify-content:center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        @media screen and (max-width:650px){
        padding: 10px;
        width:100%;
        flex-basis: 100%;
    }
    }
    .information-icon{
        font-size:60px;
        box-shadow:4px 2px 1px var(--style-blue-dark);
        color: var(--style-grey);
        background:none;
        padding:5px 6px;
        border-radius: 4px;
    }
    .information-title{
        padding :5px;
        text-align:left;
        font-weight:600;
        font-size:20px;
        color: var(--style-blue-dark);
    }
    .information-body{
        padding :20px;
        font-size: 16px;
        text-align:left;
        color:var(--style-dark);
        font-weight: lighter;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        @media screen and (max-width:650px){
        padding: 10px;
        width:100%;
        text-align: justify;
    }
    }
    .divider{
        border-top-left-radius:100px;
        border-top-right-radius:100px;
        width:100%;
        position:absolute;
        height:var(--divider-height);
        top:calc(var(--header-height) - (0.7*var(--divider-height)));
        background :var(--style-bright);
    }
`
const Header = styled.div`
        /* background-image: url(${props=>props.img});
        background-repeat: no-repeat;
        background-size: cover; */
        background-color: var(--style-blue-dark);
        width:100%;
        height: var(--header-height);
        padding:9rem 3rem;
        @media screen and (max-width:650px){
        padding: 4rem 30px;
    }
        .light-title{
        text-shadow:2px 1px 2px var(--style-blue-cyan);
        color: var(--style-bright);
        font-size: 30px;
        font-weight: 600;
    }
    .bold-title,h6{
        text-align:center;
    }
    .bold-title {
    /* margin: 1.4rem 0; */
    font-size: 25px;
    /* line-height: 1.4; */
    text-transform: capitalize;
    color: var(--style-blue-cyan);
    text-align:center;
}
.text,.text-second{
    text-align: justify;
    font-size:16px;
    color: var(--style-light-gray);
}
    .header-container{
        display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    margin:auto;
    gap:20px;
    width:60%;
    @media screen and (max-width:650px){
        width:100%;
    }
    }
    
    .header-img{
        width:100%;
    }
   
`
export default Home;
