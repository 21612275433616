import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { url } from '../../../Redux/Api';

const NewPassword = () => {
    const[sent, setSent] = useState(false)
    const[success, setSuccess] = useState('')
    const[password, setPassword] = useState('')
    const[passwordConfirmation, setPasswordConfirmation] = useState('')
    const[tokenValue, setTokenValue] = useState('')
    const[mailError, setMailError] = useState('')
    const[isLoading, setIsLoading] = useState(false)
    const[mail, setMail] = useState('')
    const {token,email} = useParams()

    const navigate = useNavigate()
    const passwordToken = ()=>{
        setTokenValue(Math.floor(Math.random() * 300))
    }
    
const handleSubmit = (e)=>{
    e.preventDefault()
    setSent(true)
    passwordToken()
    setIsLoading(true)
    async function fetchData(){
await fetch(`${url}/create-new-password`,{
    method:'put',
    headers:{
        'Content-Type':'application/json'
    },
    body:JSON.stringify({
        email,
        token,
        password
    })
}).then(res=>res.json())
.then(resp=>{
    if(resp.response){
        setTokenValue(token)
        setSuccess(resp.response)
        setPasswordConfirmation('')
        setPassword('')
        setIsLoading(false)
        navigate('/login')
    }if(resp.err){
        setMailError(resp.err)
        setPasswordConfirmation('')
        setPassword('')
        setIsLoading(false)
    }
}).catch(err=>{
    setIsLoading(false)
    setPasswordConfirmation('')
        setPassword('')
    setMailError(err.message)
})
    }
    fetchData()
}

    return (
        <Main>
            <SendMail>
                <form onSubmit={handleSubmit}>
                    {
                        password.length<8?<p>Password should be minmum 8 characters.</p>:
                        password!==passwordConfirmation?<p style={{color:'red'}}>Passwords don't match.</p>:
                        success!==''?<p>{success}</p>:mailError!==''?<p>{mailError}</p>:
                        <p>Create new password</p>
                    }
                    <div>
                    <input type='email' placeholder='Enter your email' value={email} disabled />
                    <input type='password' placeholder='Enter new password' value={password} onChange={(e)=>setPassword(e.target.value)} />
                    <input type='password' placeholder='Re-enter password' value={passwordConfirmation} onChange={(e)=>setPasswordConfirmation(e.target.value)} />
                    
                    {
                        email===''||password===''||passwordConfirmation===''?null:
                                isLoading?<FaSpinner />:<button type='submit' className='create-new-password-btn'>Create</button>
                    }
                    </div>
                </form>
            </SendMail>
        </Main>
    );
};

const Main = styled.div`
height: 100vh;
width:100%;
padding: 200px;
background-color: var(--bg-primary-color);
`
const SendMail = styled.div`
padding: 20px;
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
width: 100%;
form{
    display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
width: 70%;
background-color: #fff;
height: 40vh;
padding: 20px;
}
div{
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap:10px;
    background-color: #fff;
    padding: 10px;
    @media screen and (max-width:650px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    }
}
div input{
width: 100%;
border-radius: 4px;
border: 1px solid gray;
background-color: #eee;
padding: 10px 5px;
text-align: center;
font-size: 12px;
@media screen and (max-width:950px){
    width: 100%;
    min-height: 40px;
}
}
div input:focus{
outline: none;
background-color: white;
}
.create-new-password-btn{
    padding: 5px 30px !important;
}
`

export default NewPassword;