import React, { useState } from 'react';
import { useDispatch} from 'react-redux';
import styled from 'styled-components';
import BankComponent from './BankComponent';
import PersonalComponent from './PersonalComponent';

const PersonalSettings = () => {
const personalComponent = <PersonalComponent />
    const bankComponent =  <BankComponent />
const [personalRender,setPersonalRender] = useState(personalComponent)
    const[currentLink, setCurrentLink] = useState('personal')
    
    const personalComponentHandler = ()=>{
        setCurrentLink('personal')
        setPersonalRender(personalComponent)
    }
    const bankComponentHandler = ()=>{
        setCurrentLink('bank')
        setPersonalRender(bankComponent)
    }
    
    return (
        <Main>
        <h3 className='personal-settings-title'>Settings</h3> 
        <ul className='settings-control-pane'>
        <li className={currentLink==='personal'?'settings-control-item-active':'settings-control-item'} onClick={personalComponentHandler}>Personal Details</li>
        <li className={currentLink==='bank'?'settings-control-item-active':'settings-control-item'}onClick={bankComponentHandler}>Bank Details</li>
        </ul>   
        {personalRender}
        </Main>
    );
};

const Main = styled.div`
.personal-settings-title{
    text-align: center;
    color: gray;
    padding-bottom: 15px;
    font-size: 16px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.settings-control-pane{
display: flex;
justify-content: center;
align-items: center;
background-color: var(--style-blue-cyan);
padding-top: 20px;
border-top-right-radius: 20px;
border-top-left-radius: 20px;
}
@media screen and (max-width:500px){
    .settings-control-pane{
    margin: 1px 1px;
}
}
.settings-control-item{
    padding: 10px 10px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    color: var(--style-white);
    text-align: center;
    font-size: 16px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    cursor: pointer;
    list-style: none;
}
@media screen and (max-width: 500px){
    .settings-control-item{
    padding: 10px;
    font-size: 10px;
}   
}
.settings-control-item-active{
    background-color: var(--style-white);
    transition: all 0.4s ease;
    color: var(--style-blue-dark);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding: 10px 10px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    list-style: none;
}
@media screen and (max-width:500px){
    .settings-control-item-active{
        padding: 10px;
    font-size: 10px;
    text-align: center;
    }
}
.settings-control-item:hover{
    background-color: var(--style-white);
    transition: all 0.4s ease;
    border: none;
    color: #000;
}
.displayed-shops{
    height: 300px;
    min-width: 100%;
    overflow-y: auto;
    padding: 20px 10px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    background-color: var(--style-blue-cyan);
}
.displayed-shop{
    background-color: var(--style-white);
    height: 150px;
    width: 200px;
    padding: 20px 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
.displayed-shop h4, p{
    text-align: center;
}
.displayed-shop p{
    color: gray;
}
.active-shop-btn{
background-color: gray;
color: var(--style-white);
}
.shop-btn{
background-color: var(----style-blue-cyan);
}
.save-changes{
    padding: 10px 20px;
}
`
export default PersonalSettings;