import styled from 'styled-components'
import React,{useEffect,useState} from 'react';
import { url } from '../../Redux/Api';

const Chat = (props)=>{
    const chats = [{type:'in',text:'text 1',time:'2023-11-23 6:58'},{type:'out',text:'text 2',time:'2023-11-23 6:59'}]
   
    return (
        <Main>
            {
                chats.map((item,index)=>{
                    return <div className={item.type==='in'?'in':'out'}>
                        <div className='items'>
                        <p>{item.text}</p>
                        <p>{item.time}</p>
                        </div>
                    </div>
                })
            }
            <div className='in'>In</div>
            <div className='out'>Out</div>
        </Main>
    );
}

const Main = styled.div`
min-height:70vh;
/* display:flex;
justify-content:space-between;
align-items:center;
flex-direction:column; */
width:90%;
margin:auto;
background:var(--style-bright);
.items{

}
.out{
/* flex-basis:45%; */
padding:20px;
width:50%;
border-top-right-radius: 10px;
background:var(--style-white);
float:right;
}
.in{
/* flex-basis:45%; */
padding:20px;
width:50%;
border-top-left-radius: 10px;
background:var(--style-white);
float:left;
}
`
export default Chat;