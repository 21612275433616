import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import styled from 'styled-components';
import { url } from '../../../Redux/Api';

const PasswordReset = () => {
    const[sent, setSent] = useState(false)
    const[email, setEmail] = useState('')
    const[tokenValue, setTokenValue] = useState('')
    const[mail, setMail] = useState('')
    const[mailError, setMailError] = useState('')
    const[isLoading, setIsLoading] = useState(false)

    const token = ()=>{
        setTokenValue(setTimeout(()=>{
            Math.floor(Math.random() * 300)
        },3000))
    }
    
const handleSubmit = async (e)=>{
    e.preventDefault()
    setSent(true)
    token()
    setIsLoading(true)
   await fetch(`${url}/password-reset`,{
        method:'post',
        headers:{
            'Content-Type':'application/json'
        },
        body:JSON.stringify({
            email
        })
    }).then(res=>res.json())
    .then((resp)=>{
        if(resp.mailData){
            setMail(resp.mailData)
            setIsLoading(false)
        }else if(resp.err){
            setMailError(resp.err)
            setIsLoading(false)
        }
    }).catch(err=>{
        setMailError(err.message)
        setIsLoading(false)
    })
}

    return (
        <Main>
            {/* {
                sent===true?null:<h3>Reset Password</h3>
            } */}
            {/* {
                sent===true?null:<p>We'll send a link to the email provided below</p>
            } */}
     {
         
            mail === ''?
            <SendMail>
                <form onSubmit={handleSubmit}>
                    {
                        mail!==''?<p className='mail-sent'>A link was sent to {email}</p>:mailError!==''?<p className='mail-error'>{mailError}</p>:<p className='password-reset'>We'll send a link to the email provided below</p>
                    }
                  <div>
                  <input type='email' placeholder='Enter your email...' value={email} onChange={(e)=>setEmail(e.target.value)} />
                    
                    {
                        isLoading===true?<FaSpinner />:
                        <>
                        {
                            email===''?null:<div id='button-wrapper'>
                            <button type='submit' className='button'>Continue</button>
                        </div>
                        }
                        </>
                    }
                    
                  </div>
                </form>
            </SendMail>:<>
            {
                mailError===''||undefined?<p>To continue, click on the link sent to {email}.</p>:<p>{mailError}.</p>
            }
            </>
     }
        </Main>
    );
};

const Main = styled.div`
height: 70vh;
margin: 100px;
padding: 100px 20px;
@media screen and (max-width:950px){
margin: 10px;
padding: 10px 10px;
height: 100vh;
}
h3{
    padding-top: 50px;
}
p{
    padding:50px;
}
@media screen and (max-width: 400px) {
 p{
    font-size: 12px;
    font-weight: lighter;
 }   
}
`
const SendMail = styled.div`
padding: 20px;
.mail-sent{
    color: green;
    text-align:center;
}
.mail-error{
    color: red;
    text-align:center;
}
.password-reset{
    text-align:center;
}
div{
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--style-bright);
    padding: 30px 10px;
    @media screen and (max-width:650px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    }
}
div input{
flex-basis: 70% !important;
border: none !important;
height: 50px !important;
background: var(--style-bright);
padding: 0 5px;
@media screen and (max-width:950px){
    width: 100%;
    min-height: 40px;
}
}
div input:focus{
outline: none;
/* background: var(--style-blue-dark); */
color: var(--style-grey);
}
div #button-wrapper{
flex-basis: 30% !important;
border-radius: 30px;
background: var(--style-blue-dark);
height: 50px;
position: relative;
margin-right: 20px;
@media screen and (max-width:950px){
    flex-basis: 100% !important;
    min-height: 50px;
    }
    @media screen and (max-width:250px){
    flex-basis: 100% !important;
    min-height: 35px;
    min-width: fit-content;
    }
.button{
    border-radius: 30px;
    height: 50px !important;
    width: 60%;
    position: absolute;
    left: 0;
    cursor: pointer;
    @media screen and (max-width:650px){
    border-radius: 30px;
    height: 50px !important;
    width: 60%;
    position: absolute !important;
    left: 0;
    }
    @media screen and (max-width:250px){
    flex-basis: 100% !important;
    max-height: 35px;
    min-width: fit-content;
    position: relative;
    left: auto;
    }
}
}
`
export default PasswordReset;