import React from 'react';
import styled from 'styled-components';

const PageNotFound = () => {
    return (
        <Main>
            <h2>Oops!</h2>
           <div>
           <h1>404</h1>
           <em><p>Page not found.</p></em>
           </div>
        </Main>
    );
};
const Main = styled.div`
margin: 100px 30px;
padding: 50px;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
box-shadow: 1px 1px 1px rgb(225, 225, 225);
background-color: #eee;
h2{
    font-size: 20px;
    color: red;
    font-weight: bold;
    padding: 5px;
}
div{
    display: flex;
    justify-content: center;
    align-items: center;
}
div p{
    font-size: 20px;
    color: green;
    padding: 5px;
}
div h1{
    font-size: 30px;
    color: #000;
    font-weight: bolder;
    padding: 5px;
}
`
export default PageNotFound;