import React, { useState } from 'react';
import {FaSpinner } from 'react-icons/fa';
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import { FaqContent } from '../JsonContent';
import { url } from '../../../Redux/Api';

const Faq = () => {
    const[email, setEmail] = useState('')
    const[subscriptionSuccess, setSubscriptionSuccess] = useState('')
    const[subscriptionError, setSubscriptionError] = useState('')
    const[subscriptionLoading, setSubscriptionLoading] = useState(false)

const app = useSelector(state=>state.apps.items)
const handleSubmit = e=>{
    e.preventDefault()
    setSubscriptionLoading(true)
    async function fetchData(){
await fetch(`${url}/newsletter-subsrciption`,{
    method:'post',
    headers:{
        'Content-Type':'application/json',
        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
    },
    body:JSON.stringify({
        email,appId:app?._id
    })
}).then(res=>res.json())
.then(resp=>{
    if(resp.response){
        setSubscriptionSuccess(resp.response)
        setSubscriptionLoading(false)
    }else if (resp.err){
        setSubscriptionError(resp.err)
        setSubscriptionLoading(false)
    }
}).catch(err=>{
    if(err){
        setSubscriptionError(err.message)
        setSubscriptionLoading(false)
    }
})
    }
    fetchData()
}
    
    return (
        <Main>
                <div  className='faq-left'>
                    <h5 className='faq-left-section-title'>Frequently Asked Questions</h5>
                    {
                        FaqContent.map((item,index)=>{
                            return(<div key={index} className='faq-left-content-items'>
                                <h6 className='faq-title'>{item.title}</h6><p className='faq-body'>{item.body}</p></div>)
                        })
                    }
                </div>
                <div className='faq-right'>
                    <form onSubmit={handleSubmit} className='newsletter-form'>
                        {
                            subscriptionSuccess!==''?<p className='subscription-success'>{subscriptionSuccess}</p>:
                            subscriptionError!==''?<p className='subscription-error'>{subscriptionError}</p>:<p className='newsletter-form-title'>Don't miss an update</p>
                        }
                        <input type='email' placeholder='Enter email' onChange={e=>setEmail(e.target.value)} className='newsletter-input' />
                        {
                            subscriptionLoading?
                            <FaSpinner className='faq-image-loading' />
                            :<button type='submit' className='newsletter-btn'>Ok</button>
                            }
                    </form>
                </div>
        </Main>
    );
};

const Main = styled.div`
width:100%;
padding: 20px;
height: auto;
display: flex;
margin: auto;
justify-content: center;
    background: var(--style-bright);
    @media screen and (max-width:650px){
     flex-direction:column;
     padding: 5px;
    }
.faq-left{
    flex-basis: 65%;
    padding: 20px 30px;
    width: 100%;
    @media screen and (max-width:650px){
        flex-basis: 100%;
        padding: 20px;
    }
}
.faq-left-section-title{
    text-align: center;
    padding: 10px;
    color: var(--style-grey);
    font-size: 16px;
    font-weight: bold;
}
.faq-left-content-items{
    /* width: 100%; */
    padding: 10px 50px;
    background:var(--style-white);
    @media screen and (max-width:650px){
        padding: 20px;
    }
}
.faq-title{
 font-size:20px;
 font-weight: bold;
 padding: 20px 0px;
 color: var(--style-grey);
}
.faq-body{
font-weight: 16px;
text-align: justify;
color: var(--style-grey);
}
.faq-right{
    flex-basis: 25%;
    padding: 20px 30px;
    width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
@media screen and (max-width:650px){
        flex-basis: 100%;
        padding: 20px;
    }
}
.newsletter-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background:var(--style-white);
    padding: 20px;
    width: 100%;
    gap:15px;
}
.subscription-success{
color: var(--style-success);
margin-bottom: 15px;
text-align:center;
}
.subscription-error{
    color: var(--style-error);
    margin-bottom: 15px;
    text-align:center;
}
.newsletter-form-title{
text-align: center;
color: var(--style-grey);
font-size: 16px;
font-weight: 200;
margin-bottom: 15px;
} 
.newsletter-input{
height: 35px;
width: 90%;
border-radius: 5px;
padding: 5px;
border:thin solid var(--style-grey);
} 
.newsletter-btn{
    background-color: var(--style-bright);
    color: var(--style-blue-dark);
    cursor: pointer;
    padding:4px 10px;
    border-radius:4px;
    border:thin solid var(--style-grey);
}
.faq-image-loading{
    color: var(--style-blue-dark);
}
`
export default Faq;