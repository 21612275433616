import React, { useState } from 'react';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import addImage from '../../assets/assets/image4.png'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CircularStatic from '../../../imageUpload';
import { url } from '../../../Redux/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Support = () => {
    const[loading, setLoading] = useState(false)
    const app = useSelector(state=>state.apps.items)

    const handleYes = async(e)=>{
        await fetch(`${url}/comment-support/${e.supportId}`,{
            method:'put',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({comment:'yes',appId:app._id})
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                setLoading(false)
                toast.success(resp.resp, {position: toast.POSITION.TOP_RIGHT})
            }else if(resp.err){
                setLoading(false)
                toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
            }
        }).catch(err=>{
            if(err){
                setLoading(false)
                toast.error(err.message, {position: toast.POSITION.TOP_RIGHT})
            }
        })
    }

    const handleNo = async(e)=>{
        await fetch(`${url}/comment-support/${e.supportId}`,{
            method:'put',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({comment:'no',appId:app._id})
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                setLoading(false)
                toast.success(resp.resp, {position: toast.POSITION.TOP_RIGHT})
            }else if(resp.err){
                setLoading(false)
                toast.error(resp.err, {position: toast.POSITION.TOP_RIGHT})
            }
        }).catch(err=>{
            if(err){
                setLoading(false)
                toast.error(err.message, {position: toast.POSITION.TOP_RIGHT})
            }
        })
    }

    return (
        <Main>
            <ToastContainer />
           <div className='top-container'>
           <div className='support-left'>
            {app?.supports?.map((item,index)=>{
                    return(
                        <div className='support-content' key={index}>
                            <h2 className='support-title'>{item.problem}</h2>
                        {item.image&&<img src={item.image} alt='' className= 'support-image' />}
                    <p className='support-body'>{item.solution}</p>
                    <div className='comment-section'>
                        <div className='author-container'>
                        {/* {item?.author.image!=='image'&&<img src={item.author.image} alt='' className='author-image' />} */}
                        <p className='author-name'>{`${app?.name} admin`}</p>
                        </div>
                        <div className='comment-option'>
                         <div className='support-comment-wrapper'>
                         {loading?<CircularStatic />:<div className='support-icon-wrapper' ><p className='helps'>{app?.helped.filter(filt=>filt.supportId.toString()===item.supportId.toString()&&filt.comment==='yes').length}</p><FaThumbsUp onClick={()=>handleYes(item)} className='helps-icon'/></div>}
                            {loading?<CircularStatic />:<div  className='support-icon-wrapper'><p className='helps'>{app?.helped.filter(filt=>filt.supportId.toString()===item.supportId.toString()&&filt.comment==='no').length}</p><FaThumbsDown  onClick={()=>handleNo(item)} className='helps-icon'/></div>}
                         </div>
                        </div>
                    </div>
                        </div>
                    )
                })
            }
            </div>
            <div className='support-add'>
            <img src={addImage} alt='' className='support-add-image' />
            </div>
           </div>
        </Main>
    );
};

const Main = styled.div`
width: 100%;
height: auto;
min-height:70vh;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
gap: 50px;
.top-container{
width: 70%;
height: auto;
margin: auto;
padding: 20px;
display: flex;
justify-content: space-between;
align-items: flex-start;
@media screen and (max-width:850px){
    flex-direction: column;
    width: 100%;
}
}
.support-left{
    display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
gap: 30px;
flex-basis: 70%;
width: 100%;    
border-bottom-right-radius: 20px;
border-bottom-left-radius: 20px;
@media screen and (max-width:850px){
    flex-direction: column-reverse;
    flex-basis: 100%;
    width: 100%;
}
}
.support-content{
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
gap: 60px;
width: 100%;
}
.support-image{
    width: 100%;
    height: auto;
}
.support-title{
    text-align: center;
    color: var(--style-grey);
    font-size: 16px;
    padding: 10px;
}
.support-body{
    text-align: justify;
    padding: 0 20px;
    color: var(--style-grey);
}
.comment-section{
    display: flex;
    justify-content: center;
    background-color: var(--style-bright);
    align-items: center;
    width: 100%;
    height: 200px;
}
.success{
    color: var(--style-success);
    padding: 10px;
    text-align: center;
}
.error{
    color: var(--style-error);
    padding: 10px;
    text-align: center;
}
.helpful{
    color: var(--style-blue-dark);
    padding: 10px;
    text-align: center;
}
.comment-option{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.support-comment-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.support-icon-wrapper{
padding: 5px 10px;
background: none;
display: flex;
justify-content: space-around;
align-items: center;
}
.helps{
    padding: 10px;
    color:var(--style-blue-dark);
}
.helps-icon{
    color: var(--style-blue-dark);
    cursor: pointer !important;
}
.author-container{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
flex-basis: 30%;
padding: 20px;
width: 100%;
}

.author-image{
width: 100px;
height: 100px;
border-radius: 50%;
} 
.author-name{
    text-align: center;
    font-style: italic;
    color: var(--style-blue-dark);
}
.support-add{
    flex-basis: 25%;
    width: 100%;
    height: auto;
    @media screen and (max-width:850px){
    flex-basis: 100%;
    width: 100%;
}
}
.support-add-image{
    width: 100%;
    padding: 10px;
}
`

export default Support;