import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import axios from 'axios'
import { setAuthHeader } from "../Api";
import { url } from "../Api";

const initialState = {
    user:sessionStorage.getItem('user')?JSON.parse(sessionStorage.getItem('user')):null,token:localStorage.getItem('token'),registerStatus:'',loginStatus:'',
    registerError:'',loginError:'',userLoaded:false,getUserStatus:'',getUserError:''
}
export const registerUser = createAsyncThunk(
    'auth/registerUser',
    async (user,{rejectWithValue})=>{
        try {
            const response = await axios.post(`${url}/register`,{
                userName:user.userName,
                email:user.email,
                firstName:user.firstName,
                lastName:user.lastName,
                country:user.country,
                phone:user.phone,
                referrer:user.referrer,
                password:user.password,
                confirmedPassword:user.confirmedPassword
            })
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async(user,{rejectWithValue})=>{
        try {
            const response = await axios.post(`${url}/login`,{
                email:user.email,
                password:user.password
            })
             return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const getUser = createAsyncThunk(
    'auth/getUser',
    async(id,{rejectWithValue})=>{
        try {
            const response = await axios.get(`${url}/profile`,setAuthHeader())
            localStorage.setItem('profile',JSON.stringify(response.data))
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        loadUser(state,action){
            const token = state.token
            const user = state.user
            if(token && user){
                return {
                    ...state,
                    user:user,
                    token:token,
                    loadUser:true
                }
            }

        },
        logOut:(state)=>{
            localStorage.removeItem('token')
            sessionStorage.removeItem('user')
            sessionStorage.removeItem('current-payment')
            return {
                ...state,
                user:null,
                token:null,
                loadUser:false
            }
        },
    },
    extraReducers:(builder)=>{
        builder.addCase(registerUser.pending,(state,action)=>{
            return {...state,registerStatus:'pending'}
        })
        builder.addCase(registerUser.fulfilled,(state,action)=>{
            if(action.payload){
                const user = jwtDecode(action.payload)
                return {...state,token:action.payload,user:user,registerStatus:'success'}
            }else{
                return state
            }
        })
        builder.addCase(registerUser.rejected,(state,action)=>{
            return {...state,registerStatus:'rejected',registerError:action.payload}
        })
        builder.addCase(loginUser.pending,(state,action)=>{
            return {...state, loginStatus:'pending'}
        })
        builder.addCase(loginUser.fulfilled,(state,action)=>{
            if(action.payload){
                localStorage.setItem('token',JSON.stringify(action.payload.token))
                sessionStorage.setItem('user',JSON.stringify(action.payload.user))
                return {...state,token:action.payload.token,user:action.payload.user,loginStatus:'success'}
            }else{
                return state
            }
        })
        builder.addCase(loginUser.rejected,(state,action)=>{
            return {...state,loginStatus:'rejected',loginError:action.payload}
        })
        builder.addCase(getUser.pending,(state,action)=>{
            return {...state,getUserStatus:'pending'}
        })
        builder.addCase(getUser.fulfilled,(state,action)=>{
            if(action.payload){
                // localStorage.setItem('token')
                return {...state,user:jwtDecode(),token:action.payload,getUserStatus:'success'}
            }else{
                return state
            }
        })
        builder.addCase(getUser.rejected,(state,action)=>{
            return {...state,getUserStatus:'rejected',getUserError:action.payload}
        })
    }
})

export const {loadUser, logOut} = authSlice.actions;
export default authSlice.reducer;