import moment from 'moment';
import React from 'react';
import { FaLightbulb, FaInfoCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components'

const Footer = () => {
    const app = useSelector(state=>state.apps.items)
    const user = useSelector(state=>state.auth.user)
    const {pathname} = useLocation()
    const noFooter = [`/${user?._id}/:amount/:paymentId/:type/withdrawal`]
    
    if(noFooter.some(item=>item===pathname))
    return null
    return (
        <Main>
         <footer className='footer'>
                <div className='footer-top'>
                <div className='left-footer'>
                <p className='left-footer-title'>Knowledge</p>
                <div className='left-footer-content-one'>
                   
                <div className='left-footer-content-one-wrapper'>
                    <Link to='/learn'className='left-footer-content-one-item'>
                    <p className='footer-sub-text'>Learn</p>
                    </Link>
                    </div>
                    <div className='left-footer-content-one-wrapper'>
                    <Link to='/support'className='left-footer-content-one-item'>
                    <p className='footer-sub-text'>Support</p>
                    </Link>
                    </div>
                </div>
                </div>
                <div className='middle-footer'>
                <p className='left-footer-title'>Company</p>
                <div className='left-footer-content-one'>
                    <div className='left-footer-content-one-wrapper'>
                    <Link to={`/${user?._id}/dashboard`} className='left-footer-content-one-item'>
                        <p className='footer-sub-text'>Get Started</p>
                    </Link>
                    </div>
                    <div className='left-footer-content-one-wrapper'>
                    
                    <a href ='https://terms.cashorbits.com' target='_blank' rel='noreferrer' className='left-footer-content-one-item'>
                        <p className='footer-sub-text'>Terms of service</p>
                        </a>
                    </div>
                </div>
                </div>

                <div className='middle-footer'>
                <p className='left-footer-title'>Useful Links</p>
                <div className='left-footer-content-one'>
                    <div className='left-footer-content-one-wrapper'>
                    <Link to='/faq' className='left-footer-content-one-item'>                    
                        <p className='footer-sub-text'>FAQ</p>
                    </Link>
                    </div>
                    <div className='left-footer-content-one-wrapper'>
                    
                    <div className='left-footer-content-one-item'>
                        <p className='footer-sub-text'>{app?.help}</p>
                    </div>
                    </div>
                </div>
                </div>
                </div>
                <div className='footer-bottom'>
<p className='copyright'>copyright &copy; {moment(new Date()).year()} | {app?.name}</p>
                </div>
            </footer>
        </Main>
    );
};
const Main = styled.div`
width: 100%;
.footer{
    background:var(--style-blue-dark);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px;
    gap:50px;
}
.footer-top{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    @media screen and (max-width:800px) {
        flex-direction: column;
    }
}
.left-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-basis: 30%;
    @media screen and (max-width:800px) {
        flex-direction: column;
        flex-basis: 100%;
        width:100%;
    }
}
.left-footer-title{
    text-align: left;
    color: var(--style-blue-cyan);
}
.left-footer-content-one{
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap:30px;
}
.left-footer-content-one-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    @media screen and (max-width:800px) {
        justify-content: left;
    }
}
.left-footer-content-one-item{
    color: var(--style-bright);
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.left-footer-content-one-icon{
    color: var(--style-light-gray);
}
.footer-sub-text{
    padding: 4px 10px;
    font-size: 14px;
    color: var(--style-grey);
}
.middle-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction:column;
    flex-basis: 30%;
    @media screen and (max-width:800px) {
        flex-direction: column;
        flex-basis: 100%;
        width:100%;
        margin-top:50px;
    }
}
.right-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-basis: 30%;
    @media screen and (max-width:800px) {
        flex-direction: column;
        flex-basis: 100%;
        width:100%;
        margin-top:50px;
    }
}
.footer-bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.copyright{
    color: var(--style-grey);
    text-transform: capitalize;
}
`
export default Footer;